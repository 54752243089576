import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Options } from 'ng5-slider';
import { filter } from 'rxjs/operators';
declare let $: any;

import { AuthenticationService } from './services/authentication/authentication.service';
import { UtilityService } from './services/utility-service/utility.service';
import { LoginResponse } from './models/authentication/Login/login-response.model';
import { environment } from 'src/environments/environment';
import { Console } from 'console';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    autherizedUser: LoginResponse;
    tokenTimer: any;

    constructor(
        private router: Router
        , private _authenticationService: AuthenticationService
        , private _utilityService: UtilityService) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                  (<any>window).ga('set', 'page', event.urlAfterRedirects);
                  (<any>window).ga('send', 'pageview');
                }
              });
    }

    ngOnInit() {
        if (environment.domainRedirection.enabled) {
            this.initDomainRedirection();
        }

        if (!this._utilityService.getToken()) {
            this.getAuthencation();
        } else {
            this.refreshToken();
        }

        this.recallJsFuntions();
    }

    private initDomainRedirection() {
        const pickAndPark = ['/pick-up-and-drop-off'];
        const selfDrive = ['/self-drive'];
        const carRental = ['/car-rental']
        const tempEnvironment = environment.domainRedirection;
        const url = window.location.href.toLowerCase();
        

        if( url === tempEnvironment.pickAndPark){
            this.router.navigateByUrl('/pick-up-and-drop-off');
        } else if( url === tempEnvironment.selfDrive){
            this.router.navigateByUrl('/self-drive');
        } else if( url === tempEnvironment.carRental){
            this.router.navigateByUrl('/')
        }

       
      
        
    }

    private startTokenTimer() {

        this.tokenTimer = setInterval(() => {
            if (this._utilityService.isGoingToExpire()) {
                this.stopTokenTimer();
                this.refreshToken();
            }
        }, 1000);
    }

    private stopTokenTimer() {
        clearInterval(this.tokenTimer);
        this.tokenTimer = null;
    }

    private refreshToken(): void {
        this._authenticationService.refresh().subscribe(user => {
            if (user) {
                this.autherizedUser = user;
                this._utilityService.saveWebSettings(this.autherizedUser.accessToken);
                this._utilityService.setAppSettings(this.autherizedUser);
                this.startTokenTimer();
            }
            else
                this.getAuthencation();
        }, error => {

        })
    }

    private getAuthencation(): void {
        this._authenticationService.signIn(environment.agencyCode, environment.emailAddress, environment.password).subscribe(user => {

            if (user) {
                this.autherizedUser = user;
                this._utilityService.saveWebSettings(this.autherizedUser.accessToken);
                this._utilityService.setAppSettings(this.autherizedUser);
                this.startTokenTimer();
            }
        }, error => {

        });
    }



    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}