import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxAutocompleteComponent } from 'ngx-angular-autocomplete';
import { Prediction } from 'src/app/models/location/googl-location.model';
import { LocationService } from 'src/app/services/location/location.service';
import { GearRentalServiceService } from '../../self-drive/services/gear-rental/gear-rental-service.service';
import { SelfDriveService } from '../../self-drive/services/self-drive.service';

@Component({
  selector: 'app-need-gear-delivery',
  templateUrl: './need-gear-delivery.component.html',
  styleUrls: ['./need-gear-delivery.component.scss']
})
export class NeedGearDeliveryComponent implements OnInit {

  //#region I/O Variables
  @Input() times: Array<string> = [];
  @ViewChild('collectionCity') collectionCity: NgxAutocompleteComponent;
  //#endregion

  //#region Variables
  minDateCollection: Date;
  maxDateReturn: Date;
  minDateDelivery: Date;
  maxDatePickUp: Date;
  public carDeliveryLocationForm: any;
  public showMoreOptions = false;
  public deliveryLocations: Prediction[] = [];
  public collectionCities: Prediction[] = [];
  filteredSelectedTime: string[];
  //#endregion

  constructor(private _locationService: LocationService
    , private gearRentalService: GearRentalServiceService
    , private _router: Router
    , private alertService: AlertService) {
    this.minDateDelivery = new Date((new Date()).getTime() + (3 * 24 * 60 * 60 * 1000));
    this.minDateCollection = new Date();
  }

  ngOnInit(): void {
    this.initcarDeliveryLocationForm();

  }



  //#region  Public Methods

  public dateSelect() {
    // this.minDateCollection = event;
    this.minDateCollection = this.carDeliveryLocationForm.deliveryDate;
    this.minDateDelivery = this.carDeliveryLocationForm.deliveryDate;
    this.minDateCollection = new Date(this.minDateCollection.getTime() + (2 * 24 * 60 * 60 * 1000));
  }

  public getcarDeliveryFilterdTime(){
    this.filteredSelectedTime = this.times.filter(time => time >= this.carDeliveryLocationForm.deliveryTime);
  }

  public onChangeCollectionLocation() {
    if (this.carDeliveryLocationForm.isEnableDifferentCollectionAddress) {
      this.carDeliveryLocationForm.collectionCity = '';
      this.carDeliveryLocationForm.collectionAddress = '';



    }
  }

  public onSubmitcarDeliveryLocationForm() {

    if (this.validateForm()) {
      this.gearRentalService.gearNeedDeliverySearchCriteria = this.carDeliveryLocationForm;
      this._router.navigate(['self-drive/gear-select/need-delivery']);
    }
  }

  public onSelectItem(event: any, type: number) {
    if (event) {
      if (type === 1) {
        this.carDeliveryLocationForm.deliveryCity = event;
      } else if (type === 2) {
        this.carDeliveryLocationForm.collectionCity = event;
      }
    }

  }

  public onEnterLocation(event, type: number) {
    this.deliveryLocations = [];
    if (event?.target?.value) {
      const value = event.target.value;
      if (value?.length >= 2) {
        this._locationService.googleLocation(value).subscribe(result => {
          if (result?.data?.predictions?.length > 0) {
            if (type === 1) {
              this.deliveryLocations = result.data.predictions;
            } else if (type === 2) {
              this.collectionCities = result.data.predictions;
            }
          }
        });
      }
    }

  }
  //#endregion

  //#region  Private Methods

  private validateForm() {
    if (!this.carDeliveryLocationForm.deliveryCity) {
      this.alertService.danger('Please select delivery city.');
      return false;
    }
    else if (!this.carDeliveryLocationForm.deliveryAddress) {
      this.alertService.danger('Please fill delivery address.');
      return false;
    }

    else if (this.carDeliveryLocationForm.isEnableDifferentCollectionAddress) {
      if (!this.carDeliveryLocationForm.collectionCity) {
        this.alertService.danger('Please select collection city.');
        return false;
      }
      else if (!this.carDeliveryLocationForm.collectionAddress) {
        this.alertService.danger('Please fill collection address.');
        return false;
      }
    }

    else if (!this.carDeliveryLocationForm.deliveryDate) {
      this.alertService.danger('Please select delivery date.');
      return false;
    }

    else if (!this.carDeliveryLocationForm.deliveryTime) {
      this.alertService.danger('Please select delivery time.');
      return false;
    }


    else if (!this.carDeliveryLocationForm.collectionDate) {
      this.alertService.danger('Please select collection date.');
      return false;
    }

    else if (!this.carDeliveryLocationForm.collectionTime) {
      this.alertService.danger('Please select collection time.');
      return false;
    }

    return true;
  }

  private initcarDeliveryLocationForm(): void {
    this.carDeliveryLocationForm = {
      deliveryCity: '',
      deliveryAddress: '',
      deliveryDate: '',
      deliveryTime: '',
      collectionDate: '',
      collectionTime: '',
      isEnableDifferentCollectionAddress: false,
      collectionCity: '',
      collectionAddress: ''
    }
  }

  //#endregion

}
