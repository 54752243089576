<section class="w-100">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car">
                    <div class="home-addon-image  mt-4"><i class="bx bxs-map ic_siz1" aria-hidden="true" ></i></div>
                    <span class="w-100 mt-3 d-block text-center hd_blk" >Pick destination</span>
                    <p class="w-100 text-center mb-4">Select a destination you want to go</p>
                </div>
            </div>

            <div class="col-md-3 col-sm-6" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car">
                    <div class="home-addon-image  mt-4"><i class="bx bxs-calendar ic_siz1" aria-hidden="true" ></i></div>
                    <span class="w-100 mt-3 d-block text-center hd_blk">Select Term</span>
                    <p class="w-100 text-center mb-4">Select expected staring date of your journey.</p>
                </div>
            </div>

            <div class="col-md-3 col-sm-6" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car">
                    <div class="home-addon-image mt-4"><i class="bx bxs-car ic_siz1" aria-hidden="true" ></i>
                    </div>
                    <span class="w-100 mt-3 d-block text-center hd_blk">Choose a Car</span>
                    <p class="w-100 text-center mb-4">Choose a car that suits your travel.</p>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="w-100 d-flex flex-wrap justify-content-center box_car" data-toggle="modal" data-target="#exampleModalsearch">
                    <div class="home-addon-image d-flex justify-content-center align-items-center mt-4">
                        <img class="ic_siz1 me-0" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/smile.png" width=50 alt="smiley" >
                    </div> <span class="w-100 mt-3 d-block text-center hd_blk">Enjoy The Ride</span>
                    <p class="w-100 text-center mb-4">Enjoy your trip with your family and friends.</p>
                </div>
            </div>
        </div>

    </div>
</section>






<!-- <section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
<div class="w-100 brdrds">
    <iframe class="d-block" width="100%" height="315" src="https://www.youtube.com/embed/h_XlEIqxqgA?si=QdPMO_xQKrRXE3Ew&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen loading="lazy"></iframe>
</div>

                </div>
                <div class="col-lg-6 d-flex flex-wrap align-content-center">

                    <h2 class="hd_think mb-3">Thinking of Renting a Car in Oman?</h2>
                    <p class="newstyl">
                       
Thinking about a long weekend getaway, a 2- week trip, extended travel, or something in between? Depending on where you live and the amount of time you have to travel, certain destinations will make more sense than others.
    
                    </p>
                </div>
            </div>   </div>
</section> -->




<!-- <section class="w-100 mt-5">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="w-100 radius_20">
                    <img class="w-100" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/home2.jpg" alt="Three Girls Proudly Holding a Flag">
                </div>
            </div>
        </div>

    </div>
</section> -->
<section class="w-100 mt-5">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="w-100 radius_20">
                    <img class="w-100" src="assets/img/03.png" alt="Rent a Red Car in Muscat">
                </div>
            </div>
        </div>

    </div>
</section>

<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12" >
                <h2 class="w-100 text-center head2">Our Fleets</h2>
                <p class="w-100 text-center">
                    Welcome to our exceptional fleet of vehicles, proudly offered by our premier car rental company. Our diverse and meticulously maintained collection features an extensive range of cars to suit your every need, ensuring a smooth and enjoyable journey.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3"data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box1.jpg" alt="White car available for Car rentals in Toyota Corola" class="img-fluid" loading="lazy"></div>
                    <div>Toyota Corolla</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box2.jpg" alt="Silver car available for Car rentals in Nissan Sunny" class="img-fluid" loading="lazy"></div>
                    <div>Nissan Sunny</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box3.jpg" alt="Black car available for Car rentals in Land Cruiser" class="img-fluid" loading="lazy"></div>
                    <div>Land Cruiser</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box4.jpg" alt="Red car available for Car rentals in  Toyota Altis" class="img-fluid" loading="lazy" ></div>
                    <div>Toyota Altis</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box5.jpg" alt="Golden car available for Car rentals in Duster" class="img-fluid" loading="lazy"></div>
                    <div>Duster</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box6.jpg" alt="Silver car available for Car rentals in Elantra" class="img-fluid" loading="lazy" ></div>
                    <div>Elantra</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box7.jpg" alt="White car available for Car rentals in Mazda" class="img-fluid" loading="lazy"></div>
                    <div>Mazda</div>
                </div>
            </div>

            <div class="col-md-6 col-lg-3" data-toggle="modal" data-target="#exampleModalsearch">
                <div class="w-100 d-flex align-items-center box_car">
                    <div><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car_box8.jpg" alt="White car available for Car rentals in Oman- Kicks" class="img-fluid" loading="lazy"></div>
                    <div>Kicks</div>
                </div>
            </div>


        </div>

    </div>
</section>

<section class="w-100 mt-5">
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="w-100 radius_20">
                    <img class="w-100" src="assets/img/01.png" alt="Rent a Red Car in Muscat">
                </div>
            </div>
        </div>

    </div>
</section>
