<app-header *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="about-area ">
    <div class="container w-80p">

        <div class="about-inner-area">
            <div>
                <div class="welcome-text">
                    <h2 class="darkBigText mb-5">Welcome to <br><span style="color:#a98e5f;">INNVOCS.co Car Rental Portal</span></h2>
                    <p> We offer you a wide range of cars that can be easily pick up from different hubs or delivered to you wherever you are in Oman.<br>
                        Our fleet will meet your needs to have a safe and comfortable driving experience
                        INNVOCS.co, your gate for car rental at the Sultanate</p>
                    
                </div>
            </div>
            <div>
                <div class="container border-row mb-46"></div>
            </div>
            <br>
            <br>
            <div class="row">
                <div class="col-lg-12">
                    <div class="about-text text-justify">
                        <h3>Who We are?</h3>
                        <p> INNVOCS founded, aiming to enhance the tourism services in Oman and abroad. We select for our clients the best destinations that were perfectly designed to offer our clients unique experiences reflecting the authentic Omani hospitality for our guests, offering a twist of modernity to the Omani heritage.</p>
                          
                          <p>Our values reflect who we are, as we are committed to excellence, providing the best tourism services innovatively, in a way that meets the client needs (individuals and groups, children and adults).</p>
                          
                          
                          <br>
                        <hr style="border-top: 2px solid #dbd8d8;margin-top: -15px;">
                    </div>
                </div>
            </div>
        </div>

        <div class="row mtb-29">
            <div class="col-lg-12 align-items-center flex-center">
                <div class="descp-text">
                    <p> At INNVOCS, we are working to be Oman tourism’s gate to the inside and outside.</p>
                      <p>At INNVOCS.co, to be among best online tourism service provider in Middle East</p>
                      <p>as we are introducing the principle of Discover Oman and the world, just in your way</p>
                      <p>by designing your own trip via varieties of tourism options.</p>
                    
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="container border-row"></div>
        </div>

        <div class="row align-items-center" style="margin-top: 40px;">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about/about-img.jpg" class="shadow" alt="image- Objective" loading="lazy">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content text-justify">
                    <span class="sub-title"><h1 class="darkBigText">Objective</h1></span>
                    <p>Provide tourism and traveling services to travelers visiting or residing in Oman (individuals and groups, children and adults) professionally, through our specialized and well-educated team. By providing tourism services that meet the clients inside needs (inside and outside Oman), we aim to enhance the tourism culture, using constructive guiding and instructing tools. </p>
                </div>
            </div>            
        </div>

        <div class="row">
            <div class="welcome-text">
                <h2 class="darkBigText">Value</h2>
                <p> We believe that the team spirit is the number one motivator for us to reach our goals, therefore our values are the core pillars on which we stand to deliver our message successfully. Our values are:</p>
            </div>
            <br>
            <div class="value-sec">
                <div class="hands-col">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about/hands.png" alt="Hand image icon - Leading By Example" loading="lazy">
                    <h5>Leading by Example</h5>
                </div>
                <div class="hands-col">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about/relation.png" alt="Relationship image icon -Strong Relationships" loading="lazy">
                    <h5>Strong Relationships</h5>
                </div>
                <div class="hands-col">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about/support.png" alt="Communication image icon- Communication and Support" loading="lazy">
                    <h5>Communication and Support</h5>
                </div>
                <div class="hands-col">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about/excellency.png" alt="Excellency image icon - Excellency" loading="lazy">
                    <h5>Excellency</h5>
                </div>
            </div>
           
        </div>
        
        <hr style="border-top: 2px solid #dbd8d8;margin-top: 30px;">

        <div class="row">

<div class="col-12 mt-3">
    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/abou_car.png" class="flt" loading="lazy"/>
    <div class="about-text">
        <h3 class="mt-4 no-border">About Route Car Rental 
        </h3>
        
    </div>
    <p class="text-justify"> Route Car Rental Agency was founded with the goal of elevating car rental services in the Sultanate of Oman. We offer an extensive selection of vehicles for your convenience, spanning various cities across Oman. Our journey in the car rental industry in Oman began in 2015 under the name Desert Acacia Car Rental. Recently, we underwent a rebranding to better represent our evolving identity.</p>
                 
    <p class="text-justify">Our car fleet consists of state-of-the-art, brand-new vehicles, all of which are meticulously maintained to guarantee your complete satisfaction and safety throughout your rental experience. We prioritize the comfort and security of our customers, ensuring that every vehicle is in impeccable condition, both inside and out.</p>
    <p class="text-justify">Our services encompass delivering your desired vehicle to our office hubs or accommodating your specific delivery requirements. In our pursuit of excellence, we have established collaborations and partnerships, most notably with Innvocs Holidays. This partnership serves as a roadmap to a successful journey, enabling us to expand our online services and connect with clients worldwide.
  </p>
</div>

<div class="col-12">   <hr style="border-top: 2px solid #dbd8d8;"></div>

        </div>


        
        <div class="hekaya-container">
            <div class="row  hekayat-titl top-row-margin">
                <div class="col-md-4 col-lg-4 col-sm-12 text-center">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about/CEOImage.jpg" class="img-fluid" alt="Omani Man " loading="lazy"/>
                    
                </div>
                <div class="col-md-8 col-sm-12 col-lg-8">
                    <p style="text-align:justify;margin-top: 150px;">We believe that the tourism sector is capable of giving infinitely, and we
                    are truly proud to be part of it. Because we believe that a bright future is waiting ahead of the local 
                    and international tourism, we endeavor to leave our fingerprints in the global map of tourism. We seek to
                    make the world a better place for everyone.</p>

                    <p style="text-align: center;">Unleash your potential with us and discover the joy of traveling!</p>

                    <h5 style="text-align: end;padding-right: 18px;margin-top: 60px;">CEO TALK</h5>
                    <h5 style="text-align: end;">Ahmed Al Alwi</h5>
                </div>
        
            
        </div>
        </div>
    </div>
</section>

<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-footer>