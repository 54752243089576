import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { BookingRequest } from 'src/app/models/car-rental/booking/booking-request.model';
import { BookingResponse } from 'src/app/models/car-rental/booking/booking-response.model';
import { SearchRequest } from 'src/app/models/car-rental/search/search-request.model';
import { SearchResponse } from 'src/app/models/car-rental/search/search-response.model';
import { SelectRequest } from 'src/app/models/car-rental/select/select-request.model';
import { SelectResponse } from 'src/app/models/car-rental/select/select-response.model';
import { GenericService } from '../generic-service/generic.service';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarRentalService {

  sharedData: any;
  sharedToBooking: any;
  public formValues: FormGroup;

  constructor(private genericService: GenericService,private http: HttpClient) { }

  carRentalNeedDeliverySearchCriteria: any;

  setSearchRequest(request: SearchRequest): void {
    localStorage.setItem('CarRentalSearchRequest', JSON.stringify(request));
  }

  getSearchRequest(): SearchRequest {
    const request = localStorage.getItem('CarRentalSearchRequest');
    return (request) ? JSON.parse(request) : null;
  }

  public search(request: SearchRequest): Observable<SearchResponse> {
    const serviceUrl = RoutingConstants.carRental.search;
    return this.genericService.postService(request, serviceUrl, true);
  }

  public select(request: SelectRequest): Observable<SelectResponse> {
    const serviceUrl = RoutingConstants.carRental.select;
    return this.genericService.postService(request, serviceUrl, true);
  }

  public booking(request: BookingRequest): Observable<BookingResponse> {
    const serviceUrl = RoutingConstants.carRental.book;
    return this.genericService.postService(request, serviceUrl, true);
  }

  public setSelectedCar(selectedItem: any) {
    localStorage.setItem('SelectedItem', JSON.stringify(selectedItem));
    
  }

  public getSelectedCar(): any {
    const selectedItem = localStorage.getItem('SelectedItem');
    return (selectedItem) ? JSON.parse(selectedItem) : null;
  }
  searchCars(searchParams: string): Observable<any> {
    const url = `car-rental/search/pick-up/`+searchParams; // Replace 'your-api-url' with the actual API endpoint
    return this.http.get(url);
  }

}
