<head>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@splidejs/splide@latest/dist/css/splide.min.css">
</head>

<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Habitat Category</h2>
        </div>
    </div>
</div>

<section class="about-area ">
    <div class="container">

        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    
                </div>

                

               
            </div>
        </div>

        

         

          <div class="container main-services">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing">
                        <div class="listing-image">
                                <img src="../../../../assets/img/self-drive/habitat-category/beach.jpg" alt="image" style="width: 400px; " loading="lazy">
                        </div>
                        <div class="listing-content">
                            <ul style="font-size: 15px;margin-left: -11px;color: #a5916e;">
                                <li>Including plan patches and large masses of wind blown sand at desert environments or other areas</li>
                                <li>Example: Al Sharqia Sands</li>
                            </ul>
                            <p style="text-align: center;">Caution</p>
                            <ul style="font-size: 15px;margin-left: -11px;color: #a5916e;">
                                <li>Potential to get stuck and Potential to roll over at large dunes</li>
                                <li>It is not recommended to drive during night timing</li>
                                <li>Do not enter sandy dunes ares alone as there is high potential of being lost the directions. It is recommended to use front guide to avoid lost in the middle of dunes</li>
                                <li>Pay Attention to the animals, especially camels</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing">
                        <div class="listing-image">
                                <img src="../../../../assets/img/self-drive/habitat-category/wetland.jpg" alt="image" style="width: 400px; " loading="lazy">
                        </div>
                        <div class="listing-content">
                            <ul style="font-size: 15px;margin-left: -11px;color: #a5916e;">
                                <li>Including salty marshes and wetland dominated by grasses, rushes or reeds and area of lagoons</li>
                                <li>Example: Mahout Salty Marsh and Khor Gurnar</li>    
                            </ul>
                            <p style="text-align: center;">Caution</p>
                            <ul style="font-size: 15px;margin-left: -11px;color: #a5916e;">
                                <li>Potential to get stuck at the soft and massive sandy, gravy or mud areas</li>
                                <li>It is not recommended to drive though. However, upon condition status only to cross by</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-md-6">
                    <div class="single-listing">
                        <div class="listing-image">
                                <img src="../../../../assets/img/self-drive/habitat-category/mountain.jpg" alt="image" style="width:400px; " loading="lazy">    
                        </div>
                        <div class="listing-content">
                            <ul style="font-size: 15px;margin-left: -11px;color: #a5916e;">
                                <li>Including asphalted roads with steep high and down ascents and roads with many turns and curves</li>
                                <li>Example: Jabel Akhdar road, Wadi Al Jizi</li>
                            </ul>
                            <p style="text-align: center;">Caution</p>
                            <ul style="font-size: 15px;margin-left: -11px;color: #a5916e;">
                                <li>Potential to fall from height and roll over and warming up of braking system</li>
                                <li>Always pay attention to road instruction signs</li>
                            </ul>
                        </div>
                    </div>
                </div>
        
            </div>
        </div>

       
        
    </div>
</section>


<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-self-drive-footer>
