<p>home-salalah works!</p>

<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>



<app-home1></app-home1>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h1 class="hd_main">
                    Welcome to <br />
                    <span style="color: #a98e5f;">HEKAYA</span> Car Rental<br />
                    <span class="">in <i>Salalah</i></span>
                </h1>
                <p class="newstyl">
                    Welcome to Hekaya Car Rental in Salalah, your gateway to extraordinary journeys. Experience seamless and reliable car rental services that will elevate your exploration of this beautiful country.

                </p>
            </div>
            <div class="col-lg-6 d-flex align-items-end">
                <img class="img-fluid" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car-sample.png" alt=" White car available for Car rentals in Salalah">

            </div>
        </div>
    </div>
</section>


<app-home2></app-home2>

<section class="w-100 mt-5">
    <div class="container">
        <div class="bg_blk radius_20 p-5">
            <div class="row">

                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Best car rental in salalah</h2>
                        <br>
                        <p class="text-white">Embrace the freedom to explore Salalah, the coastal beauty of the Arabian Sea, with customised 
                            car rentals. With Hekaya Car Rental in Salalah, you can write a glorious travel tale of your own. </p>
                        <p class="text-white">From winding coastal roads to lush greenery, our reliable and stylish cars are your key to 
                            discovering the treasures of Salalah's natural wonders. Being the most affordable car rental in 
                            Salalah since 2015, Hekaya opens a magical door to the gorgeous Salalah terrain. </p>
                        <!-- <p class="text-white"  *ngIf="viewMore">Embrace the ease of owning your vehicle and 
                            forget about the other inconveniences on the road. Reserve your car with Hekaya
                             today to enjoy the beauty of Nizwa on your terms. </p> -->
                             <h2 *ngIf="viewMore">Discover Wonders with Our Car Rental Services in 
                                Salalah </h2>
                            <br>
                            <p class="text-white" *ngIf="viewMore">Embark on your journey seamlessly with Hekaya, the leading car rental service in Salalah. From 
                                pristine beaches to lush landscapes, explore Salalah's breathtaking attractions at your own pace 
                                in our fleet of meticulously maintained vehicles. </p>
                                <p class="text-white" *ngIf="viewMore">Enjoy the convenience and flexibility of having a reliable mode of transportation, ensuring every 
                                    part of your trip is as memorable as the final destination. </p>
                                <p class="text-white" *ngIf="viewMore" >You can schedule your trip conveniently 
                                    and take a rest whenever you need with our rental services. Thus, you don't need to rush to the 
                                    destinations but rather enjoy the trip at its full volume. </p>
                            <p class="text-white" *ngIf="viewMore">Partnering with Hekaya allows you to fully immerse yourself in the captivating beauty of Salalah, 
                                providing access to top-notch automobiles and the key to hassle-free mobility. Your adventure 
                                begins with Hekaya, where every journey is a discovery waiting to unfold.</p>
                      
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
        
                            <h2>Testimonials</h2>
                            <br>
                            <h5 class="text-white">Rizwan Manzoor</h5>
                        <p class="text-white">Our trip to Salalah was smooth thanks to Hekaya’s rent a car in Salalah. Renting an automobile 
                            was as easy as the scenery we drove through. The fleet was perfect, and the cars' dependability 
                            made it possible for us to concentrate on Salalah's natural beauty rather than practical issues. I 
                            am so grateful to Hekaya for making our trip go smoothly. Highly recommended.  </p>  
                            <h5 class="text-white" *ngIf="viewMore">Kumar Seth</h5>
                        <p class="text-white" *ngIf="viewMore">I have received Great help from Hekaya, Salalah’s car rental company, last month. Their cars 
                            exceeded our expectations in terms of quality, and what makes them stand out is how committed 
                            the company is to making sure the client is happy. Hekaya gave us excellent service from 
                            beginning to end, which made our tour to Salalah stress-free and enjoyable. We are appreciative 
                            of the dependability and professionalism that characterised our rental experience. </p> 
                            <h5 class="text-white" *ngIf="viewMore">Aamiya Beegum</h5>
                        <p class="text-white" *ngIf="viewMore">Hekaya, Car rental in Salalah, gave us the flexibility to discover Salalah at our own pace. We 
                            had the opportunity to customise our trip to our liking thanks to the assortment of well-maintained 
                            automobiles. We can't thank Hekaya enough for the seamless and accommodating service they 
                            offered; having a trustworthy and convenient means of transportation made our journey much 
                            more enjoyable. </p> 
                            <h5 class="text-white" *ngIf="viewMore">Salman Ul Faris</h5>
                        <p class="text-white" *ngIf="viewMore">Because of Hekaya, our journey to Salalah turned into a memorable experience. Both the rental 
                            process's simplicity and the vehicles' exceptional condition were noteworthy. We valued Hekaya's 
                            meticulous attention to detail in addition to the automobiles' dependability. They are the top 
                            affordable car rentals in salalah. Each mile spent with Hekaya became a treasured memory, 
                            and we heartily endorse using their services for a remarkable trip through Salalah.  </p> 
                       
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="!viewMore"><b> View More</b></span></div>
                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="viewMore"><b> View Less</b></span></div>
            </div>
        </div>
    </div>
</section>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h2 class="faq_hd">Frequently Asked
                    <br /> Questions
                </h2>
                <span>Can't find what you're looking for?<br />Visit our <a 
                        class="text-decoration-underline font-weight-bold" routerLink="/contact" target="_blank">Help centre</a></span>
            </div>
            <div class="col-md-8">
                <div class="accordion faqstyle faqopen" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> How much does it cost to rent a car in Salalah?   
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>The price of a car rental in Salalah is subjective to various factors including the distance, duration 
                                    and so on. Generally, rates are calculated on a daily basis; longer rentals may be subject to 
                                    reductions. It is advised that you inquire with many rental companies for precise cost information 
                                    tailored to your needs. 
                                     </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">What do I need to rent a car in Salalah? 
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>To rent a car in Salalah, a credit card, driver's licence, and proof of identity, such as a passport 
                                    are mandatory. For precise details and any additional documentation, it is recommended to check 
                                    with the rental agencies, as requirements may vary among them. 
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">How old do I have to be for an affordable car rental in salalah? 
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Although certain rental companies may insist on drivers being 25 years of age or older, the normal 
                                    minimum age for affordable car rentals in Salalah is 21. Younger drivers can be subject to extra 
                                    charges or limitations. It is advised to inquire about the precise age restrictions and related 
                                    conditions with rental suppliers. 
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingFour">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">Which is the most popular car rental company in salalah?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Determining the most popular Car Rental Services in Salalah is subjective and can vary based 
                                    on individual preferences. Hekaya stands top among the car rentals in Salalah by providing 
                                    error free procedures and elevating customer satisfaction. When you are in Salalah, Hekaya is 
                                    the ready to go choice for making your Salalah days fruitful.
                                    </p>
                            </div>
                        </div>
                    </div>

                  
                </div>



                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="!faqViewMore"><b> View More</b></span></div>
                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="faqViewMore"><b> View Less</b></span></div>
          
            </div>
        </div>
    </div>
</section>


<!-- <app-feedback></app-feedback> -->


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>
