import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { LeaseEnquiryRequest } from 'src/app/models/car-rental/lease-enquiry/leaseEnquiry-request.model';
import { LeaseAgreementService } from 'src/app/services/lease-agreement/lease-agreement.service';
import { RequestType, ServiceRequestType, ServiceType } from 'src/app/models/service.constant';

@Component({
  selector: 'app-lease-enquiry',
  templateUrl: './lease-enquiry.component.html',
  styleUrls: ['./lease-enquiry.component.scss']
})
export class LeaseEnquiryComponent implements OnInit {

  leasevalidform: FormGroup;
  isleaseEnquiry: boolean;
  minDate: Date;
  maxDate: Date;

  constructor(
    private alertService: AlertService
    , public _datepipe: DatePipe
    , private _leaseService: LeaseAgreementService
    ,private formBuilder: FormBuilder
  ) { 

    this.isleaseEnquiry = false;
    this.minDate = new Date();
    
  }

  ngOnInit(): void {
    this.initSubmiteleaseEnquiry();
  }

  public isSubmiteleaseEnquiry(): LeaseEnquiryRequest {

    this.isleaseEnquiry = true;

    if (this.leasevalidform.invalid) {
      if (!this.leasevalidform.controls.name.value) {
        this.alertService.danger('Please fill in your Name')
      } else if (!this.leasevalidform.controls.place.value) {
        this.alertService.danger('Please fill in your Place ')
      } else if (!this.leasevalidform.controls.fromDate.value) {
        this.alertService.danger('Please select From date ')
      } else if (!this.leasevalidform.controls.toDate.value) {
        this.alertService.danger('Please select To Date ')
      } else if (!this.leasevalidform.controls.email.value) {
        this.alertService.danger('Please fill in your Email')
      } else if (!this.leasevalidform.controls.phone.value) {
        this.alertService.danger('Please fill in your Phone Number ')
      }
    }
    else {

      let LeaseEnquiryRequest: LeaseEnquiryRequest = {
        type: RequestType.Request,
        service: ServiceRequestType.LeaseAgreement,
        content: {
          serviceType: ServiceType.LeaseAgreement,
          leaseAgreement: {
            Name: this.leasevalidform.controls.name.value,
            Place: this.leasevalidform.controls.place.value,
            FromDate: this._datepipe.transform(new Date(this.leasevalidform.controls.fromDate.value), 'yyyy-MM-dd'),
            ToDate: this._datepipe.transform(new Date(this.leasevalidform.controls.toDate.value), 'yyyy-MM-dd'),
            Email: this.leasevalidform.controls.email.value,
            Phone: this.leasevalidform.controls.phone.value,
          }
        }
      }

      this.alertService.success("Enquiry Sent Successfully");

      this.emptyForm();

      this._leaseService.leaseMail(LeaseEnquiryRequest).subscribe(response => {
        let data = response;
        if (response) {
          // this.alertService.success("Email Sent Successfully");
        } else {
          this.alertService.danger("Something went wrong.Please check your email id or try again later");
        }
      });
      return LeaseEnquiryRequest;
    }
  }

  private emptyForm() {
    this.leasevalidform.controls.name.setValue("");
    this.leasevalidform.controls.place.setValue("");
    this.leasevalidform.controls.fromDate.setValue("");
    this.leasevalidform.controls.toDate.setValue("");
    this.leasevalidform.controls.email.setValue("");
    this.leasevalidform.controls.phone.setValue("");
  }

  //LEASE ENQUIRY MAIL
  private initSubmiteleaseEnquiry(): void {
    this.leasevalidform = this.formBuilder.group({
      name: ['', Validators.required],
      place: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  get f2() { return this.leasevalidform.controls };

}
