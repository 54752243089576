import { AlertService } from 'ngx-alerts';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import Splide from '@splidejs/splide';
import { filter } from 'rxjs/operators';
import { UtilityService } from 'src/app/services/utility-service/utility.service';

@Component({
  selector: 'app-hekaya-route-map',
  templateUrl: './hekaya-route-map.component.html',
  styleUrls: ['./hekaya-route-map.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class HekayaRouteMapComponent implements OnInit {

  location: any;
  routerSubscription: any;
  appSettings: any;
  currentYear: number = new Date(). getFullYear();
  isAdmin:boolean = true;

  constructor(private _router: Router
    , private alertService: AlertService
    , private _utilityService: UtilityService) { }


  ngOnInit(): void {

    this.recallJsFuntions();

    this._utilityService.getAppSettings().subscribe(appSettings => {

      if(appSettings){
        this.appSettings = appSettings;
      }
    });
    
    this.getUserDetails();

  }

  getUserDetails(){
    this._utilityService.getAppSettings().subscribe(appSettings => {

      if(!appSettings.isAdmin){
        this.isAdmin = false
      }
    });
  }

  onClick(){
    
    if(this.appSettings.isAdmin){
      this.alertService.danger("Login and find more routes");
    } else if(!this.appSettings.isAdmin) {
      this._router.navigate(['/Map-Detail-Page']);
    }
    
  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}

}
