<!-- <app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header> -->

<ngx-alerts></ngx-alerts>

<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/login-bg.jpg" alt="image" loading="lazy">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/logodark.png" alt="image" loading="lazy"></a>
                            </div>

                            <h3>Forgot Password?</h3>
                            <p>Verification link will send to your email address.</p>

                            <form [formGroup]="forgotFrom" >
                                <div class="form-group">
                                    <input type="email" placeholder="Your Email Address" class="form-control"
                                        formControlName="emailAddress">
                                </div>



                                <button type="submit" (click)="onSend()">Send</button>

                                <div class="forgot-password">
                                    <a routerLink="/sign-in">Back to Login</a>
                                </div>


                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>