<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>


<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>Explore Oman</h2>

        </div>
    </div>
</div>

<div class="hekaya-container">
    <div class="row  hekayat-titl top-row-margin">
        <div class="col-md-8 col-sm-12 col-lg-8">
            <h2 class="hekayat-titl"><span class="hekayat-color">Oman</span> Overview</h2>
            <p style="text-align:justify">Oman is situated in the south eastern part of the Arabian Peninsula. It is
                surrounded by the sea on two sides, the sea of Oman to the northeast and the Indian Ocean to the
                southeast; it has joint land borders with Saudi Arabia to the west, Yemen to the south, and the United
                Arab Emirates to the north.</p>
            <!-- <h2 class="hekayat-titl">About <span class="hekayat-color">Oman</span> </h2> -->
            <p style="text-align:justify">Sultanate of Oman is known for its strategic and historical location, as well
                as its authentic beauty in a semi-desert area. It is located in West Asia, in the far south-east of the
                Arabian Peninsula between latitudes 40 <<16>> 20 << "26  N  and  longitudes  50>> 51>>  40  <<  " 59 E.
                        Its coastline stretches from Strait of Hormuz in the north until the borders of Yemen,
                        overlooking 3 seas: The Arabian Gulf, Sea of Oman, and The Arabian Sea. It shares land borders
                        with the UAE and Saudi Arabia from the west, Yemen from the south, Strait of Hormuz and the
                        Islamic Republic of Iran from the north and overlooks the Arabian Sea and the Indian Ocean
                        from the east. </p>
                        <p class="text-justify">Oman is one of the most stable countries in the region. It has an incredible
                            diversity of landscapes, from wadis lost in the mountains to white sand dunes flowing into the sea. It
                            is also home to one of the most welcoming people in the world.</p>
                        
                        <div class="hekaya-container">
                        <div class="row justify-content-center">
                            <button class="btn text-center explore-button btn-md"
                                style="margin-top: 50px;margin-bottom: 50px;" routerLink="/Oman-weather">Know
                                Weather</button>
                        </div>
        </div>
    </div>

    <div class="col-md-4 col-lg-4 col-sm-12">
        <img src="../../../../assets/img/self-drive/about-oman/oman.png" class="img-fluid" loading="lazy"/>
    </div>
</div>
</div>

<!-- <div class="hekaya-container">
    <div class="row">
        <div class="col-md-1 col-lg-1 col-sm-12 text-center top-row-margin"></div>
        <div class="col-md-2 col-lg-2 col-sm-12 text-center top-row-margin">
            <img src="../../../../assets/img/self-drive/oman/1.png" class="img-fluid img-icon" />
            <h2 class="category-icon icon-title">Oman<br>Overview</h2>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-12 text-center top-row-margin">
            <img src="../../../../assets/img/self-drive/oman/2.png" class="img-fluid img-icon2" />
            <h2 class="category-icon icon-title">Oman<br>Route</h2>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-12 text-center top-row-margin">
            <img src="../../../../assets/img/self-drive/oman/3.png" class="img-fluid" />
            <h2 class="category-icon icon-title">Hekayat<br>Guidance</h2>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-12 text-center top-row-margin">
            <img src="../../../../assets/img/self-drive/oman/4.png" class="img-fluid" />
            <h2 class="category-icon icon-title">Oman<br>Weather</h2>
        </div>
        <div class="col-md-2 col-lg-2 col-sm-12 text-center top-row-margin">
            <img src="../../../../assets/img/self-drive/oman/5.png" class="img-fluid img-icon" />
            <h2 class="category-icon icon-title">Hekayat<br>Categories</h2>
        </div>
    </div>
</div> -->



<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero">
                <div class="center-content">
                    <h1>The Sultanate of Oman</h1>
                    <h3> Generosity has a destination</h3>

                </div>
                <div class="center-content2">
                    <!-- <h1>اكتشف عمان</h1> -->
                    <!-- <h3>اصنع رحلة أحلامك إلى عمان</h3> -->
                </div>
            </header>
        </div>
    </div>
</div>

<!-- <div class="hekaya-container" style="margin-top: 50px;margin-bottom: 50px;">
    <div class="row justify-content-center">
        <button class="btn text-center explore-button btn-md" routerLink="/Driving-Category"> Read More</button>
    </div>
</div> -->

<!-- <div class="hekaya-container">
    <div class="row text-left hekayat-titl top-row-margin">
        <div class="col-md-12">
            <img src="../../../../assets/img/self-drive/routecategory.jpg" class="img-fluid" />
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12">
            <h2 class="hekayat-titl">Why Visiting<span class="hekayat-color"> Oman</span></h2>
            <p class="text-justify">Oman is one of the most stable coun-tries in the region. It has an incredible
                diversity of landscapes, from wadis lost in the mountains to white sand dunes flowing into the sea. It
                is also home to one of the most welcoming people in the world.</p>

        </div>
    </div>
</div>
 -->

<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-sm-12 col-lg-12 text-center">
            <h2 class="hekayat-titl">Why go to<span class="hekayat-color"> self-drive and camping option</span></h2>
            <p class="text-center">It is extremely easy to self-drive and camp around Oman because:</p>
        </div>
        <div class="row top-row-margin">
            <div class="col-md-5 col-lg-5 col-sm-12">
                <div class="single-destinations-box">
                    <img src="../../../../assets/img/self-drive/about-oman/78.jpg" alt="image" class="box-img" loading="lazy"> 
                </div>
            </div>
            <div class="col-md-7 col-lg-7 col-sm-12">
                <ul class="features-list box-list text-justify">
                    <li>One of the best economical options to discover Oman.</li>
                    <li>Oman is ranked as one of the safest and most relaxing
                        countries in the world and is home to some of the most welcoming people in the world (Safety and
                        security precautions always needs to be taken)</li>
                    <li>Easy to drive at the roads of Oman as Oman has one of the best
                        road infrastruc-ture and road net in the Middle East. Most of main road between cities and
                        sub-roads are provided with Lights</li>
                    <li>All roads are provided with safety and guidance signages in
                        Arabic and English Languages.</li>
                </ul>

            </div>
        </div>
    </div>
    <div class="row top-row-margin">

        <div class="col-md-7 col-lg-7 col-sm-12">
            <ul class="features-list box-list2 text-justify">
                <li>All roads are marked, wide and provided with the two sides
                    safety lane (Yellow side lane). Most main roads are provided with RADARs</li>
                <li>At areas with risk attention (crossing animals, traffic,
                    others), speed bumps are existed.</li>
                <li>Most of areas are provided with necessary services
                    including fuel, food, and etc.</li>
                <li>No rules restrict camping at Oman. Visitors may camp at
                    all remote public areas as long as it’s away from the villages and resident areas. It is
                    recommended to avoid camping at wadis and mountain areas during raining.</li>
                <li>Public toilets are available at most areas including fuel
                    stations.</li>
            </ul>

        </div>
        <div class="col-md-5 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="../../../../assets/img/self-drive/about-oman/ExploreOmanrightImage.jpg" alt="image" class="box-img" loading="lazy">
            </div>
        </div>
    </div>

</div>





<section class="destinations-area">
    <div class="container">
         <div class="section-titl">
          <h2>Design Your Trip Just In Your Way</h2>
      </div>

      <div class="row justify-content-center" style="margin-top: 20px;margin-bottom: 30px;">
        <button class="btn text-center explore-button btn-md"
            style="margin-top: 20px;margin-bottom: 30px;" routerLink="/Hekayat-route-map">Find More</button>
    </div>

        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="../../../../assets/img/self-drive/about-oman/125.jpg" alt="image" loading="lazy">

                    <div class="content">
                        <!--   <h3>New York</h3> -->
                        <!--  <div class="rating">
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                      </div> -->
                    </div>

                    <!--  <span class="listing-count">125 Listings</span> -->

                    
                </div>
            </div>

            <div class="col-lg-8 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="../../../../assets/img/self-drive/about-oman/50.jpg" alt="image" loading="lazy">

                    <div class="content">
                        <!--   <h3>Paris</h3> -->
                        <!--  <div class="rating">
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                          <i class='bx bxs-star'></i>
                      </div> -->
                    </div>

                    <!--  <span class="listing-count">50 Listings</span> -->

                    
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="../../../../assets/img/self-drive/about-oman/15.jpg" alt="image" loading="lazy">

                            <div class="content">
                                <!--     <h3>Moscow</h3> -->
                                <!--  <div class="rating">
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                              </div> -->
                            </div>

                            <!--  <span class="listing-count">15 Listings</span> -->

                           
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="../../../../assets/img/self-drive/about-oman/ExploreOmanLastImageCar.jpg" alt="image" loading="lazy">

                            <div class="content">
                                <!--   <h3>London</h3> -->
                                <!-- <div class="rating">
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                                  <i class='bx bxs-star'></i>
                              </div> -->
                            </div>

                            <!--   <span class="listing-count">78 Listings</span> -->

                           
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  <div class="destinations-btn-box">
          <a routerLink="/listing-one" class="default-btn">View All Destinations</a>
      </div> -->
    </div>
</section>



<!-- <header class="hero-last ">
    <div class="center-content">
        <h1>Know Oman's Weather</h1>
        <h3>Lorem ipsum, or lipsum as it is sometimes known</h3>

    </div>
    <div class="center-content2">
          <h1>تعرف على طقس عمان</h1>
      <h3>Lorem ipsum أو lipsum كما يُعرف أحيانًا</h3>
        <button class="btn btn-info btn-lg book-now" routerLink="/Oman-weather">Explore More</button>
    </div>
</header> -->




<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
