<header class="header-area">
    <div class="top-header d-none d-lg-block">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="header-contact-info">
                       
                        <li>Call: <a href="tel:+01321654214">+968 77884125</a></li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-12">

                </div>

                <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">
                        <li><a routerLink="/login"><i class='bx bxs-user'></i> My Account</a></li>
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- blue header -->
    <div class="navbar-area ">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/logo.png" alt="logo" loading="lazy">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/logo.png" alt="logo" loading="lazy">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home
                                   </a>
                               
                            </li>

                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">About Us
                                    </a>
                                
                            </li>
                            <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Services
                                    </a>
                               
                            </li>
                           
                           

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <div class="search-btn-box">
                                       
                                    </div>
                                </div>

                                <div class="option-item">
                                    <div class="cart-btn">
                                       
                                    </div>
                                </div>

                               
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>