import { vehicleType } from './../../../../models/vehicle-type/vehicleType.model';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { LocationService } from 'src/app/services/location/location.service';
import { Location } from '../../../../models/location/location.model';
import { VehicleService } from 'src/app/services/pick-park-service/vehicle-type/vehicle.service';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Duration } from 'ngx-bootstrap/chronos/duration/constructor';
import { Meta } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pick-drop',
  templateUrl: './pick-drop.component.html',
  styleUrls: ['./pick-drop.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})

export class PickDropComponent implements OnInit {
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  isModalShown = false;
  minDatePickUp: Date;
  maxDatePickUp: Date;
  minDateReturn: Date;
  maxDateReturn: Date;
  maxDate: Date;
  minDate: Date;

  minDateCollection: Date;
  minDateDelivery: Date;
  monthDateCollection: Date;
  monthDateDelivery: Date;

  carRental = environment.carRental;
  pickPark = environment.pickAndPark;
  selfDrive = environment.selfDrive;

  pickUpValidForm: FormGroup;
  isleaseEnquiry: boolean;
  locations: Array<Location>;
  times: Array<string>;
  vehiclesType: Array<vehicleType>;
  location: any;
  routerSubscription: any;
  parkOnlylocation: any;
  businessLocaton:any;
  pickAndDroplocations: any;
  pickAndParklocations: any;







  constructor(private formBuilder: FormBuilder
    , private alertService: AlertService
    , private _route: Router
    , private _utilityService: UtilityService
    , private locationService: LocationService
    , private vehicleService: VehicleService
    , private datepipe: DatePipe
    , private meta: Meta
    ,private renderer: Renderer2) {
    this.locations = new Array<Location>();
    this.vehiclesType = new Array<vehicleType>();
    this.times = new Array<string>();
    this.isleaseEnquiry = false;
    this.minDateDelivery = new Date();
    this.minDateCollection = new Date();
    this.monthDateDelivery = new Date();
    this.monthDateCollection = new Date();

  }



  ngOnInit(): void {

    // this.meta.addTag({ 
    //   name: 'canonical', 
    //   href: 'https://car-transfer.hekaya.co' 
    // });
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', 'https://car-transfer.hekaya.co');
    this.renderer.appendChild(document.head, canonicalLink);
    this.showModal();

    this.minDate = new Date();


    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.getVehicle();
        this.getPickAndDropLocations();
        this.getPickAndParkLocations();
      }


    });

    this.getTimes();
    this.initSubmitePickup();
    this.recallJsFuntions();
  }
  showModal(): void {
    this.isModalShown = true;
  }
 
  hideModal(): void {
    this.autoShownModal?.hide();
  }
 
  onHidden(): void {
    this.isModalShown = false;
  }

  public isSubmitePickupEnquiry(): void {

    this.isleaseEnquiry = true;

    if (this.pickUpValidForm.invalid) {
      if (!this.pickUpValidForm.controls.pickupLocation.value) {
        this.alertService.danger('Please fill in your Pickup Location')
      } else if (!this.pickUpValidForm.controls.pickDate.value) {
        this.alertService.danger('Please fill in Pick Date ')
      } else if (!this.pickUpValidForm.controls.pickupTime.value) {
        this.alertService.danger('Please fill in To Pickup Time ')
      } else if (!this.pickUpValidForm.controls.returnDate.value) {
        this.alertService.danger('Please fill in your Return Date')
      } else if (!this.pickUpValidForm.controls.returnTime.value) {
        this.alertService.danger('Please fill in your Return Time ')
      }
    }
    else {


      const pickUpLocation: number = parseInt(this.pickUpValidForm.controls.pickupLocation.value);
      const vehicleType: number =
        this.pickUpValidForm.controls.vehicleType.value ?
          parseInt(this.pickUpValidForm.controls.vehicleType.value) : 0;
      const pickUpTime = this.pickUpValidForm.controls.pickupTime.value;
      const pickUpDate = this.pickUpValidForm.controls.pickDate.value;
      const returnDate = this.pickUpValidForm.controls.returnDate.value;
      const returnTime = this.pickUpValidForm.controls.returnTime.value;
      let inputData = `/${pickUpLocation},${vehicleType},${pickUpTime},${this.datepipe.transform(pickUpDate, 'dd-MMM-yyyy')},${this.datepipe.transform(returnDate, 'dd-MMM-yyyy')},${returnTime}`;

      this._route.navigate(['/pick-up-and-drop-off/search-results' + inputData]);
    }
  }

  get f() { return this.pickUpValidForm.controls };

  public onChangeReturnDifferentLocation() {


    const isParkOnly = this.pickUpValidForm.controls.isEnableParkOnly.value;
    if (isParkOnly) {
      this.pickUpValidForm.controls.pickupLocation.setValidators([Validators.required]);
    } else {
      this.pickUpValidForm.controls.pickupLocation.setValidators([Validators.required]);
      this.pickUpValidForm.controls.pickupLocation.setValue('');
    }
  }

  private initSubmitePickup(): void {
    this.pickUpValidForm = this.formBuilder.group({
      isEnableParkOnly: [false],
      pickupLocation: ['', Validators.required],
      vehicleType: ['',Validators.required],
      pickDate: ['', Validators.required],
      pickupTime: ['', Validators.required],
      returnDate: ['', Validators.required],
      returnTime: ['', Validators.required]
    });

  }

  private getTimes(): void {
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.times.push('0' + i + ':00');
        this.times.push('0' + i + ':30');
      } else {
        this.times.push(i + ':00');
        this.times.push(i + ':30');
      }
    }
  }

  private locationFilter(){

    this.parkOnlylocation = this.locations.filter(x => x.locationType === 1);


    this.businessLocaton = this.locations.filter(x => x.locationType === 2);


  }

  private getPickAndDropLocations(): void {

    this.locationService.locationByBookingModule(3).subscribe(locations => {
      this.pickAndDroplocations = locations.filter(x => x.locationType === 1);

      this.locationFilter();
    });

  }

  private getPickAndParkLocations(): void {

    this.locationService.locationByBookingModule(2).subscribe(locations => {
      this.pickAndParklocations = locations.filter(x => x.locationType === 1);

      this.locationFilter();
    });

  }

  private getVehicle(): void {

    this.vehicleService.getAllVehicle().subscribe(vehicles => {
      this.vehiclesType = vehicles;

    });
  }

  public dateSelect(event: Date) {
    this.minDateCollection = event;

  }


  recallJsFuntions() {
    this.routerSubscription = this._route.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._route.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}




}
