import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { vehicleType } from 'src/app/models/vehicle-type/vehicleType.model';
import { GenericService } from '../../generic-service/generic.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private genericService: GenericService) { }

  getAllVehicle(): Observable<Array<vehicleType>> {
    const serviceUrl = RoutingConstants.vehicleType.getAllVehicleType;
    return this.genericService.getService(serviceUrl, true);
  }
}
