<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>Self Camping</h2>
            <!-- <h4>Plan</h4> -->
        </div>
    </div>
</div>


<section class="about-area ">
    <div class="container">

        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <h3>Self-Camping Guidance</h3>
                        <p>Outdoor Self-camping is free anywhere and within the incredible diversity of landscapes, from wadis lost in the mountains to white sand dunes flowing into the sea make it a perfect country for outdoor enthusiasts.</p>
                     
                    </div>
                </div>     
            </div>
        </div>  
        
    </div>
</section>


<section class="about-area pb-100">
    <div class="container">

        <div class="about-inner-area">
            <div class="row">
                <div class="">
                    <div class="about-text">
                        <h3>Where to Camp ?</h3>
                        <p>Wild camping at different habitats is legal in Oman, and you may basically pitch your tent anywhere as long as staying away from villages, resident areas and around main grazing areas. Please check our recommendations, where not to camp below.Generally,  no  official  campsites  in  the  region  which  provides  any  type  of  camping  facil-ities.  Hence,  while  camping  it  will  be  you,  your  Hekayat  provided  Equipment’s  and  the  view.</p>
                        <br>
                        <p>As said, while avoiding where not to camp locations, you may camp anywhere. However, below table listing some of the best popular spots for camping through-out the Sultanate which are commonly used by locals and outside visitors. </p>
                     
                    </div>
                </div>     
            </div>
        </div>  
        
    </div>
</section>



<div class="hekaya-container">
    <section class="hekaya-banner-Hekayat ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="">
            <div class="hekaya-content">
              
                <h2>Enjoy your <br> <span> Camping with us.</span></h2>
                <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class='bx bx-play'></i></a> -->
            </div>
        </div>
    </section>
    </div>

    <section class="about-area pb-100">
        <div class="container">
    
            <div class="about-inner-area">
                <div class="row">
                    <div class="">
                        <div class="about-text">
                            <div class="row align-items-center" style="margin-top: 10px;">
                                <div class="col-lg-6 col-md-12" >
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3>Choose the Right Camping Site </h3>
                                                <p>Please use where to camp guide and avoid locations mention for where not to camp. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3>Risk Potentials </h3>
                                                <p>Understand all risk potentials mention for camping at each different habitat in Oman as per Hekayat Categories.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3> Stay up to Date with the Weather Condition  </h3>
                                                <p>You  may  check  Oman  Official  Directorate  General  of  Meteorology  Web  at  www.met.gov.om  for  more  weather  information  and  any  issued  weather  alerts. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3>Choose the Right Camping Site </h3>
                                                <p>Please use where to camp guide and avoid locations mention for where not to camp. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3> Pack and Store your Food  </h3>
                                                <p>Pack your food in tight, waterproof containers and store them in an insulated cooler box. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3>Heat Sources</h3>
                                                <p>If  welling  to  cook  or  starting  a  fire,  ensure  all  source  of  heat  are  a  way  from  your  vehicle  and  equipment’s. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3>Allergies </h3>
                                                <p>Be Aware of Allergies and take all necessary precautions. Packing an EpiPen or other medications for your known allergies is a smart way to prepare for any unexpected encounters.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                
                                <div class="col-lg-6 col-md-12">
                                    
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3> Your Equipment’s Security </h3>
                                                <p>Even  if  you  are  in  safe  and  secure  place.  Take  all  precautions  and  ensure  to  stay close to your vehicle and equipment’s for security reasons. If welling to go  far  to  do  any  type  of  activities,  well  pack  your  equipment’s,  do  not  keep  any expensive belongs visible to others and lock the vehicle. We recommend in such cases to try to park your vehicle in proper secure place. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3> Protection from Sun</h3>
                                                <p>Protect your skin form the Sun. Use protections, seeking shade, wearing a hat, or putting on sunglasses can help protect you from the UV rays. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3> Watch Out for Wildlife</h3>
                                                <p>Some areas are inhabited with different species of wildlife which can be dan-gerous  to  you  including  desert  snacks,  scorpions,  spiders  etc.  Hence,  we  rec-ommend taking precautions. You may ensure to sleep in your tent while clos-ing it and keeping ventilation through upper window opens. </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3> Stay Hydrated</h3>
                                                <p>Ensure  you  have  plenty  of  water  while  camping  far  from  the  main  roads  and  towns.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="about-text ">
                                        <ul>
                                            <li>
                                                <h3>  Network Coverages</h3>
                                                <p>We recommend trying camping in places which has network coverage as pos-sible to be able to contact us or emergency Oman number in case of any emer-gencies. </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                
                            <div class="about-inner-area">
                                <div class="row">
                                    <div class="">
                                        <div class="about-text">
                                            <h3>Where not to Camp</h3>
                                            <p>Below are some areas, where we are not recommend to camp as for legal, safety, security, or social reasons.</p>
                                            <ul>
                                                <li> <p>At Cities Centres and Souqs </p>  </li>
                                                <li> <p> Private Lands/Property</p>  </li>
                                                <li> <p> Sensitives Habitat</p></li>
                                                <li> <p>At Water Streaming Paths in Wadies</p>    </li>
                                                <li> <p> Pets Grazing Areas(Camels/Goats/Cows etc.)</p> </li>
                                                <li><p>Cultivated Area</p></li>
                                                <li><p>Close to Clift’s</p></li>
                                                <li><p>Close to Roads/Tracks</p></li>
                                            </ul>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>
                         
                        </div>
                    </div>     
                </div>
            </div>  
            
        </div>
    </section>

    


<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-self-drive-footer>