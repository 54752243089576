import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { filter } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location/location.service';
import { DrivingCategoryService } from 'src/app/services/self-drive/driving-category/driving-category.service';
import { VehicleCategoryService } from 'src/app/services/self-drive/vehicle-category/vehicle-category.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { environment } from 'src/environments/environment';
import { EquipmentsCategory } from '../self-drive/models/gear-rental/equipments/equipments.model';
import { DrivingCategory, VehicleCategory } from '../self-drive/models/search/search-request.model';
import { GearRentalServiceService } from '../self-drive/services/gear-rental/gear-rental-service.service';
import { Location } from '../../../models/location/location.model';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-selfdrive-home',
  templateUrl: './selfdrive-home.component.html',
  styleUrls: ['./selfdrive-home.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class SelfdriveHomeComponent implements OnInit {
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  // @ViewChild('contactTab') contactTab: any;
  isModalShown = false;
  minDateCollection: Date;
  maxDateReturn: Date;
  minDateDelivery: Date;
  maxDatePickUp: Date;
  minDate: Date;
  maxDate: Date;

  carRental = environment.carRental;
  pickPark = environment.pickAndPark;
  selfDrive = environment.selfDrive;

  selfDriveValidForm: FormGroup;
  gearRentalValidForm: FormGroup;
  isSelfDriveForm:boolean;
  locations: Array<Location>
  gearlocations: Array<Location>
  drivingCategorySettings: IDropdownSettings = {};
  equipmentsCategorySettings: IDropdownSettings = {};
  vehicleCategorySettings: IDropdownSettings = {};
  location: any;
  routerSubscription: any;
  drivingType: Array<DrivingCategory> ;
  vehicleCategory: Array<VehicleCategory>;
  times: Array<string>;
  filteredSelectedTime: Array<string>;
  equipments: Array<EquipmentsCategory>;
  flag: number;



  constructor(
      private formBuilder: FormBuilder
    , private alertService: AlertService
    , private _route:Router
    , private locationService: LocationService
    , private _vehicleCategoryService: VehicleCategoryService
    , private _utilityService: UtilityService
    , public _datepipe: DatePipe
    , private _drivingCategoryService: DrivingCategoryService
    , private gearRentalService: GearRentalServiceService) {

      this.locations = new Array<Location>();
      this.gearlocations = new Array<Location>();
      this.isSelfDriveForm = false;
      this.minDateDelivery = new Date((new Date()).getTime() + (3 * 24 * 60 * 60 * 1000));
      this.minDateCollection = new Date();
      this.vehicleCategory = new Array<VehicleCategory>();
      this.drivingType=new Array<DrivingCategory>();
      this.times = new Array<string>();
      this.filteredSelectedTime = new Array<string>();
      this.equipments = new Array<EquipmentsCategory>();
    }

  ngOnInit(): void {
    this.showModal();

    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.getLocations();
      }
    });

    this.getEquipments();
    this.getDrivingCategory();
    this.initSubmiteSelfDrive();
    this.initsubmitGearRental();
    this.recallJsFuntions();
    this.getVehicle();
    this.getTimes();

  }
  // ngAfterViewInit() {
    // Trigger click on the tab using ViewChild after the view has been initialized
  //   this.contactTab.nativeElement.click();
  // }
  showModal(): void {
    this.isModalShown = true;
  }
 
  hideModal(): void {
    this.autoShownModal?.hide();
  }
 
  onHidden(): void {
    this.isModalShown = false;
  }

  public dateSelect() {
    this. minDateCollection = this.selfDriveValidForm.controls.fromDate.value;
    // this.minDateDelivery = this.selfDriveValidForm.controls.fromDate.value;
    this.minDateCollection = new Date(this.minDateCollection.getTime() + (2 * 24 * 60 * 60 * 1000));
  }

  public geardateSelect() {
    this. minDateCollection = this.gearRentalValidForm.controls.fromDate.value;
    this.minDateDelivery = this.gearRentalValidForm.controls.fromDate.value;
    this.minDateCollection = new Date(this.minDateCollection.getTime() + (2 * 24 * 60 * 60 * 1000));
  }

  public getSefdriveFilterdTime(){
    this.filteredSelectedTime = this.times.filter(time => time >= this.selfDriveValidForm.controls.pickUpTime.value);
  }

  public getGearRentalFilterdTime(){
    this.filteredSelectedTime = this.times.filter(time => time >= this.gearRentalValidForm.controls.pickUpTime.value);
  }


  //Self Drive search function
  public isSubmiteSelfDrive(): void{

    this.isSelfDriveForm=true;

    if (this.selfDriveValidForm.invalid) {
      if(!this.selfDriveValidForm.controls.Location.value){
        this.alertService.danger('Please select your Location')
      }else if(!this.selfDriveValidForm.controls.fromDate.value){
         this.alertService.danger('Please select your starting date ')
      }else if(!this.selfDriveValidForm.controls.toDate.value){
        this.alertService.danger('Please select your ending date ')
      }else if(!this.selfDriveValidForm.controls.pickUpTime.value){
        this.alertService.danger('Please select your time of Pick Up')
      }else if(!this.selfDriveValidForm.controls.retureTime.value){
        this.alertService.danger('Please select your time on Return')
      }else if(!this.selfDriveValidForm.controls.numberOfPerson.value){
        this.alertService.danger('Please select number of person')
      }
    }
    else{

      let vehicleCategoryType = this.selfDriveValidForm.controls.vehicleType.value;
      let applicableVehicleType = [];

      if(vehicleCategoryType == 0){
        vehicleCategoryType = null
      } else {
      vehicleCategoryType.forEach(type =>{
        applicableVehicleType.push(type.id);
      });
      }

      var type ;
      if(type) {
        type = applicableVehicleType.join("-");
      } else {
        type = null;
      }

      const pickUp: number = parseInt(this.selfDriveValidForm.controls.Location.value);
      let dropOff: number = 0;
      const fromDate = this.selfDriveValidForm.controls.fromDate.value;
      const toDate = this.selfDriveValidForm.controls.toDate.value;
      const pickUpTime = this.selfDriveValidForm.controls.pickUpTime.value;
      const returnTime = this.selfDriveValidForm.controls.retureTime.value;
      //const drivingCategory = applicableDelivery;
      const vehicleType = applicableVehicleType;
      //const numberOfPerson = this.selfDriveValidForm.controls.numberOfPerson.value;

      //const vehicleType = vehicleCategoryType;
      const numberOfPerson = this.selfDriveValidForm.controls.numberOfPerson.value;
      const flag: number = 0;

      const inputData = `/${pickUp}-${dropOff},${this._datepipe.transform(fromDate, 'dd-MMM-yyyy')},${this._datepipe.transform(toDate, 'dd-MMM-yyyy')},${pickUpTime},${returnTime},${vehicleType},${numberOfPerson},${flag}`;


      this._route.navigate(["/self-drive/self-drive-result/pick-up" + inputData]);


    }
  }


  //Self Drive Form Control
  private initSubmiteSelfDrive(): void {
    this.selfDriveValidForm = this.formBuilder.group({
      Location: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      retureTime: ['',Validators.required],
      drivingCategory: [''],
      vehicleType: [''],
      numberOfPerson: ['',Validators.required]
    });

  }

  //Gear rental search function
  public isSubmiteGearRental(): void{
    this.isSelfDriveForm=true;

    if (this.gearRentalValidForm.invalid) {
      if(!this.gearRentalValidForm.controls.location.value){
        this.alertService.danger('Please select your Location')
      }else if(!this.gearRentalValidForm.controls.fromDate.value){
         this.alertService.danger('Please select your starting date ')
      }else if(!this.gearRentalValidForm.controls.toDate.value){
        this.alertService.danger('Please select your ending date ')
      }else if(!this.gearRentalValidForm.controls.pickUpTime.value){
        this.alertService.danger('Please select your Pick Up Time')
      }else if(!this.gearRentalValidForm.controls.retureTime.value){
        this.alertService.danger('Please select your Return Time')
      }
    }
    else{


      const pickUp: number = parseInt(this.gearRentalValidForm.controls.location.value);
      let dropOff: number = 0;
      const fromDate = this.gearRentalValidForm.controls.fromDate.value;
      const toDate = this.gearRentalValidForm.controls.toDate.value;
      const pickUpTime = this.gearRentalValidForm.controls.pickUpTime.value;
      const returnTime = this.gearRentalValidForm.controls.retureTime.value;
      const equipments: number = 3;

      const urlData = `/${pickUp}-${dropOff},${this._datepipe.transform(fromDate, 'dd-MMM-yyyy')},${this._datepipe.transform(toDate, 'dd-MMM-yyyy')},${pickUpTime},${returnTime},${equipments}`;


      this._route.navigate(["/self-drive/gear-select/pick-up" + urlData]);
    }

  }

  //Gear Rental
  private initsubmitGearRental():void{
    this.gearRentalValidForm = this.formBuilder.group({
      location: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      pickUpTime: ['', Validators.required],
      retureTime: ['',Validators.required],
      equipments: [''],
    });
  }

  // location response
  private getLocations(): void {

    this.locationService.locationByBookingModule(4).subscribe(location => {
      this.locations =location;
    })

    this.locationService.locationByBookingModule(5).subscribe(location => {
        this.gearlocations =location;
      })
  }

  //Equipments Category , Gear Rentals

  private getEquipments(): void {
    this.gearRentalService.getAll().subscribe((gears) => {
      this.equipments = gears;
      this.equipmentsCategorySettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
      }

    })
  }

  private getDrivingCategory(): void {
    this._drivingCategoryService.getAll().subscribe((category) => {
      this.drivingType = category;
      this.drivingCategorySettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
      }


    })
  }

  private getVehicle() : void {
    this._vehicleCategoryService.getAll().subscribe(category =>{
      this.vehicleCategory = category;
      this.vehicleCategorySettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
      }
    })
  }


  private getTimes(): void {
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.times.push('0' + i + ':00');
        this.times.push('0' + i + ':30');
      } else {
        this.times.push(i + ':00');
        this.times.push(i + ':30');
      }
    }
  }


  onItemSelect(item: any) {

  }


  recallJsFuntions() {
    this.routerSubscription = this._route.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._route.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}

}
