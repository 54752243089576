import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility-service/utility.service';

@Component({
  selector: 'app-pick-drop-footer',
  templateUrl: './pick-drop-footer.component.html',
  styleUrls: ['./pick-drop-footer.component.scss']
})
export class PickDropFooterComponent implements OnInit {

  public appSettings: any;
  currentYear: number = new Date(). getFullYear();
  constructor(private _utilityService: UtilityService
    , private router: Router) { }

  ngOnInit(): void {
  }

  myAccount(){
    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.appSettings = appSettings;

        if(!appSettings?.isAdmin){
          this.router.navigate(['/pick-up-and-drop-off/myProfile']);
        } else {
          this.router.navigate(['/pick-up-and-drop-off/Login']);
        }
      }
    });
  }
}
