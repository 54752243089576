<form (submit)="onSubmitcarDeliveryLocationForm()" name="need-car-delivery" autocomplete="off">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group transparent-field autotxt">
                <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Delivery City, Town, Location, etc'" [entries]="deliveryLocations" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,1)"
                    [(ngModel)]="carDeliveryLocationForm.deliveryCity" name="delivery-city" (selectedValue)="onSelectItem($event,1)" (focus)="showMoreOptions=true">
                </ngx-autocomplete>
            </div>
            
        </div>

        <div class="col-md-6"> 
            <div class="form-group">
                <input autocomplete="off" type="text" class="form-control " name="delivery-address" placeholder="Delivery Address..." [(ngModel)]="carDeliveryLocationForm.deliveryAddress">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 left_text">
            <div class="form-check">
                <input type="checkbox" class="form-check-input col_adress  col_add " id="car-rental-collection-address" name="check-different-location" (change)="onChangeCollectionLocation()" [(ngModel)]="carDeliveryLocationForm.isEnableDifferentCollectionAddress">
                <label class="form-check-label" name="col_adress" for="car-rental-collection-address">Change Collection
                    Address</label>
            </div>
        </div>
    </div>

    <div class="row mt-2" [hidden]="!carDeliveryLocationForm.isEnableDifferentCollectionAddress">
        <div class="col-md-6">
            <div class="form-group transparent-field autotxt">
                <ngx-autocomplete #collectionCity [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Collection City'" [entries]="collectionCities" autocomplete="off" class="form-control pl-0" (keyup)="onEnterLocation($event,2)" [(ngModel)]="carDeliveryLocationForm.collectionCity"
                    name="collection-city" (selectedValue)="onSelectItem($event,2)">
                </ngx-autocomplete>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <input type="text" class="form-control " name="collection-address" placeholder="Collection Address..." [(ngModel)]="carDeliveryLocationForm.collectionAddress">
            </div>
        </div>

    </div>

    <div class="row row_mar">

        <div class="col-12 mt-3" [hidden]="!showMoreOptions">
            <div class="card card-body more_control">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">                           
                            <input type="text" class="form-control" placeholder="Delivery Date" name="delivery-date"
                            [minDate]="minDateDelivery" [maxDate]="maxDatePickUp" #dp="bsDatepicker" bsDatepicker 
                            [(ngModel)]="carDeliveryLocationForm.deliveryDate" [readonly]="true" placement="top" (ngModelChange)=dateSelect()>
                        </div>
                    </div>
                    <div class="col-md-2">                        
                        <div class="form-group select">
                            <select class="form-control" [(ngModel)]="carDeliveryLocationForm.deliveryTime" name="delivery-time" (change)="getcarDeliveryFilterdTime()">
                                <option value="">Time</option>
                                <option *ngFor="let time of times" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">                          
                            <input type="text" class="form-control" placeholder="Collection Date" [minDate]="minDateCollection" [maxDate]="maxDateReturn" #dp="bsDatepicker" bsDatepicker [(ngModel)]="carDeliveryLocationForm.collectionDate" [readonly]="true" placement="top" name="collection-date">
                        </div>
                    </div>
                    <div class="col-md-2">                       
                        <div class="form-group select">
                            <select class="form-control" [(ngModel)]="carDeliveryLocationForm.collectionTime" name="collection-time">
                                <option value="">Time</option>
                                <option *ngFor="let time of times" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row align-items-center more_control align-items-center">
                        <div class="col-md-6 driver_check">
                            <div class="form-check">
                                <input id="driver18Plus" type="checkbox" class="form-check-input" id="has-liscence" [(ngModel)]="carDeliveryLocationForm.hasLiscence" name="driver-liscence">
                                <label for="driver18Plus" class="form-check-label" name="returncar" id="returncar" for="has-liscence">Driver age
                                    18+</label>
                            </div>
                        </div>
                        <div class="col-md-6  text-right pr-0">
                            <div class="form-check">
                                <input type="submit" class="btn btn-warning" value="Search" name="search">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="link_but" *ngIf="!showMoreOptions" (click)="showMoreOptions=true">
            <a style="cursor: pointer;">
                Show
            </a>
        </div>
    </div>
</form>