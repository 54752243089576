<p>home-salalah works!</p>

<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>



<app-home1></app-home1>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h1 class="hd_main">
                    Welcome to <br />
                    <span style="color: #a98e5f;">HEKAYA</span> Car Rental<br />
                    <span class="">in <i>Sohar</i></span>
                </h1>
                <p class="newstyl">
                    Welcome to Hekaya Car Rental in Sohar, your gateway to extraordinary journeys. Experience seamless and reliable car rental services that will elevate your exploration of this beautiful country.

                </p>
            </div>
            <div class="col-lg-6 d-flex align-items-end">
                <img class="img-fluid" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car-sample.png" alt=" White car available for Car rentals in Sohar">

            </div>
        </div>
    </div>
</section>


<app-home2></app-home2>

<section class="w-100 mt-5">
    <div class="container">
        <div class="bg_blk radius_20 p-5">
            <div class="row">

                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Top car rental in Sohar</h2>
                        <br>
                        <p class="text-white">Redefine the way you experience the open roads with Hekaya, the best car rental deal in Sohar. 
                            Capture some Sohar portraits in your mind while travelling through the vibrant streets, full of 
                            comfort.</p>
                        <p class="text-white">Elevate the quality of the voyages with the added features of our rental services. Your journey 
                            through Sohar starts with us, as we vow to provide excellent service at affordable prices.  </p>
                        <!-- <p class="text-white"  *ngIf="viewMore">Embrace the ease of owning your vehicle and 
                            forget about the other inconveniences on the road. Reserve your car with Hekaya
                             today to enjoy the beauty of Nizwa on your terms. </p> -->
                             <h2 *ngIf="viewMore">Why choose Us for Car Rental Services Sohar</h2>
                            <br>
                            <p class="text-white" *ngIf="viewMore">Hire a car from Hekaya Car Rental Services Sohar, the best car rental company in the area, and 
                                make your trip to Sohar unique. Selecting Hekaya guarantees smooth travel with a fleet of pristine 
                                cars, giving you the flexibility to see Sohar's captivating locations at your own pace.  </p>
                                <p class="text-white" *ngIf="viewMore">Established in 2015, Hekaya continues to add the stories of happy journeys to its legacy. Enjoy 
                                    the confidence of a reliable vehicle for any occasion, the freedom of an itinerary, and the ease of 
                                    door-to-door service.</p>
                                <p class="text-white" *ngIf="viewMore" >Hekaya makes your trip tales come to life, enabling you to easily and comfortably traverse Sohar's 
                                    landscapes and create priceless memories. You can rely on Hekaya to provide you with a smooth 
                                    and delightful automobile rental service that will enhance your Sohar journey. 
                                     </p>
    
                      
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
        
                            <h2>Testimonials</h2>
                            <br>
                            <h5 class="text-white">Richard Daniel</h5>
                        <p class="text-white">My trip to Sohar was made unforgettable by Hekaya Car Rental Services Sohar. My thoughts of 
                            taking a road trip became a reality because of their outstanding support and excellent fleet. 
                            Complete attention to detail was given to every aspect of the project. For anybody looking for a 
                            trustworthy and smooth car rental service, I heartily suggest Hekaya. They've gained a lifelong 
                            client. </p>  
                            <h5 class="text-white" *ngIf="viewMore">Nahas Mashar</h5>
                        <p class="text-white" *ngIf="viewMore">Hekaya, Car rental in Sohar, has been my preferred option for business trips in Sohar for a long 
                            time. Their team's level of professionalism and efficiency is unparalleled. I can concentrate on my 
                            work without having to worry about transportation because of the ease of having a well-maintained 
                            car at my service. Hekaya genuinely comprehends what a busy traveller wants. 
                            </p> 
                            <h5 class="text-white" *ngIf="viewMore">Dua Armaan</h5>
                        <p class="text-white" *ngIf="viewMore">Because of Hekaya, there was no trouble travelling to Sohar with my family. The friendly service 
                            and spacious automobiles made our journey unforgettable. The simple rental procedure and 
                            welcoming staff made sure that our journey was picture-perfect. There is no doubt that they are 
                            the best car rental deals in Sohar. Thank you, Hekaya. </p> 
                            <h5 class="text-white" *ngIf="viewMore">Niyas M </h5>
                        <p class="text-white" *ngIf="viewMore">Hekaya offers excellent service and is one of the most affordable car rentals in Sohar. Being 
                            an economical traveller, I like their affordable prices without sacrificing quality. The rental process 
                            was simple, and the automobiles were in good condition. Hekaya demonstrates that having an 
                            amazing trip doesn't have to break the bank. Highly recommended. </p> 
                       
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="!viewMore"><b> View More</b></span></div>
                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="viewMore"><b> View Less</b></span></div>
            </div>
        </div>
    </div>
</section>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h2 class="faq_hd">Frequently Asked
                    <br /> Questions
                </h2>
                <span>Can't find what you're looking for?<br />Visit our <a 
                        class="text-decoration-underline font-weight-bold" routerLink="/contact" target="_blank">Help centre</a></span>
            </div>
            <div class="col-md-8">
                <div class="accordion faqstyle faqopen" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">  What are the conditions of choosing a rental car company in sohar?   
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Take into account aspects such as rental costs, vehicle choice, and client reviews when selecting 
                                    a Car rental in sohar. Make sure all conditions are clear, including mileage restrictions and fuel 
                                    rules. Choose a firm that offers easy locations for pickup and drop-off, and make sure they have 
                                    dependable customer service in case you run into any problems while renting from them.  
                                     </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">How can I get an affordable rental car in Sohar at the last minute?  
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Compare online rates from several companies to get a Affordable Car Rentals in Sohar at the 
                                    last time. Possessing flexibility in terms of pickup and drop-off locations will help you locate a 
                                    better offer. Try utilising discount codes or renting from local rental companies. Making 
                                    reservations during off-peak hours might also enable you to acquire a more affordable price.                                     
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">Is it a good idea to choose Car rental company in Sohar?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Yes, it makes sense to select the Best Car Rental Deals in Sohar, if you want ease and flexibility 
                                    throughout your Sohar voyage. Through this, you can spend long hours discovering the city and 
                                    its surroundings. To make a smart decision that meets your travel needs, make sure you read 
                                    reviews, compare prices, and comprehend the conditions.
                                    </p>
                            </div>
                        </div>
                    </div>

                  
                </div>



                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="!faqViewMore"><b> View More</b></span></div>
                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="faqViewMore"><b> View Less</b></span></div>
          
            </div>
        </div>
    </div>
</section>


<!-- <app-feedback></app-feedback> -->


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>
