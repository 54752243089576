export const RoutingConstants = {

    contactUs: '/api/contact-us/enquiry',

    authentication: {
        register: '/client/user/create',
        signIn: '/client/auth/sign-in',
        refresh: '/client/auth/refresh',
        forgotPassword: '/client/auth/forgot-password',
        update: '/client/user/update',
        resetPassword: '/client/auth/reset-password'
    },

    location: {
        getAll: '/api/locations/get-all',
        googleLocation: '/api/google/get-locations-by-keyword',
        getLocationByBookingModule: '/api/locations/get-all-by-booking-module-id',
        getAllLocation:'/api/google/get-locations-by-placeid',
    },

    country: {
        getAllCountries: '/client/country/get-all'
    },

    carRental: {
        search: '/api/service/search',
        select: '/api/service/select',
        book: '/client/bookings/create',
    },

    myAccount: {
        bookings: '/client/bookings/get-all-bookings-for-userid',
        bookingByRef: '/client/bookings/get-booking-details-by-id',
        bookingRefByServiceType: '/client/bookings/get-all-bookings-for-userid-servicetype'
    },

    leaseEnquiry: {
        leaseAgreementMail: '/lease-agreement/send',
    },

    vehicleType: {
        getAllVehicleType: '/api/vehicle-type/get-all',
    },

    pickPark: {
        search: '/api/pick-and-park/search',
        bookings: '/client/bookings/create',
        bookingsByRef: '/client/bookings/get-booking-details-by-id',
    },

    pickAndDrop: {
        search: '/api/pick-and-drop/search',
        bookings: '/client/bookings/create',
        bookingsByRef: '/client/bookings/get-booking-details-by-id',
    },

    selfDrive: {
        drivingCategory: '/api/selfdrive/get-all-driving-category',
        vehicleCategory: '/api/selfdrive/get-all-self-drive-vehicle-category',
        equipmentGroup: '/api/equipments/get-all-equipments-group',

        search: '/api/selfdriveservice/search',
        select: '/api/selfdriveservice/select',
        booking:'/client/bookings/create',
        bookingByRef: '/client/bookings/get-booking-details-by-id',
    },

    gearRental: {
        equipmentsCategory: '/api/gearrentalservice/get-all-equipment-categories',

        search: '/api/gearrentalservice/search',
        booking: '/client/bookings/create',
        bookingByRef: '/client/bookings/get-booking-details-by-id'
    }
}
