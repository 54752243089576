import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newbooking',
  templateUrl: './newbooking.component.html',
  styleUrls: ['./newbooking.component.scss']
})
export class NewbookingComponent implements OnInit {
  seats_icon:string="../../assets/img/seats.png";
  door_icon:string="../../assets/img/door.png";
  trans_icon:string="../../assets/img/transmission.png";
  bag_icon:string="../../assets/img/bag.png";
  settings_icon="../../assets/img/settings.png";
  ac_icon:string="../../assets/img/ac.png";

  gps_icon:string="../../assets/img/gps.png";
  driver_icon:string="../../assets/img/driver.png";
  baby_icon:string="../../assets/img/baby.png";
  col_icon:string="../../assets/img/collision.png";
  acc_icon:string="../../assets/img/accident.png";
  constructor() { }

  ngOnInit(): void {
  }

}
