import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class ServicesComponent implements OnInit {

  location: any;
  routerSubscription: any;
  hostName= window.location.origin;

  pickPark= this.hostName + '/pick-up-and-drop-off'
  selfDrive= this.hostName + '/self-drive'

  constructor(private _router: Router
    , private meta: Meta
    , private titleService: Title
    ,private renderer: Renderer2) { }

  ngOnInit(): void {
    this.titleService.setTitle('Online Tourism Service Provider in Oman,Muscat | Hekaya');

    // this.meta.addTag({ 
    //   name: '', 
    //   href: 'https://car-rental.hekaya.co/hekaya-services' 
    // });
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', 'https://car-rental.hekaya.co/hekaya-services');
    this.renderer.appendChild(document.head, canonicalLink);

    this.meta.addTag({ 
      name: 'description', 
      content: 'Discover the beauty of Oman with the best online tourism service provider in Oman, Muscat. We offer a wide range of travel services to make your trip memorable.' 
    });

    this.meta.addTag({ 
      property: 'og:title', 
      content: 'Innvocs' 
    });

    this.meta.addTag({ 
      property: 'og:description', 
      content: 'Discover the beauty of Oman with the best online tourism service provider in Oman, Muscat. We offer a wide range of travel services to make your trip memorable.' 
    });

    this.meta.addTag({ 
      property: 'og:url', 
      content: 'Innvocs'
    });

    this.meta.addTag({ 
      property: 'og:type', 
      content: 'website' 
    });

    this.meta.addTag({ 
      name: 'og:image', 
      content: 'assets/img/services/services.jpg'
    });

    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}
}
