import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import Splide from '@splidejs/splide';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-habitat-category',
  templateUrl: './habitat-category.component.html',
  styleUrls: ['./habitat-category.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class HabitatCategoryComponent implements OnInit {

  
  location: any;
  routerSubscription: any;

  currentYear: number = new Date(). getFullYear();

  constructor(private _router: Router) { }


  ngOnInit(): void {
    new Splide( '#splide', {
      perPage : 3,
      lazyLoad: 'nearby',
      preloadPages: 1,
      arrows     : true,
      breakpoints: {
        600: {
            height: '25rem',
            perPage : 1
        }

    }
    } ).mount();

    this.recallJsFuntions();

    // new Splide( '#splide', {
    //   type   : 'loop',
    //   perPage: 3,
    // } );
    
      }

      recallJsFuntions() {
        this.routerSubscription = this._router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.location = this._router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

}
