<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>




<section class="about-area pt-4" style="margin-bottom: 30px;">
    <div class="container new_container">
        <div class="about-inner-area">
           

            <div class="row">
                <div class="col-12">
                      
                      <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="#">Home</a></li>
                          <li class="breadcrumb-item active" aria-current="page">Terms And Conditions</li>
                        </ol>
                      </nav>
                </div>
            </div>

           
            <div class="row">
                

                <div class="col-lg-8 pr-lg-5">
                    <div class="w-100 mb-5">
                        <h4 class="suhd text-left">Legal Terms</h4>
                        <h2 class="hdfont text-left">Terms and Conditions</h2>
                    </div>
               <div class="about-text w-100">
                        <h3 class="mt-0">Table of Content:</h3>

       <ol>
        <li><a (click)="scrollToSection('Definitions')">Definitions</a></li>
        <li><a (click)="scrollToSection('Collision')">Collision Damage Waiver (CDW)</a></li>
        <li><a (click)="scrollToSection('Full')">Full Protection </a></li>
        <li><a (click)="scrollToSection('Driver')">Driver Information </a></li>
        <li><a (click)="scrollToSection('Special')">Special Conditions</a></li>
        <li><a (click)="scrollToSection('Currency')">Currency calculations and fees</a></li>
        <li><a (click)="scrollToSection('Incidents')">Incidents involving another vehicle.</a></li>
        <li><a (click)="scrollToSection('Cancellation')">Cancellation by us</a></li>
        <li><a (click)="scrollToSection('General')">General Conditions / Eligibility Requirements</a></li>
        <li><a (click)="scrollToSection('Making')">Making a Claim</a></li>
        <li><a (click)="scrollToSection('Equipment')">Special equipment</a></li>
        <li><a (click)="scrollToSection('Collecting')">Collecting and returning your vehicle</a></li>
        <li><a (click)="scrollToSection('One')">One Way Rentals</a></li>
        <li><a (click)="scrollToSection('Fuel')">Fuel Policy </a></li>
        <li><a (click)="scrollToSection('Early')">Early Return/ No Shows:</a></li>
        <li><a (click)="scrollToSection('Late')">Late Arrival Policy</a></li>
        <li><a (click)="scrollToSection('Refunds')">Cancellation and refunds</a></li>
        <li><a (click)="scrollToSection('Traffic')">Traffic Violations</a></li>
        <li><a (click)="scrollToSection('Taking')">Taking your vehicle outside the country</a></li>
        <li><a (click)="scrollToSection('Payment')">Payment options</a></li>
        <li><a (click)="scrollToSection('Complaints')">Complaints/Disputes</a></li>
        <li><a (click)="scrollToSection('Governing')">Governing Law and Venue </a></li>
       </ol>

<div class="w-100" id="Definitions">
    <h3>Definitions</h3>
    <p>Any word defined below will have the same meaning throughout:</p>


    <table class="table table-bordered table-responsive-lg">
        <tr>
            <td>Accident or collision</td>
            <td>An accident or collision means a sudden, unexpected event caused by something external and visible, which results directly in loss or damage.</td>
        </tr>

        <tr>
            <td>Bodywork</td>
            <td>The metal frame of your rental vehicle.</td>
        </tr>

        <tr>
            <td>Call out fee</td>
            <td>A roadside assistance charge from a service provider to attend to a vehicle breakdown</td>
        </tr>

        <tr>
            <td>Certificate</td>
            <td>Certificate	A certificate means a validation Certificate issued that describes who is covered under this product. This is provided along with your confirmation email or invoice.
 </td>
        </tr>


        <tr>
            <td>Country of residence</td>
            <td>Your place of residence.</td>
        </tr>

        <tr>
            <td>Currency of Loss</td>
            <td>The currency that was originally charged for the damage charges.</td>
        </tr>

        <tr>
            <td>Distribution Partner</td>
            <td>Contractually authorized distributor of our service.</td>
        </tr>

        <tr>
            <td>Excess</td>
            <td>The amount paid - or payable - by you to the rental company, if there’s accidental damage or theft. Hekaya Car Rental products are "zero excess", meaning there is no excess deducted by us for a claim.</td>
        </tr>
        <tr>
            <td>Full value</td>
            <td>The market value of the rental car at the time of your claim. This is the cover limit and is the most we pay in the event of a claim.  </td>
        </tr>
        <tr>
            <td>“Not at fault”</td>
            <td>One of the drivers is not deemed responsible for damages after an investigation between the insurers of each driver.</td>
        </tr>
        <tr>
            <td>Natural weather event</td>
            <td>An event caused by natural forces, including but not limited to fire, flood, earthquake, explosion, tsunami, volcanic eruption, landslide, avalanche, hurricane, cyclone, or storm that was impossible to predict at the time you acquired this product.</td>
        </tr>
        <tr>
            <td>Plan</td>
            <td>Reimbursement product that reimburses the charges as outlined in the benefits table.</td>
        </tr>
        <tr>
            <td>Rental agreement</td>
            <td>The contract provided by a rental company with respect to the provision of a rental vehicle contains your signature confirming you agree to its terms.</td>
        </tr>
        <tr>
            <td>Rental company</td>
            <td>A commercial operation in business to rent out vehicles that are fully licensed, where applicable, by the regulatory authority of Oman, including online "share" or "peer to peer" websites, loan cars from a licensed mechanic, or accident replacement vehicles.</td>
        </tr>
        <tr>
            <td>Rental vehicle</td>
            <td>The private passenger automobile is rented from an authorized rental company - including mechanic loan cars and accident replacement cars - at the time the rental contract is signed, including online "share" or "peer to peer" websites, loan cars from a licensed mechanic, or accident replacement vehicles.</td>
        </tr>

        <tr>
            <td>Tax</td>
            <td>A government tax that is payable by you in addition to the cost of the product.</td>
        </tr>
        <tr>
            <td>Theft</td>
            <td>A rental vehicle that has been stolen, to either a known or unknown location, without your permission.</td>
        </tr>
        <tr>
            <td>Vandalism</td>
            <td>A rental vehicle that has been damaged intentionally by you or someone known or unknown to you.</td>
        </tr>
        <tr>
            <td>You/your</td>
            <td>The person named on the Certificate and any other person who drives the same rental vehicle and is listed on the same rental agreement.</td>
        </tr>
       
    </table>


</div>
<div class="w-100" id="Collision">
    <h3>Collision Damage Waiver (CDW)</h3>
    <p>The rental company’s basic Collision Damage Waiver (CDW) is by default included in your car rental. CDM reduces your liability if the vehicle is damaged. If the vehicle is damaged, you will pay the cost of repair up to the excess amount, even if you did not cause the damage.
        <p> Period of Cover: You are covered for the period as shown in your Certificate.</p>
        
</div>
<div class="w-100" id="Full">
    <h3>Full Protection</h3>
    <p>While CDW covers basic body damage, it has a high excess that you need to pay if there’s damage, even if you did not cause the damage. Full Protection covers your excess charges and other costly damage-related fees, and it covers various types of damage that CDW sometimes excludes. You can cancel at any time up until pickup for a refund. After pick up, full protection amount is not refundable at any time. </p>
    
       
       
    <p> With Full Protection, you are covered up to the full value of the car. This protection is designed to ensure the various costs applied by rental companies are covered, namely the excess that’s payable on any damages and related fees that are also charged.</p>
            <p>You are covered when you are charged for...</p>

        <ul>
            <li>Damage to the rental vehicle's bodywork.</li>
            <li>Theft or vandalism of the rental vehicle.</li>
            <li>Damage to windscreens, mirrors, and lights: Includes all external glass & lights.</li>
            <li>Damage to wheels & tires: Includes punctures, fittings, replacements, and repairs.</li>
            <li>Roof damage.</li>
            <li>Damage to the underbody of the vehicle.</li>
            <li>Key loss or replacement or lockout: Covers call-out fees, replacement of lost, damaged, or stolen car keys, key programming, and key delivery. You must follow the rental company’s guidelines.</li>
            <li>Damages caused by natural disasters: Covers wind, fire, hail, and other damages caused by natural weather events unless there’s been a breach of the rental agreement.</li>
        </ul>
        <p>You are also covered for these costly fees...</p>
        <ul>
            <li>Towing & roadside assistance costs: you are covered for any towing or roadside assistance costs following physical loss or damage to or mechanical breakdown of the rental vehicle.</li>
            <li>Administration fees: Includes Administration fees or anything similar that are charged for processing damage claims (also called handling fees, carriage fees, postal fees, accident fees). Transaction fees that you pay to your credit card company are not covered.</li>
            <li>Drop off/relocation of damaged vehicle: Includes drop off/relocation costs of your rental vehicle if there’s a breakdown.</li>
            <li>Loss of use/demurrage fees: Includes fees charged by rental companies for loss of use while the vehicle is being repaired </li>
        </ul>
        <p>You are not covered where...</p>

        <ol>
            <li>You or another driver on the rental agreement breached any term of the rental agreement.
            <li>You or another driver on the rental agreement contravened driving rules or laws in any local jurisdiction where the rental vehicle was driven.
            <li>You have given misleading or fraudulent information. We reserve the right to recover any claims that have been paid based on any misrepresentation. 
            <li>You have not provided documents that have been requested during the claims process.
            <li>You paid the rental company in cash and have insufficient evidence for that payment.
            <li>The event for which you are making a claim occurred before the product was purchased.
            <li>You incur costs resulting from misfueling your rental vehicle (i.e. using incorrect fuel) or mechanical failure caused by you driving in a manner which is reckless, or which otherwise violates the terms of your rental agreement.
            <li>You did not notify police in accordance with your Rental Agreement terms. We recommend that you understand the local requirements for notifying authorities prior to picking up your vehicle.
            <li>Your personal items are lost, stolen or damaged.
            <li>You are liable for injuries to passengers, other drivers or any other party.
            <li>You are liable for damages to the property of passengers, other drivers or any other party.
            <li>The vehicle has been driven on an unsealed road, except for access roads to your accommodation venue.
            <li>Your rental vehicle is being used for deliveries or similar commercial purposes. 
            <li>Your rental vehicle is a truck/lorry, van, bus, racing car, street car, motorcycle, scooter, moped, motorhome, RV, campervan or a 4x4 that you’ve driven on an unsealed road (except where the unsealed road is an access road to your accommodation venue).
            <li>Your rental vehicle requires a non-standard driver’s license in your region/s of travel.</li>
</ol>
        <p>Period of Cover: You are covered for the period as shown in your Certificate.</p>
</div>
<div class="w-100" id="Driver">
<h3>Driver Information </h3>
<p> You must be at least 18 years old to rent all vehicle groups on this web. If you are over 72 years old, you cannot rent on this web.</p>
<p>If you have a corporate or contracted agreement with us the requirements and charges may be different. Please check your contracted terms and conditions.</p>
<p>Additional drivers can be added using the add-on service. Please check the rate on your rental result page. </p>
<p>Any additional drivers must be present when you collect the car. All drivers must meet our conditions of rental. </p>
<p>If you have a corporate or contracted agreement with us the requirements may be different. Please check your contracted terms and conditions.</p>
</div>
<div class="w-100" id="Special">
<h3>Special Conditions</h3>
<p><strong> “Disputed” charges from the rental company. </strong></p>
<p>If the rental company’s charges are inconsistent or unfair, as deemed by you or our claims team, we will outline a process for the recovery of the charges through your credit card issuer. If you are unsuccessful in recovering these charges and can evidence this to us, we will then consider these charges under the terms of this policy. Examples include, but are not limited to, charges for interior, wear, and tear, or other damage that you are not responsible for or mechanical failure or inflated repair costs.</p>

</div>
<div class="w-100" id="Currency">
<h3>Currency calculations and fees</h3>
<p>Claims are calculated based on the currency that was originally charged by the rental company (this is the “currency of loss”). Our claims calculation does not include an international card or other fees, or foreign exchange conversions applied by your credit card issuer. Our claims process allows you to convert your approved claim amount from the original currency of loss to your preferred currency, using a retail exchange rate</p>
</div>
<div class="w-100" id="Incidents">
<h3>Incidents involving another vehicle</h3>
<p>In cases where another vehicle (a “third-party”) has been involved in an accident, and details of that vehicle and/or its driver are available, we require that information to be provided during the claim process. We may also confirm from the rental company that they have received those details. Rental companies will often charge you an amount up to the excess and reimburse you if you are deemed to be “not at fault” as a result of an investigation between the insurers of each driver. We will assist you to help ensure you’re expediently reimbursed. </p>
    <p>If we pay out a claim before the completion of any investigation, we reserve the right to recover the reimbursable funds on your behalf. We will bring action in your name to enforce these rights.</p>
</div>
<div class="w-100" id="Cancellation">
<h3>Cancellation</h3>
<p>We reserve the right to cancel your coverage. Examples include, but are not limited to, the discovery of misleading information or a sanction irregularity or at our underwriter’s request. </p>
</div>
<div class="w-100" id="General">
    <h3>General Conditions / Eligibility Requirements</h3>
    <p>All of the following conditions and/or eligibility requirements must be met for you to qualify for reimbursement:</p>
    <ol>
        <li>Your name must be on the rental agreement and it must be signed. Other drivers that are on the rental agreement with you are also covered.</li>
            <li>You must not have breached any terms of the rental agreement.</li>
                <li>Coverage is limited to one vehicle per rental, for the duration of coverage, except if your rental vehicle has been replaced by the rental company. Each time that you sign a new rental agreement you will need a new product.</li>
                    <li>You must take reasonable care to protect the rental vehicle and avoid damages.</li>
    </ol>
</div>
<div class="w-100" id="Making">
    <h3>Making</h3>
    <p>Send us an email at <a class="text-decoration-underline" href="mailto:customerservie@hekaya.co" ><strong> customerservie@hekaya.co.</strong></a> You will need photos or scans of these documents:</p>
<ol>
    <li>Your rental agreement from the rental company. </li>
    <li>Your driver’s license. </li>
    <li>Bank statement showing your payment for the damage. </li>
    <li>Final invoice from the rental company (available when the damage costs are settled - this may differ from your initial invoice). </li>
    <li>We may also request that you provide an invoice showing the repair cost for damages. </li>
    <li>Police report, if applicable. </li>
    <li>All correspondence with the rental company. </li>
    <li>Other documents as requested by your claims officer. </li>
</ol>
</div>
<div class="w-100" id="Equipment">
<h3>Special equipment</h3>
<p> Baby seats, child seats and child booster seats, and GPS units are available at a cost as mentioned in add-on services.</p>
<p>If you have a corporate or contracted agreement with us the conditions and charges may be different. Please check your contact terms and conditions.</p>

</div>
<div class="w-100" id="Collecting">
<h3>Collecting and returning your vehicle</h3>
<p>Each driver must show photographic proof of identity and their passport at the start of the rental. All drivers must present a full and current driving license. Valid Omani, American, European, UK, Australian, NZ, International, and GCC licenses are accepted.</p>
    <p>If you are delayed and arrive due to a delayed flight, no additional charge will apply. If you are late we will guarantee your rental for 4 hours. After this time, you are kindly required to inform us of the new pick-up timing (at least before 3 hours) to hold your car and arrange the delivery, or your reserved car may be released and rented to another customer.
    </p>
</div>
<div class="w-100" id="One">
    <h3>One Way Rentals</h3>
    <p>Domestic one-way rental is available at below rates over & above the rental amount: </p>
    <ul>
        <li>Muscat to Salalah or vice versa: OMR 125.</li>
            <li>Muscat to Sur or vice versa: OMR 50.</li>
                <li>Muscat to Sohar or vice versa: OMR 50.</li>
    </ul>
    <p>International: Not Available</p>
</div>
<div class="w-100" id="Fuel">
    <h3>Fuel Policy </h3>
    <p>When you pick your car up, the fuel tank will be full or partly full. Before you drop-off the vehicle, you are kindly required to ensure that the fuel level is similar to the level at the time of the pick-up or otherwise extra charge might be applied. Our team will confirm how much this will be.</p>
        <p> If the vehicle is a drop-off with a lower fuel level compared to the pick-up level, you will be charged the amount to re-full the tank to the level of pick-up.  
        </p>
</div>
<div class="w-100" id="Early">
    <h3>Early Return/ No Shows:</h3>
    <p>There will be no refund for unused car rental days on a prepaid rental, once the rental has started.</p>
        <p>There will be no refund if the client fails to show up for a rental that has not been cancelled prior to the pickup date.
        </p>
</div>
<div class="w-100" id="Late">
    <h3>Late Arrival Policy</h3>
    <p>If you make a reservation specifying a pick-up location and you do not arrive at the specified pick-up location for the rental within 4 hours of the specified pick-up time, (or if the location closes before then, by the locations closing time), the reservation will be treated as a No Show and charged accordingly.</p>
</div>
<div class="w-100" id="Refunds">
    <h3>Cancellation and refunds</h3>
    <p>The cancellation terms are outlined on your rental page result and your rental Certificate.</p>
        <p>  Refunds will be made to the credit card as per refund policy (Check Web use terms) Any booking that is cancelled on the same day of pick-up due to invalid documents, etc., will be considered as cancelled with less than 48 hours’ notice. Hence, Hekaya will charge one to two days rental of the reservation and an Administration fee of OMR: 5.000
        </p>
</div>
<div class="w-100" id="Traffic">
<h3>Traffic Violations</h3>
<p>Drivers are expected to abide by traffic rules and regulations. For every traffic fine incurred will be charged to the hirer with an additional 10% service charge.</p>
</div>
<div class="w-100" id="Taking">
    <h3>Taking your vehicle outside the country</h3>
    <p>Hekaya vehicles can be taken out of Oman into the United Arab Emirates only at a charge of OMR 20.00 per trip (valid for 7 days only) and must be added to your rental certificate. It is not allowed to take the car outside Oman without prior confirmation. </p>
</div>
<div class="w-100" id="Payment">
    <h3>Payment options</h3>
    <p>We are accepting online payment using your debit/credit visa card or master card. </p>
        <p> One to two weeks from the pick-up, the main driver will get a link to the registered email to pay the security deposit of 120 – 200 OMR depending on the type of rented vehicle. The client should make the payment before picking up. </p>
            <p>The amount will be fully refunded within 10 working days from the drop-off and in case of no fines/issues are registered. </p>
                <p> During the rental period, cash payment or payment by machine using your debit credit visa card or master card is also accepted for any extra services requested. 
        </p>
</div>
<div class="w-100" id="Complaints">
    <h3>Complaints/Disputes</h3>
    <p>You can contact our Claims Complaints Team (customerservice@hekaya.co) for a formal review of your claim or if you have other concerns. </p>
     <p>   We will respond within 5 days.
        </p>
</div>
<div class="w-100" id="Governing">
    <h3>Governing Law and Venue</h3>
    <p>Hekaya may take any action it determines is reasonably necessary to comply with applicable law, or the order or request of a court, law enforcement, or other administrative agency or governmental body.</p>
        <p>  These terms and conditions are subject to the laws of the government of the Sultanate of Oman, which may be represented in the laws of the General Authority of Civil Aviation and the General Authority for Consumer Protection and the laws of the Ministry of Commerce and Industry and the Ministry of Tourism and Heritage and others; any dispute not settled amicably between the parties is subject to the exclusive jurisdiction of the courts of the Sultanate.</p>
</div>
       

                    </div>

                </div>

                <div class="col-lg-4">
                    <div class="w-100 stikside">
                    <div class="w-100 textrightcond">


                        <h2>Want to get help with something?</h2>
                        <ng-container *ngIf="isLoggedIn; else notLoggedIn">
                          <button class="btn btn-warning w-100" routerLink="/contact">Contact us</button>
                        </ng-container>
                        <ng-template #notLoggedIn>
                          <p>Log in to help with your reservations, account, and more.</p>
                          <button class="btn btn-warning w-100" routerLink="/sign-in">Log in or sign up</button>
                        </ng-template>
                    </div>
                    
    
                    <div class="w-100 textrightcond">
    
                        <h2>Related articles:</h2>
                        <div class="w-100 mb-1"><a routerLink="/Web-Use-Terms">Web Use Terms</a></div>
                        <div class="w-100 mb-1"><a routerLink="/Privacy-Policy">Privacy Policy</a></div>
                       
                    </div>
                </div> 
                 </div>
            </div>
                

        </div>
    </div>
</section>


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
    </app-footer>