import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { GenericService } from '../generic-service/generic.service';
import { LeaseEnquiryResponse } from '../../models/car-rental/lease-enquiry/leaseEnquiry-response.model';
import { LeaseEnquiryRequest } from 'src/app/models/car-rental/lease-enquiry/leaseEnquiry-request.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaseAgreementService {

  constructor(private genericService: GenericService, private http: HttpClient) { }

  leaseMail(request:LeaseEnquiryRequest): Observable <LeaseEnquiryResponse>{
    let url= RoutingConstants.leaseEnquiry.leaseAgreementMail;
    return this.genericService.postService(request,url,true);
  }

  
}
