<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/login-bg.jpg" alt="image" loading="lazy">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <a href="#"><img src="assets\img\car-rental\invocs.png" style="width: 150px;padding: 4px  0px;" alt="innvocs-logo" loading="lazy"></a>
                            </div>

                            <h3>Welcome Back</h3>
                            <p>New to Innvocs? <a routerLink="#">Sign up</a></p>

                            <form>
                                <div class="form-group">
                                    <input type="email" placeholder="Your Email Address" class="form-control">
                                </div>

                                <div class="form-group">
                                    <input type="password" placeholder="Your Password" class="form-control">
                                </div>

                                <button type="submit">Login</button>
                                
                                <div class="forgot-password">
                                    <a routerLink="/">Forgot Password?</a>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>