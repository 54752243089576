import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  constructor() { }
  cards="../../assets/img/cards.png";
  build="../../assets/img/build.png";
  ngOnInit(): void {
  }

}
