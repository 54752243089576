export const RequestType = {
    Request: 'Request'
}

export const ServiceRequestType = {
    Service: 'Search',
    Book: 'Book',
    LeaseAgreement: 'LeaseAgreement',
    Select: 'Select'
}

export const ServiceType = {
    CarRental: 'CarRental',
    pickPark: 'PickAndPark',
    PickAndPark: 'PickAndPark',
    LeaseAgreement: 'LeaseAgreement',
    PickAndDrop: 'PickAndDrop',
    SelfDrive: 'SelfDrive',
    GearRental: 'GearRental'
}