import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class PrivacyPolicyComponent implements OnInit {

  location: any;
  routerSubscription: any;
  isLoggedIn:boolean=false;

  constructor(private _router: Router
    , private elementRef: ElementRef
    , private _authService: AuthenticationService
    , private _utilityService: UtilityService) { }

  ngOnInit(): void {

    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        if(!(appSettings.isAdmin)){
        console.log('login')
        console.log(appSettings);
        this.isLoggedIn=true;
      }
      else{
        console.log('not login')
        this.isLoggedIn=false;
      }
    }});
this.recallJsFuntions();


}

  scrollToSection(sectionId: string) {
    const element = this.elementRef.nativeElement.querySelector(`#${sectionId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}


}
