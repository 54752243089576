<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Partners</h2>
        </div>
        
        <div class="partner-slides owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img1.png" alt="image" loading="lazy"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img2.png" alt="image" loading="lazy"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img3.png" alt="image" loading="lazy"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img4.png" alt="image" loading="lazy"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img5.png" alt="image" loading="lazy"></a>
            </div>

            <div class="partner-item">
                <a href="#"><img src="assets/img/partner/img6.png" alt="image" loading="lazy"></a>
            </div>
        </div>
    </div>
</div>