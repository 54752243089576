<header class="header-area">
    <div class="navbar-area navbar-style-two">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/black-logo.png" alt="logo" loading="lazy">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/black-logo.png" alt="logo" loading="lazy">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 1</a></li>
    
                                    <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 2</a></li>
    
                                    <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 3</a></li>
    
                                    <li class="nav-item"><a routerLink="/home-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home 4</a></li>
                                </ul>
                            </li>
    
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Listing <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/listing-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listing Layout 1</a></li>
    
                                    <li class="nav-item"><a routerLink="/listing-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listing Layout 2</a></li>
    
                                    <li class="nav-item"><a routerLink="/listing-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listing Layout 3</a></li>
    
                                    <li class="nav-item"><a routerLink="/listing-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listing Layout 4</a></li>
    
                                    <li class="nav-item"><a routerLink="/listing-five" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listing Layout 5</a></li>
    
                                    <li class="nav-item"><a routerLink="/listing-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Listing Details</a></li>
    
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">User Panel <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a href="/admin-dashboard" class="nav-link">Dashboard</a></li>
            
                                            <li class="nav-item"><a href="/admin-dashboard/app-message" class="nav-link">Messages</a></li>
            
                                            <li class="nav-item"><a href="/admin-dashboard/bookings" class="nav-link">Bookings</a></li>
            
                                            <li class="nav-item"><a href="/admin-dashboard/reviews" class="nav-link">Reviews</a></li>
            
                                            <li class="nav-item"><a href="/admin-dashboard/bookmarks" class="nav-link">Bookmarks</a></li>
            
                                            <li class="nav-item"><a href="/admin-dashboard/my-listing" class="nav-link">My Listing</a></li>
            
                                            <li class="nav-item"><a href="/admin-dashboard/add-listing" class="nav-link">Add Listing</a></li>
    
                                            <li class="nav-item"><a href="/admin-dashboard/my-profile" class="nav-link">Profile</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
    
                            <li class="nav-item megamenu"><a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col">
                                                    <h6 class="submenu-title">Pages</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/about" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
    
                                                        <li><a routerLink="/team" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>
    
                                                        <li><a routerLink="/how-it-works" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">How It Works</a></li>
    
                                                        <li><a routerLink="/faqs" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
    
                                                        <li><a routerLink="/error" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>
    
                                                        <li><a routerLink="/coming-soon" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
    
                                                        <li><a routerLink="/contact" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                                                    </ul>
                                                </div>
    
                                                <div class="col">
                                                    <h6 class="submenu-title">Categories</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/categories-one" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Categories Layout 1</a></li>
    
                                                        <li><a routerLink="/categories-two" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Categories Layout 2</a></li>
                                                    </ul>
    
                                                    <h6 class="submenu-title">My Account</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/login" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>
    
                                                        <li><a routerLink="/register" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
    
                                                        <li><a routerLink="/cart">Cart</a></li>
    
                                                        <li><a routerLink="/checkout">Checkout</a></li>
                                                    </ul>
                                                </div>
    
                                                <div class="col">
                                                    <h6 class="submenu-title">Shop</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/product-list">Product List</a></li>
    
                                                        <li><a routerLink="/cart">Cart</a></li>
    
                                                        <li><a routerLink="/checkout">Checkout</a></li>
    
                                                        <li><a routerLink="/products-details">Product Details</a></li>
                                                    </ul>
    
                                                    <h6 class="submenu-title">Listing</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/listing-one">Listing Layout</a></li>
    
                                                        <li><a routerLink="/listing-details">Listing Details</a></li>
                                                    </ul>
                                                </div>
    
                                                <div class="col">
                                                    <h6 class="submenu-title">Blog</h6>
    
                                                    <ul class="megamenu-submenu">
                                                        <li><a routerLink="/blog-one">Grid (2 in Row)</a></li>
                        
                                                        <li><a routerLink="/blog-two">Grid (3 in Row)</a></li>
                        
                                                        <li><a routerLink="/blog-three">Grid (Full Width)</a></li>
                        
                                                        <li><a routerLink="/blog-four">Right Sidebar</a></li>
                        
                                                        <li><a routerLink="/blog-five">Masonry (3 in Row)</a></li>
    
                                                        <li><a routerLink="/blog-details-one">Blog Details 1</a></li>
    
                                                        <li><a routerLink="/blog-details-two">Blog Details 2</a></li>
                                                    </ul>
                                                </div>
                                            </div>
    
                                            <div class="partner-slides owl-carousel owl-theme">
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img1.png" alt="image" loading="lazy"></a>
                                                </div>
                            
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img2.png" alt="image" loading="lazy"></a>
                                                </div>
                            
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img3.png" alt="image" loading="lazy"></a>
                                                </div>
                            
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img4.png" alt="image" loading="lazy"></a>
                                                </div>
                            
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img5.png" alt="image" loading="lazy"></a>
                                                </div>
                            
                                                <div class="partner-item">
                                                    <a href="#"><img src="assets/img/partner/img6.png" alt="image" loading="lazy"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
    
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/product-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product List</a></li>
    
                                    <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
    
                                    <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
    
                                    <li class="nav-item"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details</a></li>
                                </ul>
                            </li>
    
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/blog-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid (2 in Row)</a></li>
    
                                    <li class="nav-item"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid (3 in Row)</a></li>
    
                                    <li class="nav-item"><a routerLink="/blog-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid (Full Width)</a></li>
    
                                    <li class="nav-item"><a routerLink="/blog-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar</a></li>
    
                                    <li class="nav-item"><a routerLink="/blog-five" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry (3 in Row)</a></li>
    
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Single Post <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/blog-details-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Default</a></li>
    
                                            <li class="nav-item"><a routerLink="/blog-details-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">With Video</a></li>
    
                                            <li class="nav-item"><a routerLink="/blog-details-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">With Image Slider</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
    
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <div class="search-btn-box">
                                        <i class="search-btn bx bx-search-alt"></i>
                                    </div>
                                </div>
    
                                <div class="option-item">
                                    <div class="cart-btn">
                                        <a href="#" data-toggle="modal" data-target="#shoppingCartModal"><i class='bx bx-shopping-bag'></i><span>0</span></a>
                                    </div>
                                </div>
    
                                <div class="option-item">
                                    <a href="/admin-dashboard/add-listing" class="default-btn"><i class="bx bx-plus"></i> Add Listing</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>