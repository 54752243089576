<div bsModal #searchCarsPopUp="bs-modal" class="modal fade rds" id="exampleModalsearch" tabindex="-1" role="dialog"
    aria-labelledby="searchCarsPopUp" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">

                <button type="button"  data-dismiss="modal" class="close" (click)="searchCarsPopUp.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
               <app-search-car></app-search-car>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->