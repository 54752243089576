import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import Splide from '@splidejs/splide';
import { filter } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-camping-gear',
  templateUrl: './camping-gear.component.html',
  styleUrls: ['./camping-gear.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class CampingGearComponent implements OnInit {

  location: any;
  routerSubscription: any;
  sectionId: any;

  currentYear: number = new Date(). getFullYear();

  constructor(private _router: Router, private commonService: CommonService) { }


  ngOnInit(): void {

    this.sectionId = this.commonService.getSelectedCar();
    this.recallJsFuntions();

  
    
      }

      onClick(){
        this.commonService.setSelectedCar(this.sectionId);
        this._router.navigate(['/Driving-Category']);
      }

      recallJsFuntions() {
        this.routerSubscription = this._router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.location = this._router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }


}
