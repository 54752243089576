<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>
<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Services</h2>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-4 col-sm-12 first-col-bg">
            <div class="container-fluid">
                <div class="page-content text-center">
                    <h1 class="darkBigText" style="color:#222;">Ready to Experience</h1>
                    <h1 style="color:#222;">Hekaya</h1>
                    <div class="row justify-content-center">
                        <button class="btn text-center explore-button btn-md"
                            style="margin-top: 50px;margin-bottom: 50px;" routerLink="/sign-up">Register Now</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-8 col-sm-12 container-pad">
            <div class="">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/service.jpg" alt="Seaview -Oman" class="img-fluid" style="width:100%;min-height:400px" loading="lazy">
            </div>
        </div>
    </div>
</div>



<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content text-center">
                    <h1 class="darkBigText hekayat-titl">Discover<span class="hekayat-color"> Oman</span></h1>
                    <h2 class="darkBigText" style="color:#333;font-size:21px;">Holidays and More</h2>
                    <p style="color:#555;margin-top: 30px;">We offer you an unique travel experience through our well-tailored tourism packages and 
                        tours that were designed precisely for you to live an amazing experience in Oman.</p>
                    <div class=" justify-content-start ">
                        <a href="https://hekaya.co/WebInbound/Default.aspx" target="_blank"><button class="btn explore-button btn-md"
                            style="margin-bottom: 50px;" >Find More</button></a>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/services1.jpg" alt="Hill View-Oman" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>
</div>


<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/service-img3.png" alt="A man is watching from hill top to down" class="img-box-right" loading="lazy">
            </div>
        </div>
        <div class="col-md-12 col-lg-7 col-sm-12 text-center">
            <div class="container">
                <div class="page-content2">
                    <h2 class="darkBigText" style="color:#333;">Discover the <span class="hekayat-color"> World</span></h2>
                    <h3 style="color:#333;font-size:21px;">Holidays at Many Destinations</h3>
                    <p style="color:#555;margin-top: 30px;">We offer you a unique travel experience through our well-tailored tourism packages and 
                        tours that were designed precisely for you to live an amazing experience in Oman.</p>
                    <div class="justify-content-start">
                        <a href="https://hekaya.co/WebOutbound/FixedPackageList.aspx" target="_blank"><button class="btn explore-button btn-md"
                            style="margin-bottom: 50px;" >Find More</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content3 text-center">
                    <h2 class="darkBigText" style="color:#333;">Hekayat <span class="hekayat-color">Experience</span></h2>
                    <h3 style="color:#333;font-size:21px;">Oman Self-Drive Tours</h3>
                    <p style="color:#555;">Your one-way shop-stop to rent a car and camping gears in Oman.</p>
                    <p style="color:#555;"><span style="color: #ae8f47;">Hekayat means ‘’stories’’:</span>&nbsp;The world of experience and adventure to discover the beauty of Oman by your-self and live the real adventure.</p>
                        <div class="justify-content-start">
                            <a href="https://self-drive.hekaya.co" target="_blank"><button class="btn explore-button btn-md"
                                style="margin-bottom: 50px;" >Find More</button></a>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/Services3.png" alt="A car in Desert with small tent" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>

</div>


<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/Services4.jpg" alt="Two small tents at hill top with blue sky" class="img-box-right" loading="lazy">
            </div>
        </div>
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content2 text-center">
                    <h2 class="darkBigText" style="color:#333;">Hekayat <span style="color: #ae8f47;">Gear Rental</span></h2>
                    <h3 style="color:#333;font-size:21px;">Camping and Adventure Gear Rental</h3>
                    <p style="color:#555;">Your one-way shop-Stop to rent camping gear in Oman and much more.</p>
                    <p style="color:#555;">You do not have to buy anything. With Hekaya Camping Gear Rental, you can rent all gear you need.</p>
                    <div class="justify-content-start">
                        <a href="https://self-drive.hekaya.co" target="_blank"><button class="btn explore-button btn-md"
                            style="margin-bottom: 50px;" >Find More</button></a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>


<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content3 text-center">
                    <h2 class="darkBigText" style="color:#333;">Car <span class="hekayat-color">Rental</span></h2>
                    <p style="color:#555;">A wide range of cars that can be easily pick up from different hubs or delivered to you wherever you are in Oman.Our fleet will meet your needs to have a safe and comfortable driving experience</p>
                        <div class="justify-content-start">
                           
                        </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/Services5.jpg" alt="Four wheel drive in desert-white car" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>
</div>
 
<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/about-oman/service-img6.png" alt="Four wheel drive in desert-Black car" class="img-box-right" loading="lazy">
            </div>
        </div>

        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content2 text-center">
                    <br>
                    <h2 class="darkBigText" style="color:#333;">Pick Up - <span class="hekayat-color">Drop off and Park </span></h2>
                    <p style="color:#555;">Thinking about transfer service for vehicle in Oman. Now, pick up and drop or park services for your vehicle in Oman</p>
                    <div class="justify-content-start">
                        <a href="https://car-transfer.hekaya.co" target="_blank"><button class="btn explore-button btn-md"
                            style="margin-bottom: 50px;" >Find More</button></a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-7 col-sm-12">
            <div class="container">
                <div class="page-content3 text-center">
                    <h2 class="darkBigText" style="color:#333;">Visa <span class="hekayat-color">Web</span></h2>
                    <p style="color:#555;">Want to visit Oman and concern about your visa?
                    <br>Apply for Oman Visa online with us. 
                    <br>All Online. Easy to apply. Easy to track.</p>
                        <div class="justify-content-start">
                          <a href="https://visa.hekaya.co/" target="_blank"> <button class="btn explore-button btn-md"
                                style="margin-bottom: 50px;">Find More</button> </a>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-5 col-sm-12">
            <div class="single-destinations-box">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/firstImageBig.jpg" alt="A small village under hill-Oman" class="img-box-left" loading="lazy">
            </div>
        </div>
    </div>
</div>
 



<div class="hekaya-container">
    <div class="row top-row-margin">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <header class="hero">
                <div class="center-content">
                    <h1 class="darkBigText">Sultanate Of Oman</h1>
                    <h3>Simplicity is a Treasure of Luxury</h3>

                </div>
                <div class="center-content2">
                    
                </div>
            </header>
        </div>
    </div>
</div>



<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-footer>