<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>




<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Hekayat Guidance</h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row  justify-content-center">
        <div class="col-md-12 col-lg-12 col-sm-12 pt-5 pb-5">
            <h2 class="hekaya-title text-center">Self-<span class="hekayat-color">Camping Guidance</span></h2>
            <p class="text-center">Outdoor Self-camping is free anywhere and within the incredible diversity of
                landscapes, from wadis lost in the mountains to white sand dunes flowing into the sea make it a
                perfect country for outdoor enthusiasts.</p>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row row-margin-top">
        <div class="col-md-12 col-lg-12 col-sm-12 row-full-width">
            <header class="hero">
                <div class="center-content">
                    <div class="row">
                        <div class="col-md-6">
                            <h2 class="hekaya-title">Safety<br><span class="hekayat-color">While Camping</span></h2> <br>
                            <p class="text-justify">While self-camping with Hekayat, there are many tips to ensure
                                your safety during trip. Check out the following guide to learn more about how to
                                make the most of your next campaign trip by keeping everyone safe and comfortable.
                            </p>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
</div>

<div class="container">
    <div class="row row-margin-top ">
        <div class="col-md-5 col-lg-5 col-sm-12 text-center">
            <img src="../../../../assets/img/self-drive/oman-guidence/guidance-camp/G2SafetyWhileCamping2.jpg" class="img-fluid img-3" loading="lazy"/>
        </div>
        <div class="col-md-7 col-lg-7 col-sm-12">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <br> <br>
                    <h3 class="sub-heading">Choose the Right<br><span class="hekayat-color">Camping Site</span></h3>
                    <p class="text-justify">Please use where to camp guide and avoid locations mention for where not to camp.</p> <br>
                    <h3 class="sub-heading">Stay up to Date with the<br>Weather Condition</h3>
                    <p class="text-justify">You may check Oman Official Directorate General of Meterology Web at <a style="color: #007bff;" href="http://www.met.gov.om/" target="_blank"> www.met.gov.com</a> for more
                        weather information and any issued weather alerts.</p> <br>
                    <h3 class="sub-heading">Heat Sources</h3>
                    <p class="text-justify">If welling to cook or starting a fire, ensure all source of heat are a way from your vehicle
                        and equipment's.</p>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <br> <br>
                    <h3 class="sub-heading">Risk Potentials</h3>
                    <p class="text-justify">Understand all risk potentials mention for camping at each different habitat in Oman as per
                        Hekayat Categories.</p> <br>
                    <h3 class="sub-heading">Pack and Store your Food</h3>
                    <p class="text-justify">Pack your food in tight, waterproof containers and store them in an insulated cooler box.</p>
                    <br>
                    <h3 class="sub-heading">Allergies</h3>
                    <p class="text-justify">Be Aware of Allergies and take all necessary precautions. Packing an EpiPen or other
                        medications for your known allergies is a smart way to prepare for any unexpected
                        encounters.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row row-margin-top">
        <div class="col-md-12 col-sm-12 col-lg-12 row-full-width">
            <img src="../../../../assets/img/self-drive/oman-guidence/guidance-camp/G2CentreBanner.jpg" class="img-fluid img-3 w-100" loading="lazy"/>
        </div>
    </div>
</div>

<div class="container">
    <div class="row row-margin-top">
        <div class="col-md-7 col-lg-7 col-sm-12">
            <h3 class="sub-heading">Your Equipment's <span class="hekayat-color">Security</span></h3>
            <p class="text-justify">Even if you are in safe and secure place. Take all precautions and ensure to stay close to your
                vehicle and equipment's for security reasons. If welling to go far to do any type of activities,
                well pack your equipment's, do not keep any expensive belongs visible to others and lock the
                vehicle. We recommend in such cases to try to park your vehicle in proper secure place.</p> <br>
            <h3 class="sub-heading">Watch Out for Wildlife</h3>
            <p class="text-justify">Some areas are inhabited with different species of wildlife which can be dangerous to you including
                desert snacks, scorpions, spiders etc. Hence we recommend taking precautions. You may ensure to
                sleep in your tent while closing it and keeping ventilation through upper window opens.</p> <br>
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h3 class="sub-heading">Network Coverages</h3>
                    <p class="text-justify">We recommend trying camping in places which has network coverage as possible to be able to
                        contact us or emergency.</p> <br>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <h3 class="sub-heading">Stay Hydrated</h3>
                    <p class="text-justify">Ensure you have plenty of water while camping far from the main roads and towns.</p> <br>
                </div>
            </div>
            <h3 class="sub-heading">Protection from Sun</h3>
            <p class="text-justify">Protect your skin from the Sun. Use protections, seeking shade, wearing a hat, or putting on
                sunglasses can help protect you from the UV rays.</p> <br>
        </div>
        <div class="col-md-5 col-lg-5 col-sm-12 text-right">
            <img src="../../../../assets/img/self-drive/oman-guidence/guidance-camp/G2YourEquipment'sSecurity.jpg" class="img-fluid img-3" loading="lazy"/>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-margin-top">
        <div class="col-md-12 col-lg-12 col-sm-12 row-full-width">
            <header class="hero2">
                <div class="center-content2">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="hekaya-title3">Where to <span class="hekayat-color">Camp</span></h2> <br>
                            <p class="safety-text3">Wild camping at different habitats is legal in Oman, and you may
                                basically pitch your tent anywhere as long as statying away from villages, resident
                                areas and around main grazing areas. Please check our recommendations, where not to
                                camp below. Generally, no official campsites in the region which provides any type
                                of camping facilities. Hence, while camping it will be you, your Hekayat provided
                                Equipment's and the view.<br><br>
                                As said, while avoiding where not to camp locations, you may camp anywhere. However,
                                below table listing some of the best popular spots for camping through-out the
                                Sultanate which are commonly used by locals and outside visitors.
                            </p>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
</div>


<section class="faq-area pt-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne" style="background-color: #00aeef;">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style="color: #fff;">Sandy Beach / Rocky Shores<i class='bx bx-chevron-down'></i></a>
                            </div>
                            <div id="collapseOne" class="collapse " role="tabpanel" data-parent="#accordionEx">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Locations</th>
                                                <th scope="col">Popular Examples</th>
                                                <th scope="col">Vehicle Categories Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="outer-tr">
                                                <td class="outer-td">Muscat</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>As Sifah Beach</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Yti Beach</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Bandar Al Khiran</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr >
                                                <td class="outer-td">Al Batinah South</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Al Dimanyat Islands</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Al Sawadi Beach (Barka)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Nil (Only by Boat)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr class="outer-tr">

                                                <td class="outer-td">Dhofar</td>
                                                <td class="outer-td">
                                                    <table>
                                                    <tbody >
                                                        <tr class="tb-border">
                                                            <td>Al Mughsail Beach (Salalah)</td>
                                                        </tr>
                                                        <tr class="tb-border">
                                                            <td>Fazayah Beach (Salalah)</td>
                                                        </tr>
                                                        <tr class="tb-border">
                                                            <td>Khor Rori (Taqa)</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td class="outer-td">
                                                <table>
                                                    <tbody >
                                                        <tr class="tb-border">
                                                            <td>Non 4WD</td>
                                                        </tr>
                                                        <tr class="tb-border">
                                                            <td>Non 4WD</td>
                                                        </tr>
                                                        <tr class="tb-border">
                                                            <td>Non 4WD</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Al Sharqiyah South</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Bimah Sinkhole</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Fins White Beach</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Noora Camp Plateau</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Qalhat</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Ras Al Hadd</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Masirah Island</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Ras Al Ruwais</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td >Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td >Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td >Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td >Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td >Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr class="outer-tr">
                                                <td class="outer-td">Al Wusta</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Khaluf</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Ras Markaz (Doqum)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Ras Madrakah</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Bar Al Hakman</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr >
                                                <td class="outer-td">Musandam</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Rocky Beaches</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Khasab Beaches</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo" style="background-color: #298b43;">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="color: #fff;"> Wadies (Check Precaution)<i class='bx bx-chevron-down'></i></a>
                            </div>
                            <div id="collapseTwo" class="collapse" role="tabpanel" data-parent="#accordionEx">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Locations</th>
                                                <th scope="col">Popular Examples</th>
                                                <th scope="col">Vehicle Categories Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="outer-tr">
                                                <td class="outer-td">Muscat</td>
                                                <td class="outer-td">Wadi Al Khoud</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr >
                                                <td class="outer-td">Al Batinah North</td>
                                                <td class="outer-td">Wadi Hibi (Sohar)</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr class="outer-tr">

                                                <td class="outer-td">Al Batinah South</td>
                                                <td class="outer-td">
                                                    <table>
                                                    <tbody >
                                                        <tr class="tb-border">
                                                            <td>Wadi Sahtan (Al Rustaq)</td>
                                                        </tr>
                                                        <tr class="tb-border">
                                                            <td>Wadi Al Hoqueen (Al Rustaq)</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                                <td>
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Al Dakhiliyah</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Wadi Al Muaydin (Nizwa)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Wadi Tanuf (Nizwa)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Wadi Ghul (Al Hamra)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td >4WD recommended</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td >4WD recommended</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr class="outer-tr">
                                                <td class="outer-td">Al Sharqiyah South</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Wadi Al Arabiyeen</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Wadi Tiwi (Sur)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Wadi Diqah (Quirayat)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr >
                                                <td class="outer-td">Al Sharqiyah North</td>
                                                <td class="outer-td">Wadi Endam</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr class="outer-tr">
                                                <td class="outer-td">Al Dhahirah</td>
                                                <td class="outer-td">Wadi Damm (Ibri)</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr >
                                                <td class="outer-td">Musandam</td>
                                                <td class="outer-td">Wadi Bih</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr class="outer-tr">
                                                <td class="outer-td">Dhofar</td>
                                                <td class="outer-td">Wadi Suneik</td>
                                                <td class="outer-td">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree" style="background-color: #ffc20e;">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style="color: #fff;"> Sandy Dune <i class='bx bx-chevron-down'></i></a>
                            </div>
                            <div id="collapseThree" class="collapse" role="tabpanel" data-parent="#accordionEx">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Locations</th>
                                                <th scope="col">Popular Examples</th>
                                                <th scope="col">Vehicle Categories Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="outer-tr">

                                                <td>Al Sharqiyah North</td>
                                                <td>Wahiba Sands (Bidiyah)</td>
                                                <td>4WD</td>
                                            </tr>
                                            <tr>

                                                <td>Al Batinah South</td>
                                                <td>Al Abiadh Sands (Nackal)</td>
                                                <td>4WD</td>
                                            </tr>
                                            <tr class="outer-tr">

                                                <td>Al Wusta</td>
                                                <td>Sugar Dunes (Also Beach Site)</td>
                                                <td>4WD</td>
                                            </tr>
                                            <tr>

                                                <td>Dhofar</td>
                                                <td>The Empty Quarter "Rub Al Khali Sands"</td>
                                                <td>4WD</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour" style="background-color: #b58e5f;">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" style="color: #fff;"> Mountains<i class='bx bx-chevron-down'></i></a>
                            </div>
                            <div id="collapseFour" class="collapse" role="tabpanel" data-parent="#accordionEx">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Locations</th>
                                                <th scope="col">Popular Examples</th>
                                                <th scope="col">Vehicle Categories Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="outer-tr">

                                                <td>Al Dakhiliyah</td>
                                                <td>
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Jabal Akhadar (Nizwa) </td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Jabel Shams (Al Hamra)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Sharfat Al Alamein (Al Hamra)</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD recommended</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Non 4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Al Sharqiyah South</td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Salmah Plateau</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr class="outer-tr">

                                                <td>Musandam</td>
                                                <td>
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>Khor Al Najid (Rocky Shore Also)</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Al Khalidiya Acacia</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>Jabel Al Harim</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>
                                                    <table>
                                                        <tbody >
                                                            <tr class="tb-border">
                                                                <td>4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD</td>
                                                            </tr>
                                                            <tr class="tb-border">
                                                                <td>4WD</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>





<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 row-full-width">
            <header class="hero3">
                <div class="center-content3">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="hekaya-title4">Where Not to Camp</h2>
                            <p class="sub-head">Below are some areas, where we are not recommend to camp as for
                                legal, safety, security, or social reasons</p>
                            <div class="container">
                                <div class="row box-margin">
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first">
                                            <h3 class="box-heading" style="color: #fff;">At Cities Centers and Souqs</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> All cities/
                                                towns / villages centre areas and souqs.</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> For safety and
                                                culture reason.</span></p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first-white">
                                            <h3 class="box-heading" style="color: #fff;">Private Lands/Property</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> At or nearby
                                                existing house /building or lands with walls, fines, signs of marks
                                                or tags. </span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> Social and
                                                legal reasons as apart of respect to the private property and to
                                                give those area its own privacy. </span></p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first">
                                            <h3 class="box-heading" style="color: #fff;">Sensitives Habitat</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> Ras  Al  Jinz  Turtle  Reserve .</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> Area  of  sensitive  animals. </span></p>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first-white">
                                            <h3 class="box-heading" style="color: #fff;">At Water Streaming Paths in Wadies</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> All  wadies  areas  unless  you  camp  it  higher  ground.</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> For  safety  as  water  level  can  climb fast in case of rain from the areas where  Wadies'  water  come  from.  Pay attention sometimes it is not visual for you. </span></p>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first-white">
                                            <h3 class="box-heading" style="color: #fff;">Pets Grazing Areas(Camels/Goats/<br>Cows etc.)</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> At   or   nearby   any   grazing   areas.</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> For    your    own    safety    to    avoid  any  unexpected  behaviours  from  animals   or   owners.</span></p>    
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first">
                                            <h3 class="box-heading" style="color: #fff;">Cultivated Area</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> At  or  nearby  any  cultivated  area.</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> Social  and  legal  reasons  as  apart of respect to the private property and  to  give  those  area  its  own  privacy.</span></p>    
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first-white">
                                            <h3 class="box-heading" style="color: #fff;">Close to Clift's</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> At or nearby any Clift's areas. Always keep distance.</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> For  Safety  to  avoid  falling  or  equipment's   falling.  </span></p>    
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3 col-sm-12 remove-gutter">
                                        <div class="box-first">
                                            <h3 class="box-heading" style="color: #fff;">Close to Roads/Tracks</h3>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Examples:</b> At or nearby any roads or off-road track paths.</span></p>
                                            <p class="text-justify description-text"><span style="color: #fff;"><b>Remarks:</b> For Safety to avoid run over by traffics.  </span></p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row justify-content-center">
        <p>Now, you are ready for self-camp experince in Oman but you will need to know more about <span class="hekayat-color">Accommodations</span> in Oman</p>
    </div>
    <div class="row justify-content-center">
        <button class="btn text-center explore-button btn-md" routerLink="/Hekayat-Accommodation">Read More</button>
    </div>
</div>



<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>