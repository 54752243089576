// export class LeaseEnquiry {
//     Name: string;
//     Place: string;
//     FromDate:string;
//     ToDate:string;
//     Email:string;
//     Phone:string;
// }

export class LeaseEnquiry{
    to: string;
    subject: string;
    body: string;
}
