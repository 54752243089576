
<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>



<app-home1></app-home1>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h1 class="hd_main">
                    Welcome to <br />
                    <span style="color: #a98e5f;"> HEKAYA</span> Car Rental<br />
                    <span class="">in <i> Nizwa </i></span>
                </h1>
                <p class="newstyl">
                    Welcome to Hekaya Car Rental in Nizwa, your gateway to extraordinary journeys. Experience seamless and reliable car rental services that will elevate your exploration of this beautiful country.

                </p>
            </div>
            <div class="col-lg-6 d-flex align-items-end">
                <img class="img-fluid" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car-sample.png" alt=" White car available for Car rentals in Nizwa">

            </div>
        </div>
    </div>
</section>


<app-home2></app-home2>

<section class="w-100 mt-5">
    <div class="container">
        <div class="bg_blk radius_20 p-5">
            <div class="row">

                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Trusted Partner for Car Rentals in Nizwa </h2>
                        <br>
                        <p class="text-white">Embrace the astonishing realm of motor voyages with the most trusted car rental in Nizwa. Accelerating in 2015, Hekaya touches the heart of every roadgeek by giving worry-free journeys.  </p>
                        <p class="text-white">Write a new road story by experiencing the road trip of your dreams in the city of Nizwa. Feel the spirit of wheels rolling through this dynamic city, unveiling the magic of long trips. </p>
                        <h2 *ngIf="viewMore">Book your car with Hekaya in Nizwa  </h2>
                        <br>
                        <p class="text-white" *ngIf="viewMore">Book your car with Hekaya in Nizwa and discover a new world of 
                            convenience and freedom. Hekaya is a reputable automobile rental business in Nizwa 
                            that provides several advantages to ensure a smooth travel experience.</p>
                        <p class="text-white" *ngIf="viewMore" >Enjoy the freedom to take your time seeing Nizwa and 
                            its environs, including the breathtaking views of the city and the old Nizwa Fort. 
                            You can pick a perfect car with Hekaya Car Rental Services Nizwa, guaranteeing comfort and 
                            style for the duration of your journey.  </p>
                        <p class="text-white"  *ngIf="viewMore">Embrace the ease of owning your vehicle and 
                            forget about the other inconveniences on the road. Reserve your car with Hekaya
                             today to enjoy the beauty of Nizwa on your terms. </p>
                      
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Testimonials</h2>
                        <br>
                        <h5 class="text-white">Suhair Ali </h5>
                        <p class="text-white">Our journey in Nizwa was made unforgettable by Hekaya’s Rent 
                            a Car in Nizwa. The car was in excellent condition and the whole process went smoothly. 
                            It was a pleasure to explore the ancient locations with the convenience of self driving. 
                            We are grateful for their wonderful service and heartily suggest them to other travellers. </p>
                            <h5 class="text-white" >Rahul V Menon  </h5>
                            <p class="text-white" *ngIf="viewMore" >I had a wonderful day in Nizwa thanks to Hekaya Car Rental in Nizwa.
                                It was wonderful to have the freedom to select the perfect vehicle for our trip. 
                                They offered excellent value for the money overall, and their pricing was fair. 
                                I appreciate how convenient they made my first solo journey at Nizwa and how kind their personnel were. 
                                Highly recommended. </p>
                                <h5 class="text-white" *ngIf="viewMore">Ashar Sulaiman  </h5>
                        <p class="text-white" *ngIf="viewMore">Kudos to Hekaya for their excellent services. Making a reservation with
                             them was simple, and their staff was quite supportive. Our expectations were surpassed by the vehicle,
                              and we felt secure driving throughout Nizwa. The next time we hire a luxury rental car in Nizwa, 
                              we are definitely going to use Hekaya.  </p>
                            <h5 class="text-white" *ngIf="viewMore">Shafi Rawuther</h5>
                        <p class="text-white" *ngIf="viewMore">Our Nizwa holiday was completely transformed by Nizwa Luxury Car Rentals from Hekaya. 
                            It was invaluable to be able to take our time to see the city and its surroundings. 
                            The customer service was excellent and the car was kept up well. 
                            We were able to maximise our time in Nizwa because of Hekaya. 
                            Strongly advised for everyone searching for a dependable and easy car rental experience. </p>  
                       
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="!viewMore"><b> View More</b></span></div>
                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="viewMore"><b> View Less</b></span></div>
            </div>
        </div>
    </div>
</section>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h2 class="faq_hd">Frequently Asked
                    <br /> Questions
                </h2>
                <span>Can't find what you're looking for?<br />Visit our <a 
                        class="text-decoration-underline font-weight-bold" routerLink="/contact" target="_blank">Help centre</a></span>
            </div>
            <div class="col-md-8">
                <div class="accordion faqstyle faqopen" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Should I get a luxury rental car in Nizwa?
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Yes, there are many rental agencies offering luxury rental cars in Nizwa. 
                                    If you value comfort and convenience, then a luxury vehicle can enhance your trip.
                                     Travelling in a luxurious vehicle with added features is a privilege while touring in Nizwa,
                                      battling with its subtropical, dry climate. </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">What types of cars are available to rent in Nizwa?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>
                                    For all of your requirements, a range of automobiles are available for car rental services in Nizwa.
                                     Compact automobiles make for convenient city navigation, spacious sedans make for comfortable travel, 
                                     and SUVs are ideal for off-road adventurers. Furthermore,
                                      some of the Nizwa Luxury Car Rentals provide luxury rental services, adding a level of sophistication 
                                      to your trip throughout Nizwa and the nearby locations. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">Should I rent a car in Nizwa?
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>You're able to freely explore Nizwa and its 
                                    environs at your own pace when you opt for a car rental in Nizwa.
                                     The freedom and convenience offered by a rental car are unmatchable. A private vehicle offers comfort, 
                                     allowing you to take beautiful drives to the city or explore historical locations like Nizwa Fort
                                      with super comfort. </p>
                            </div>
                        </div>
                    </div>

                  
                </div>



                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="!faqViewMore"><b> View More</b></span></div>
                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="faqViewMore"><b> View Less</b></span></div>
          
            </div>
        </div>
    </div>
</section>


<!-- <app-feedback></app-feedback> -->


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>
