<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
        || location == '/sign-in'
        || location == '/sign-up'
        ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3" >
    <div class="container">
        <div class="page-title-content">
            <h2>Web Use Terms</h2>
        </div>
    </div>
</div>

<section class="about-area pb-0">
    <div class="container">
        <div class="about-inner-area">
            <div class="row justify-content-center">
                <div class="text-center">
                    <h2><span style="color: #ae8f47;">Hekaya Website</span> User Terms and Conditions</h2>
                    <h4>The user of Hekaya website agrees to the following terms and conditions.</h4>
                </div>
            </div>
            <div class="row ">
                <div class="container border-row"></div>
            </div>
            <br>
            <br>
        </div>

        <div class="row">
            <div class="">
                <div class="about-text text-justify">
                    <h3>General terms:</h3>
                    <ul>
                        <li>When using the website, the customer declares that he/she has the legal
                            capacity to enter a commercial contract with the Hekaya website according
                            to the terms and conditions of the service/services that are obtained.
                        </li>
                        <li>The customer is prohibited from modifying, copying, distributing,
                            transferring, copying, selling or reselling any information, products or
                            services provided by this website.
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="">
                <div class="about-text text-justify">
                    <h3>Other Terms:</h3>
                    <h5>External links</h5>
                    <ul>
                        <li>Any other links contained in this website are considered managed by other
                            parties, so the Hekaya site has no authority to control its contents, privacy
                            or use, some links are only found for business, marketing and other
                            purposes, and its existence does not imply any endorsement by Hekaya
                            Company. Accordingly, the customer must take all necessary precautions
                            when entering these websites and links.
                        </li>
                    </ul>
                </div>
                <div class="about-text text-justify">
                    <h5>Products and services</h5>
                    <ul>
                        <li>The prices shown on the website are not absolutely guaranteed and may
                            vary from time to time according to the difference in prices according to
                            suppliers. Prices are confirmed after booking and final confirmation is
                            obtained from the Hekaya Holidays team.
                        </li>
                        <li>The services offered on the website do not guarantee their availability, and
                            the service is confirmed and available after the final reservation is
                            confirmed by the Hekaya team.
                        </li>
                        <li>The customer can amend or upgrade any of the products or services that he
                            purchased, when available only. The customer shall bear any costs arising
                            from the modification of the products and services, and the customer can
                            communicate with Hekaya team according to the available means of
                            communication.
                        </li>
                        <li>All Hekaya company policies related to accommodation, external and
                            internal holidays, visa facility services, travel ticket reservation facilities,
                            public transport services, and vehicle rental facilitation services are applied
                            (for these policies, please refer to the terms and conditions of tourism
                            services).
                        </li>
                        <li>Hekaya site is obligated to provide travel and tourism services according to
                            the details of the service provided and according to the services included in
                            the offer only.
                        </li>
                        <li>Hekaya does not have any control over any of the travel services and
                            products displayed in the portal. It is owned, controlled and provided by
                            travel service providers. Therefore, Hekaya website does not guarantee the
                            quality of the products or services provided by travel service providers.
                        </li>
                    </ul>
                </div>

                <div class="about-text text-justify">
                    <h5>The use of credit or debit cards</h5>
                    <ul>
                        <li>Hekaya accepts online payment using a credit or debit card; Visa or
                            MasterCard, which will be handled with the utmost confidentiality.
                        </li>
                        <li>Hekaya website provides payments in Omani Rials and US Dollars and
                            therefore payment will be calculated accordingly. In the event of a
                            difference in the transfer price between the customer’s bank and the
                            intermediary bank, the customer’s bank will then cover the amount of the
                            difference. Once the payment is made, the system automatically issues the
                            reservation receipt to the customer.
                        </li>
                        <li>The customer bears full responsibility according to the applicable law in the
                            event of committing a fraudulent act using credit or debit card.
                        </li>
                        <li>The Hekaya website does not bear responsibility for any losses or damages
                            that result from the misuse or fraudulent use of a credit card.
                        </li>
                        <li>Taxes</li>
                        <li>The customer shall bear the taxes imposed on the products and services
                            he\she purchases if they are not included in the service purchased from the
                            website.
                        </li>
                    </ul>
                </div>


                <div class="about-text text-justify">
                    <h5>Compensation and limitation of liability</h5>
                    <p>In the event that the website’s terms of use or the conditions of tourism
                        services are violated in any way through which it results in any material or
                        moral damages or operational damages and any other claims from other
                        parties, the customer is responsible for compensating the website of Hekaya.</p>
                </div>


                <div class="about-text text-justify">
                    <h5>Other additional fees</h5>
                    <p>In different situations that may cause delays or cancellations of reserved
                        services, which may include (but are not limited to) different climate
                        conditions, different natural disasters, different health conditions, political
                        conditions, and various governmental or international decisions, operational
                        or technical problems by the providers or the interruption of transportation
                        services, roads, etc., Hekaya website is not responsible for that and any
                        additional fees or material or other operational losses that may result.</p>
                </div>

                <div class="about-text text-justify">
                    <h5>Customer responsibilities</h5>
                    <p>When purchasing any of the products and services from Hekaya website,
                        customers are considered aware of all terms and conditions of use of the
                        website and the terms and conditions of the tourism services of Hekaya and
                        the terms and conditions of each service provided.
                    </p>
                    <p>Customers, when purchasing any of Hekaya’s products and services, are
                        considered aware of what is and is not covered by the service and are aware of
                        the terms of cancellation, change, and not showing up.
                    </p>
                    <p>Customers are responsible for their own safety and that of their relatives.
                        Hekaya does not take responsibility of any injuries that may arise, God forbid.
                    </p>
                </div>

                <div class="about-text text-justify">
                    <h5>Intellectual property rights</h5>
                    <p>Unless otherwise specified, all contents of the website are subject to the
                        intellectual property rights of the Hekaya vwebsite.</p>
                </div>



            </div>
        </div>
    </div>
</section>

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>