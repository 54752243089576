import { AlertService } from 'ngx-alerts';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';
import { Registration } from '../../../models/authentication/registration/registration.model';
import { ConfirmedValidator  } from '../../../models/shared/confirmed.validator';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]
})
export class SignUpComponent implements OnInit {

  registerFrom: FormGroup;
  get f() { return this.registerFrom.controls };
  isSubmitted: boolean;
  isLoginAction: boolean = false;
  appSettings: any;
  location: any;
  routerSubscription: any;

  constructor(
    private formBuilder: FormBuilder
    , private _router: Router
    , private _utilityService: UtilityService
    , private _authService: AuthenticationService
    , private alertService: AlertService
    , private meta: Meta
    ,private renderer: Renderer2
  ) { }

  

  ngOnInit(): void {
    // this.meta.addTag({ 
    //   name: 'canonical', 
    //   href: 'https://car-rental.hekaya.co/sign-up' 
    // });
    const canonicalLink = this.renderer.createElement('link');
    this.renderer.setAttribute(canonicalLink, 'rel', 'canonical');
    this.renderer.setAttribute(canonicalLink, 'href', 'https://car-rental.hekaya.co/sign-up');
    this.renderer.appendChild(document.head, canonicalLink);


    this._utilityService.getAppSettings().subscribe(appSettings => {
      if (appSettings) {
        this.appSettings = appSettings;
      
        if (!appSettings.isAdmin) {
          this._router.navigate(['/']);
        }
      }
    });
    this.initForm();
    this.recallJsFuntions();
  }
  private initForm() {
    this.registerFrom = this.formBuilder.group({
      name: ['', [Validators.required]],
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required,Validators.minLength(6),Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$")]],
      confirm_password: ['', [Validators.required]]
    },{
      // check whether our password and confirm password match
      validator: ConfirmedValidator('password', 'confirm_password')
    }
    
    );
  }

  public onSignUp() {
    this.isSubmitted = true;

    if (this.registerFrom.invalid) { return; }

    const user: Registration = {
      firstName: this.registerFrom.controls.name.value,
      lastName: '',
      userName: this.registerFrom.controls.emailAddress.value,
      agencyID: this.appSettings.agencyID,
      id: 0,
      designation: '',
      contact: '',
      emailAddress: this.registerFrom.controls.emailAddress.value,
      password: this.registerFrom.controls.password.value,
      isActive: true,
      isAdmin: false
    }
    this.isLoginAction = true;
  
    this._authService.register(user).subscribe(userId => {
      this.isLoginAction = false;
      if (userId > 0) {
        this.alertService.success('User has been successfully registered.');
        this._router.navigate(['/sign-in']);
      } else {
        this.alertService.warning('Email id already exists.')
      }
    }, error => {
      this.isLoginAction = false;
    });
  }


  recallJsFuntions() {
    this.routerSubscription = this._router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this._router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
}



}
