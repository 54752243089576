import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocationService } from 'src/app/services/location/location.service';
import { UtilityService } from 'src/app/services/utility-service/utility.service';

@Component({
  selector: 'app-search-car-popup',
  templateUrl: './search-car-popup.component.html',
  styleUrls: ['./search-car-popup.component.scss']
})
export class SearchCarPopupComponent implements OnInit {

  @ViewChild('searchCarsPopUp') public searchCarsPopUp: ModalDirective;

  constructor() {
  }

  ngOnInit(): void {
  }

  public openPopUp() {
    if (this.searchCarsPopUp) {
      this.searchCarsPopUp.show();
    }
  }
}
