<div class="search_box">
    <div class="tab-content">
        <div role="tabpanel" aria-labelledby="pills-home-tab" class="tab-pane fade show active">
            <form (submit)="onSubmitSearch()">

                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="form-group select">
                            <select class="form-control" name="park-hub" [(ngModel)]="selfParkValidForm.parkHubLocation"  placeholder="Select pick location from our main hub" >
                                <option value="">Select Park Hub Location</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group select">
                            <select name="Vehicle Type" [(ngModel)]="selfParkValidForm.vehicleType" placeholder="Select Vehicle Type"  class="form-control">
                                <option value="">Vehicle Type</option>
                                <option *ngFor="let vehicle of vehiclesTypes" value="{{vehicle.id}}">
                                    {{vehicle.typeName}} 
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" readonly="true" class="form-control" name="pick-up-date"  [(ngModel)]="selfParkValidForm.parkDate"
                            [minDate]="minDatePickUp" [maxDate]="maxDatePickUp" placeholder="Park date" #dp="bsDatepicker" autocomplete="off"
                            bsDatepicker (ngModelChange)=dateSelect($event)>
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="pick-up-time" [(ngModel)]="selfParkValidForm.parkTime" (change)="getReturnTime()">
                                <option value="">Park Time</option>
                                <option *ngFor="let time of pickUpTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" name="return-up-date" [minDate]="minDateReturn" [maxDate]="maxDateReturn" autocomplete="off"
                            [(ngModel)]="selfParkValidForm.returnDate" placeholder="Return date" #dp="bsDatepicker" bsDatepicker (ngModelChange)="getNoOfDays()">
                        </div>
                    </div>
                    <div class="col-md-6 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="return-up-time" [(ngModel)]="selfParkValidForm.returnTime">
                                <option value="">Return Time</option>
                                <option *ngFor="let time of returnTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center pb-3" *ngIf="selfParkValidForm?.returnDate">
                    <div class="col-auto text-left " >
                        <div class="form-check pb-3 pb-md-0">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Parking calculated for {{noOfDays}} days, Vehicle should be received on or before {{selfParkValidForm?.returnDate | date: 'dd/MM/yyyy'}} at {{selfParkValidForm?.returnTime}}</label>
                        </div>
                    </div>
                </div>

                <div class="text-right pr-0 mt-4">
                    <div class="form-check">
                        <button type="submit" class="btn btn-warning">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
