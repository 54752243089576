<header class="header-area">
    <div class="top-header d-none d-lg-block">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    
                </div>

                <div class="col-lg-4 col-md-12">

                </div>

                <div class="col-lg-4 col-md-12">
                    <ul class="header-top-menu">
                        <li *ngIf="!appSettings?.isAdmin"><a routerLink="/pick-up-and-drop-off/myProfile"><i
                            class='bx bxs-user'></i> My
                            Profile</a></li>
                        <li *ngIf="!appSettings?.isAdmin"><a routerLink="/pick-up-and-drop-off/My-Bookings"><i
                                    class='bx bxs-book'></i> My
                                Bookings</a></li>
                        <li *ngIf="appSettings?.isAdmin"><a routerLink="/pick-up-and-drop-off/Login"><i class='bx bx-log-in'></i> Login</a>
                        </li>
                        <li *ngIf="!appSettings?.isAdmin"><a class="pointer" (click)="onSignOut()"><i
                                    class='bx bx-log-in-circle '></i>Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- blue header -->
    <div class="navbar-area ">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu">
                    <div class="logo">
                        <a routerLink="/pick-up-and-drop-off">
                            <img src="assets/img/logo.png" alt="logo" loading="lazy">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/pick-up-and-drop-off">
                        <img src="assets/img/logo.png" alt="logo" loading="lazy">
                    </a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a href="javascript:void(0)" routerLink="/pick-up-and-drop-off" class="nav-link">Home
                                </a>

                            </li>

                            <li class="nav-item"><a href="javascript:void(0)" routerLink="/pick-up-and-drop-off/about-us" class="nav-link">About Us
                                </a>

                            </li>

                            <li class="nav-item megamenu"><a href="javascript:void(0)" routerLink="/pick-up-and-drop-off/Services" class="nav-link">Services
                                </a>

                            </li>


                            <li class="nav-item"><a routerLink="/pick-up-and-drop-off/contact-us" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a>
                            </li>
                            <li *ngIf="!appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a routerLink="/pick-up-and-drop-off/myProfile" 
                                class="nav-link">My
                                    Profile</a>
                            </li>
                            <li *ngIf="!appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a routerLink="/pick-up-and-drop-off/My-Bookings" 
                                class="nav-link"> My Bookings</a>
                            </li>
                            <li *ngIf="appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a routerLink="/pick-up-and-drop-off/Login"
                                    class="nav-link">Login</a>
                            </li>
                            <li *ngIf="!appSettings?.isAdmin" class="nav-item d-block d-lg-none"><a
                                    (click)="onSignOut()" class="nav-link">Logout</a>
                            </li>
                        </ul>

                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <div class="option-item">
                                    <div class="search-btn-box">
                                        <!--   <i class="search-btn bx bx-search-alt"></i> -->
                                    </div>
                                </div>

                                <div class="option-item">
                                    <div class="cart-btn">
                                        <!--  <a href="#" data-toggle="modal" data-target="#shoppingCartModal"><i class='bx bx-shopping-bag'></i><span>0</span></a> -->
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>

<ngx-alerts></ngx-alerts>
