<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo">
                    <img src="assets/img/black-logo.png" alt="image" loading="lazy">
                </a>

                <h2>We Are Launching Soon</h2>

                <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center"></div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center"></div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center"></div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center"></div>
                </div>

                <form class="newsletter-form">
                    <div class="form-group">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <span class="label-title"><i class='bx bx-envelope'></i></span>
                    </div>
                    
                    <button type="submit" class="default-btn">Subscribe</button>

                    <p>If you would like to be notified when your app is live, Please subscribe to our mailing list.</p>
                </form>
            </div>
        </div>
    </div>
</div>