<p>home-salalah works!</p>

<app-header *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>



<app-home1></app-home1>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h1 class="hd_main">
                    Welcome to <br />
                    <span style="color: #a98e5f;">HEKAYA</span> Car Rental<br />
                    <span class="">in <i>Jebel Akhdar</i></span>
                </h1>
                <p class="newstyl">
                    Welcome to Hekaya Car Rental in Jebel Akhdar, your gateway to extraordinary journeys. Experience seamless and reliable car rental services that will elevate your exploration of this beautiful country.

                </p>
            </div>
            <div class="col-lg-6 d-flex align-items-end">
                <img class="img-fluid" src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/car-sample.png" alt=" White car available for Car rentals in Jebel Akhdar">

            </div>
        </div>
    </div>
</section>


<app-home2></app-home2>

<section class="w-100 mt-5">
    <div class="container">
        <div class="bg_blk radius_20 p-5">
            <div class="row">

                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
                        <h2>Luxury Car car rental in Jebel Akhdar</h2>
                        <br>
                        <p class="text-white">Get your gear. Wake up from neutral mode to explore the scenic beauty of Jebel Akhdar. Give a 
                            new life to your motor dreams with Hekaya Car Rental in Jebel Akhdar. </p>
                        <p class="text-white">A vast spectrum of Jebel Akhdar luxury car rentals at Hekaya awaits you for the most 
                            memorable trip on the way. Feel the comfort of conquering roads with our luxurious cars to kick-start your trip mode in this royal country. </p>
                        <!-- <p class="text-white"  *ngIf="viewMore">Embrace the ease of owning your vehicle and 
                            forget about the other inconveniences on the road. Reserve your car with Hekaya
                             today to enjoy the beauty of Nizwa on your terms. </p> -->
                             <h2 *ngIf="viewMore">Explore Jebel Akhdar at Your Own Pace with us</h2>
                            <br>
                            <p class="text-white" *ngIf="viewMore">Discover the splendours of Jebel Akhdar at your own pace with Hekaya, the top choice for vehicle 
                                rentals in this enchanting area. Our array of quality automobiles gives you the flexibility to 
                                customise your journey, allowing you to go at your pace and find Jebel Akhdar's undiscovered 
                                gems. </p>
                                <p class="text-white" *ngIf="viewMore">Hekaya, the car rental company in Jebel Akhdar, has a variety of alternatives to fit your needs. 
                                    Get rid of strict timetables and enjoy the unpredictability of the trip, making stops at beautiful towns 
                                    or sweeping vistas whenever the desire strikes. 
                                    </p>
                                <p class="text-white" *ngIf="viewMore" >Hekaya makes it easy to navigate the lovely roads, making the trip stress-free and joyful. Enjoy 
                                    the freedom and simplicity of vehicle rentals as Hekaya becomes your reliable guide to seeing 
                                    Jebel's unmatched beauty.  </p>
                      
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="w-100 scrollonviewmore">
        
                            <h2>Testimonials</h2>
                            <br>
                            <h5 class="text-white">Wazim Shah </h5>
                        <p class="text-white">My vacation to Jebel Akhdar was made into an amazing journey by Hekaya Car Rental in Jebel 
                            Akhdar. The effortless process of renting a car gave me the freedom to be spontaneous and 
                            discover hidden treasures. On narrow roads, the car gave confidence and safety. I'm grateful that 
                            Hekaya added a little bit of magic to my trip. </p>  
                            <h5 class="text-white" *ngIf="viewMore">Samir A</h5>
                        <p class="text-white" *ngIf="viewMore">Hekaya, Car rental company in Jebel Akhdar, made our family's road journey to Jebel Akhdar 
                            effortless. Our group was well-suited for the spacious vehicle we hired. Hekaya's assistance made 
                            travelling with children stress-free, enabling us to take in the stunning surroundings. Hekaya is 
                            our preferred automobile rental in Jebel Akhdar because of its excellent customer service and 
                            fleet. </p> 
                            <h5 class="text-white" *ngIf="viewMore">Martina Francis </h5>
                        <p class="text-white" *ngIf="viewMore">Hekaya, the Car rental services jebel Akhdar, was my reliable travelling partner in Jebel Akhdar. 
                            The rental process was simple and quick, and the compact vehicle was easy to handle. My solo 
                            trip was made even more memorable by the flexibility to design my own schedule. Hekaya, you 
                            have introduced me to the joys of travelling alone. Highly recommended for all the solo travellers 
                            out there. </p> 
                            <h5 class="text-white" *ngIf="viewMore">Aryan Mehta</h5>
                        <p class="text-white" *ngIf="viewMore">Hekaya’s Jebel Akhdar Luxury Car rentals gave me excellent assistance on my trip to Jebel 
                            Akhdar for business. Both the car's quality and the booking process's quickness were beyond my 
                            expectations. The car's GPS navigation changed everything by enabling me to get to my meetings 
                            on time. Highly recommended.  </p> 
                       
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="!viewMore"><b> View More</b></span></div>
                <div class="col-12 d-flex justify-content-end mt-3"><span (click)="viewMore = !viewMore"
                        *ngIf="viewMore"><b> View Less</b></span></div>
            </div>
        </div>
    </div>
</section>


<section class="w-100 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h2 class="faq_hd">Frequently Asked
                    <br /> Questions
                </h2>
                <span>Can't find what you're looking for?<br />Visit our <a 
                        class="text-decoration-underline font-weight-bold" routerLink="/contact" target="_blank">Help centre</a></span>
            </div>
            <div class="col-md-8">
                <div class="accordion faqstyle faqopen" id="accordionExample">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">  Do you provide delivery and pickup services in Jebel Akhdar?  
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Yes, delivery and pickup services have been offered in Jebel Akhdar. You can request for the Car 
                                    rental in jebel Akhdar to be delivered to a convenient and flexible location. A flawless experience 
                                    is guaranteed since the automobile will be picked up at the address you provide at the end of your 
                                    rental time.                                     
                                     </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo"> How can I book a car rental in Jebel Akhdar? 
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>Booking a Rent a car in Jebel Akhdar is simple. You can do it online through rental websites or 
                                    by calling the rental service directly. Provide your travel details, choose a car type, and confirm 
                                    your reservation. Payments are usually made online or upon vehicle pickup, depending on the 
                                    rental company's policies. 
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button class="btn btn-link btn-block text-left collapsed" type="button"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">What are the conditions of renting Luxury car in jebel Akhdar? 
                                </button>
                            </h2>
                        </div>
                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                            data-parent="#accordionExample">
                            <div class="card-body">
                                <p>When renting a luxury vehicle in Jebel Akhdar, you usually need to have a current driver's licence, 
                                    a credit card, and be at least 25 years old. Additionally, rental firms could need verification of 
                                    insurance. It's best to inquire about the precise criteria from the Jebel Akhdar Luxury Car rental 
                                    agency as specific restrictions may differ.
                                    </p>
                            </div>
                        </div>
                    </div>

                  
                </div>



                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="!faqViewMore"><b> View More</b></span></div>
                <div class="w-100 d-flex justify-content-end mt-3"><span class="viewbtn" (click)="faqViewMore = !faqViewMore"
                    *ngIf="faqViewMore"><b> View Less</b></span></div>
          
            </div>
        </div>
    </div>
</section>


<!-- <app-feedback></app-feedback> -->


<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
     || location == '/sign-up'
     ||location == '/forgot-password')">
</app-footer>
