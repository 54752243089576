import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { Prediction } from 'src/app/models/location/googl-location.model';
import { vehicleType } from 'src/app/models/vehicle-type/vehicleType.model';
import { LocationService } from 'src/app/services/location/location.service';
import { PickAndDropService } from '../../../services/pick-and-drop.service';
import {ResultService} from '../../../../../../services/pick-park-service/pick-park-result/result.service';

@Component({
  selector: 'app-self-park-at-hub-search-form',
  templateUrl: './self-park-at-hub-search-form.component.html',
  styleUrls: ['./self-park-at-hub-search-form.component.scss']
})
export class SelfParkAtHubSearchFormComponent implements OnInit {

  public minDatePickUp: Date = new Date();
  public maxDatePickUp: Date;
  public minDateReturn: Date;
  public maxDateReturn: Date;
  public selfParkValidForm: any;
  public pickUpCities: Prediction[] = [];
  public dropOffCities: Prediction[] = [];
  public pickUpTime = [];
  public returnTime = [];
  public noOfDays: any;

  @Input() public times: Array<string>;
  @Input() public vehiclesTypes: Array<vehicleType>;
  @Input() public parkOnlylocation: any;


  //#endregion

  constructor(
    private alertService: AlertService
    , private _route: Router
    , private formBuilder: FormBuilder
    , private datepipe: DatePipe
    , private _locationService: LocationService
    , private _service: ResultService
  ) { 
    this.minDatePickUp = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
    this.minDateReturn = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000));
  }

  ngOnInit(): void {
    this.initFormElements();
  }

  checkTime(i) {
    return (i < 10) ? "0" + i : i;
  }

  startTime() {
    let currentDate = new Date((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000)).getDate();
    if( new Date(this.selfParkValidForm.parkDate).getDate() === currentDate){
      var today = new Date(),
        h = this.checkTime(today.getHours()),
        m = this.checkTime(today.getMinutes())
        this.generateTime(h,m);
    } else {
      this.pickUpTime = this.times;
    }
  }

  generateTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.pickUpTime.push('0' + i + ':00');
        this.pickUpTime.push('0' + i + ':30');
      } else {
        this.pickUpTime.push(i + ':00');
        this.pickUpTime.push(i + ':30');
      }
    }
  }

  getReturnTime(){
    this.returnTime = [];
    if( new Date(this.selfParkValidForm.returnDate).getDate() === new Date(this.selfParkValidForm.parkDate).getDate()){
      this.returnTime = this.pickUpTime.filter(x => x > this.selfParkValidForm.parkTime);
        // let pickUpTime = this.selfParkValidForm.parkTime;
        // pickUpTime = pickUpTime.split(':');
        // let h = this.checkTime(parseInt(pickUpTime[0]) + 2)
        // let m = this.checkTime(parseInt(pickUpTime[1]))
        // this.generateReturnTime(h,m)
    } else {
        this.returnTime = this.times;
    }
    
  }

  generateReturnTime(h,m){
    for (let i = h; i < 24; i++) {
      if (i < 10) {
        this.returnTime.push('0' + i + ':00');
        this.returnTime.push('0' + i + ':30');
      } else {
        this.returnTime.push(i + ':00');
        this.returnTime.push(i + ':30');
      }
    }
  }

  getNoOfDays(){
    this.getReturnTime();
    let pickUpDate: Date;
    let returnDate: Date;
    pickUpDate = new Date(this.selfParkValidForm.parkDate);
    returnDate = new Date(this.selfParkValidForm.returnDate);
    this.noOfDays = (Math.ceil(Math.abs(returnDate.getTime() - pickUpDate.getTime()) / (1000 * 3600 * 25))) + 1;
  }

  public dateSelect(event: Date) {
    this.minDateReturn = event;
    this.startTime();
  }

  public onEnterLocation(event, type: number) {
    this.pickUpCities = [];
    this.dropOffCities = [];
    if (event?.target?.value) {
      const value = event.target.value;
      if (value?.length > 2) {
        this._locationService.googleLocation(value).subscribe(result => {
          if (result?.data?.predictions?.length > 0) {
            if (type === 1) {
              this.pickUpCities = result.data.predictions;
            } else if (type === 2) {
              this.dropOffCities = result.data.predictions;
            }
          }
        });
      }
    }

  }

  public onSelectItem(event: any, type: number) {
    if (event) {
      if (type === 1) {
        this.selfParkValidForm.pickUp = event;
      } else if (type === 2) {
        this.selfParkValidForm.dropOff = event;
      }
    }

  }


  private validateForm() {
    if (!this.selfParkValidForm.parkHubLocation) {
      this.alertService.danger('Please select park hub location.');
      return false;
    }

    if (!this.selfParkValidForm.vehicleType) {
      this.alertService.danger('Please select vehicle type.');
      return false;
    }

    if (!this.selfParkValidForm.parkDate) {
      this.alertService.danger('Please select park date.');
      return false;
    }

    if (!this.selfParkValidForm.parkTime) {
      this.alertService.danger('Please select park time.');
      return false;
    }

    if (!this.selfParkValidForm.returnDate) {
      this.alertService.danger('Please select return date.');
      return false;
    }

    if (!this.selfParkValidForm.returnTime) {
      this.alertService.danger('Please select return time.');
      return false;
    }

    return true;
  }

  public onSubmitSearch() {
    if (this.validateForm()) {
      const vehicleType = this.vehiclesTypes.find(x => x.id === Number(this.selfParkValidForm.vehicleType));

      const search = {
        formType: 2,
        pickUp: {
          placeId: this.parkOnlylocation.find(x => x.id === parseInt(this.selfParkValidForm.parkHubLocation)).placeID,
          locationId: parseInt(this.selfParkValidForm.parkHubLocation),
          name: this.parkOnlylocation.find(x => x.id === parseInt(this.selfParkValidForm.parkHubLocation)).locationName,
          date: this.selfParkValidForm.parkDate,
          time: this.selfParkValidForm.parkTime,
        },
        dropOff: {
          placeId: this.parkOnlylocation.find(x => x.id === parseInt(this.selfParkValidForm.parkHubLocation)).placeID,
          locationId: parseInt(this.selfParkValidForm.parkHubLocation),
          name: this.parkOnlylocation.find(x => x.id === parseInt(this.selfParkValidForm.parkHubLocation)).locationName,
            date: this.selfParkValidForm.parkDate,
            time: this.selfParkValidForm.parkTime,
        },
        return: {
          date: this.selfParkValidForm.returnDate,
          time: this.selfParkValidForm.returnTime,
        },
        vehicleType,
        isSelfPark: true
      };

      this._service.searchData = search;
      this._route.navigate(['/pick-and-park/search-results']);
    }
  }

  private initFormElements(): void {
    this.selfParkValidForm = {
      parkHubLocation: '',
      vehicleType: '',
      parkDate: '',
      parkTime: '',
      returnDate: '',
      returnTime: ''
    };
  }


}
