import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCarPopupComponent } from './components/search-car-popup/search-car-popup.component';
import { SearchCarComponent } from './components/search-car/search-car.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LeaseEnquiryComponent } from './components/lease-enquiry/lease-enquiry.component';
import { NeedCarDeliveryComponent } from './components/need-car-delivery/need-car-delivery.component';
import { NgxAutocompleteModule } from 'ngx-angular-autocomplete';



@NgModule({
  declarations: [SearchCarPopupComponent, SearchCarComponent, LeaseEnquiryComponent
    , NeedCarDeliveryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxAutocompleteModule
  ],
  exports: [SearchCarPopupComponent]
})
export class SharedModule { }
