<app-self-drive-nav *ngIf="!(location == '/error' || location == '/coming-soon'
    || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-nav>

<div class="page-title-area page-title-bg3">
    <div class="hekaya-container">
        <div class="page-title-content">
            <h2>What is </h2>
            <h4>hekayat</h4>
        </div>
    </div>
</div>

<div class="hekaya-container">
    <section class="listing-area extra-pt pb-100">
        <div class="container">
            <div class="section-title">
                <h2>About Hekayat</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="single-team-member  round-radius boxshadow clearfix">
                   
                    <div class="left col-lg-6 col-md-6 px-0">
                        <img src="assets/img/team/img55.jpg" class="shadow about-img"
                            style="width: 100% !important; border-top-left-radius: 2rem;  border-bottom-left-radius: 2rem;"
                            alt="image" loading="lazy">
                    </div>
                    <div class="right col-lg-6 col-md-6 px-2">
                        <p>Hekayat which is an Arabic word means ‘’stories’’ is ouwhich is an Arabic word means
                            ‘’stories’’ is our unique product designed for those who likes to discover Oman by
                            themselves, by self-driving and self-camping or a combination with other staying
                            stylishHekayat which is an Arabic word means ‘’stories’’ is our unique product designed for
                            those who likes to discover Oman by themselves, by self-driving and self-camping or a
                            combination with other staying stylish</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="hekaya-container">
    <section class="hekaya-banner-Hekayat ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="hekaya-content">
                <h2>Enjoy the true <br> <span> Hekayat.</span></h2>
            </div>
        </div>
    </section>
</div>

<div class="container">
    <section class="listing-area extra-pt pb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Destinations</span>
                <h2>Visit Oman </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="assets/img/destinations/125.jpg" alt="image" loading="lazy">
                    <a routerLink="/listing-one" class="link-btn" ></a>
                </div>
            </div>
            <div class="col-lg-8 col-sm-12 col-md-12">
                <div class="single-destinations-box">
                    <img src="assets/img/destinations/50.jpg" alt="image" loading="lazy">
                    <a routerLink="/listing-one" class="link-btn"></a>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="assets/img/destinations/15.jpg" alt="image" loading="lazy">
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="single-destinations-box">
                            <img src="assets/img/destinations/78.jpg" alt="image" loading="lazy">
                            <a routerLink="/listing-one" class="link-btn"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<app-self-drive-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-self-drive-footer>
