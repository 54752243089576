<!-- <app-header-one></app-header-one> -->
<div class="menu_bar">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Fleets</h2>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row  row_top row_bottom">
        <div class="col-md-3 col-sm-12 col-lg-3">
            <div class="sidebox">
                <div class="side_header">booking details </div>
                <div class="side_container">
                    <div class="side_container_inner">
                        <h6>PICKUP</h6>
                        <i class='bx bxs-calendar side_icon'></i>
                    </div>
                    <div class="side_container_inner2">
                        <h6>Muscat International Airport,Muscat <br>
                            September 12, 2020 10:00</h6>

                    </div>
                </div>
                <div class="side_container">
                    <div class="side_container_inner">
                        <h6>RETURN</h6>
                        <i class='bx bxs-calendar side_icon'></i>
                    </div>
                    <div class="side_container_inner2">
                        <h6>Muscat International Airport,Muscat <br>
                            September 12, 2020 10:00</h6>

                    </div>
                </div>
                <div class="rental_length">RENTAL LENGTH</div>
                <div>3 DAYS</div>
                <div class="align_center">
                    <button class=" button_margin btn btn-warning ">MODIFY SEARCH</button>
                </div>
            </div>
        </div>
        <div class="col-md-9 col-sm-12 col-lg-9">

            <div class="form-check form-check-inline">
                <h5>Are you already a Customer ? </h5>
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                    value="option1">
                <label class="form-check-label" for="inlineRadio1">Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                    value="option2">
                <label class="form-check-label" for="inlineRadio2">No</label>
            </div>
            <h5>Personal Information </h5>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-lg-6">
                    <div class="form-group">
                        <label for="exampleInputEmail1">FIRST NAME *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Frist Name">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">EMAIL ID *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Email Id">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">NATIONALITY *</label>
                        <select class="form-control">
                            <option>Nationality</option>
                            <option>Nationality</option>
                            <option>Nationality</option>
                        </select>
                    </div>

                </div>
                <div class="col-md-6 col-sm-12 col-lg-6">
                    <div class="form-group">
                        <label for="exampleInputEmail1">FIRST NAME *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Frist Name">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">EMAIL ID *</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Email Id">
                    </div>
                </div>
            </div>
            <h6>Upload documents (This will help to speed up the booking process</h6>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2"
                    value="option2">
                <label class="form-check-label text-danger" for="inlineRadio2">I accept the Terms & Conditions *</label>
            </div>
        
            <table class="table">

                <thead>
                    <tr>
                        <th scope="col">ITEM</th>
                        <th scope="col">AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Rental charge </th>
                        <td>OMR 26.39 </td>
                    </tr>
                    <tr>
                        <th scope="row">Personal Accident Insurance</th>
                        <td>OMR 3.00 </td>
                    </tr>
                    <tr>
                        <th scope="row">Airport Parking</th>
                        <td>OMR 5.00 </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            <h3 class="text-danger">Grand Total</h3>
                        </th>
                        <td>
                            <h3 class="text-danger">OMR 34.39 </h3>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button class=" button_margin btn btn-warning ">Continue Booking</button>
            <!-- Button trigger modal -->
            <button type="button" class="button_margin btn btn-warning" data-toggle="modal" data-target="#exampleModalLong">
              Login & Continue
            </button>

            <!-- Modal -->
            <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                         
                        <div class="modal-body">
                          <div class="login-content">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="login-form">
                                        <div class="logo">
                                            <a routerLink="/"><img src="assets/img/logodark.png" alt="image" loading="lazy"></a>
                                        </div>
            
                                        <h3>Welcome Back</h3>
                                        <p>New to Louise? <a routerLink="/register">Sign up</a></p>
            
                                        <form>
                                            <div class="form-group">
                                                <input type="email" placeholder="Your Email Address" class="form-control">
                                            </div>
            
                                            <div class="form-group">
                                                <input type="password" placeholder="Your Password" class="form-control">
                                            </div>
            
                                            <button type="submit">Login</button>
                                            
                                            <div class="forgot-password">
                                                <a routerLink="/">Forgot Password?</a>
                                            </div>
            
                                          
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
