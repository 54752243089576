<ngx-alerts></ngx-alerts>

<section class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">

            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/car-rental/invocs.png" alt="image" loading="lazy"></a>
                            </div>

                            <h3>Create Your Account Now</h3>
                            <p>Already register? <a routerLink="/sign-in">Login</a></p>

                            <form [formGroup]="registerFrom" (submit)="onSignUp()">
                                <div class="form-group">
                                    <input type="text" placeholder="Enter Your Name" class="form-control"
                                        formControlName="name">
                                </div>

                                <div class="form-group">
                                    <input type="email" placeholder="Enter Your Email" class="form-control"
                                        formControlName="emailAddress">
                                </div>

                                <div class="form-group">
                                    <input placeholder="Password"
                                        formControlName="password"
                                        id="password" 
                                        type="password" 
                                        class="form-control">
                                    <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                                        <div *ngIf="f.password.errors.required">Password is required.</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be atleast 6 characters</div>
                                        <div *ngIf="f.password.errors.pattern">Password must contain Lowercase letters,Uppercase letters,Numbers,Special characters.</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    
                                    <input placeholder="Confirm Password"
                                        formControlName="confirm_password"
                                        id="confirm_password" 
                                        type="password" 
                                        class="form-control">
                                    <div *ngIf="f.confirm_password.touched && f.confirm_password.invalid" class="alert alert-danger">
                                        <div *ngIf="f.confirm_password.errors.required">Password is required.</div>
                                        <div *ngIf="f.confirm_password.errors.confirmedValidator">Password and Confirm Password must be match.</div>
                                    </div>
                                </div>

                                <button type="submit" [disabled]="isLoginAction">Signup</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>