import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../generic-service/generic.service';
import { LoginResponse } from '../../models/authentication/Login/login-response.model';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { shareReplay } from 'rxjs/operators';
import { Registration } from 'src/app/models/authentication/registration/registration.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private genericService: GenericService
  ) { }

  signIn(agencyCode: string, emailAddress: string, password: string): Observable<LoginResponse> {
    let serviceUrl = RoutingConstants.authentication.signIn +
      '?emailAddress=' + emailAddress +
      '&password=' + password +
      '&agencyCode=' + agencyCode;
    return this.genericService.getService(serviceUrl, false);
  }

  register(registration: Registration): Observable<number> {
    let serviceUrl = RoutingConstants.authentication.register;
    return this.genericService.postService(registration, serviceUrl, true);
  }

  refresh(): Observable<LoginResponse> {
    let serviceUrl = RoutingConstants.authentication.refresh;
    return this.genericService.getService(serviceUrl, true);
  }

  forgotPassword(emailAddress: string, agencyCode: string): Observable<boolean> {
    let serviceUrl = RoutingConstants.authentication.forgotPassword + 
    '?EmailID=' + emailAddress +
    '&agencyCode=' + agencyCode;
    return this.genericService.getService(serviceUrl, true);
  }

}
