

<div class="hdgap"></div>

<section class="w-100">
    <div class="container">



       
        <div id="carouselExampleControls" class="carousel slide mnslider" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active d-flex align-items-stretch">

                    <div class="slider_txt">
                        <h2>Introducing self drive for better experience</h2>
                        <span>Rent a car along with camping equipment</span>
                        <a href="#" target="_blank">Take a tour</a>
                    </div>


                   <img src="assets/img/02.png" class="d-block w-100" alt="White Cars Available for Car Rentals in Muscat">
                

                 <!-- <div class="w-100 bgsetup">
                    <img src="../../../../assets/img/mob.jpg" class="dmob" loading="lazy"/>
                    <div class="slider_txt">
                        <h2>Introducing self drive for better experience</h2>
                        <span>Rent a car along with camping equipment</span>
                        <a href="#" target="_blank">Take a tour</a>
                    </div>
                
                </div> -->
                 
                 <!-- <div  class="w-50 videosetup"> -->
                    <!-- <video width="100%" height="auto"  [muted]="'muted'" autoplay loop style="display: block;">
                        <source src="../../../../assets/video/slide.mp4" type="video/mp4" loading="lazy">
                        Your browser does not support the video tag.
                      </video> -->
                      
                      <!-- <img *ngIf="!showVideo" src="../../../../assets/video/img-1.png" width="100%" height="auto" style="display: block;"/>
                      <video *ngIf="showVideo" #myVideo width="100%" height="auto" [muted]="'muted'" autoplay loop style="display: block;">
                        <source src="../../../../assets/video/slide.mp4" type="video/mp4" loading="lazy">
                        Your browser does not support the video tag.
                      </video>
                      
                 </div> -->
                
                </div>
               
            </div>
           
        </div>



    </div>

    

</section>
<!-- <div class="main-categories-box">
    <ul>
       

        <li>
            
            <a href="https://car-rental.hekaya.co/pick-up-and-drop-off" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon2.png" alt="Car Icon- Pick&Drop" >
                </span> Pick & Drop

            </a>
        </li>

        <li>
            
            <a href="https://car-rental.hekaya.co/self-drive" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon4.png" alt="Car Icon- Self Drive">
                </span> Self Drive
            </a>
        </li>

        <li>
            <a href="https://hekaya.co/default.aspx" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon3.png" alt="Car Icon- Packages">
                </span> Packages
            </a>
        </li>
        <li>
            <a href="https://visa.hekaya.co/" target="_blank">
                <span class="icon">
                    <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/icon5.png" alt="Visa Icon">
                </span> Visa
            </a>
        </li>


    </ul>
</div> -->





