import { type } from 'jquery';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { timeStamp } from 'console';
import { AlertService } from 'ngx-alerts';
import { filter } from 'rxjs/operators';
import { getDiffieHellman } from 'crypto';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
    selector: 'app-map-detail-page',
    templateUrl: './map-detail-page.component.html',
    styleUrls: ['./map-detail-page.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class MapDetailPageComponent implements OnInit {

    location: any;
    routerSubscription: any;
    vehicleTypes: Array<any> = new Array<any>();
    response: any;
    filteredMap: Array<any>;
    drivingType: Array<any> = new Array<any>();
    habitatType: Array<any> = new Array<any>();
    currentYear: number = new Date().getFullYear();
    driveTypeName: string[] = [];
    HabitatTypeName: string[] = [];

    drivingOption = [
        {
            "id": 1,
            "code": "S",
            "name": "Smooth",

        },
        {
            "id": 2,
            "code": "LA",
            "name": "Light Adventure Driving",

        },
        {
            "id": 3,
            "code": "MA",
            "name": "Medium Adventure Driving",

        },
        {
            "id": 4,
            "code": "AA",
            "name": "Advanced Adventure Driving",

        }
    ];

    vehicleOption = [
        {
            "id": 1,
            "name": "4WD"
        },
        {
            "id": 2,
            "name": "NON 4WD"
        }
    ];

    habitatOption = [
        {
            "id": 1,
            "code": "B",
            "name": "Beaches",

        },
        {
            "id": 2,
            "code": "M",
            "name": "Wetland And Marshes",

        },
        {
            "id": 3,
            "code": "MH",
            "name": "Mountain Asphalted Hills",

        },
        {
            "id": 4,
            "code": "MO",
            "name": "Mountain Off-roading",

        },
        {
            "id": 5,
            "code": "W",
            "name": "Wadies",

        },
        {
            "id": 6,
            "code": "SD",
            "name": "Sandy Dunes",

        }
    ]

    

    mapDetails = [
        {
            "id": 1,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            }],
            "noOFdays": "1-2 days",
            "vehicleOption": 2,
            "habitatOption": [],
            "image": '../../../../assets/img/self-drive/hekayat-route/1.jpg',
        },
        {
            "id": 2,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            }],
            "noOFdays": "2-3 days",
            "vehicleOption": 2,
            "habitatOption": [],
            "image": '../../../../assets/img/self-drive/hekayat-route/2.jpg',
        },
        {
            "id": 3,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            }],
            "noOFdays": "1-2 days",
            "vehicleOption": 2,
            "habitatOption": [],
            "image": '../../../../assets/img/self-drive/hekayat-route/3.jpg',
        },
        {
            "id": 4,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            }],
            "noOFdays": "2-3 days",
            "vehicleOption": 2,
            "habitatOption": [],
            "image": '../../../../assets/img/self-drive/hekayat-route/4.jpg',
        },
        {
            "id": 5,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            }],
            "noOFdays": "2-3 days",
            "vehicleOption": 2,
            "habitatOption": [],
            "image": '../../../../assets/img/self-drive/hekayat-route/5.jpg',
        },
        {
            "id": 6,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            }],
            "noOFdays": "3-4 days",
            "vehicleOption": 2,
            "habitatOption": [],
            "image": '../../../../assets/img/self-drive/hekayat-route/6.jpg',
        },
        {
            "id": 7,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            }],
            "noOFdays": "2-3 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/7.jpg',
        },
        {
            "id": 8,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            }],
            "noOFdays": "3-4 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/8.jpg',
        },
        {
            "id": 9,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 2,
                "code": "LA",
                "name": "Light Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "3-4 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/9.jpg',
        },
        {
            "id": 10,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 2,
                "code": "LA",
                "name": "Light Adventure Driving",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "4-5 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/10.jpg',
        },
        {
            "id": 11,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 2,
                "code": "LA",
                "name": "Light Adventure Driving",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "6-7 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 3,
                "code": "MH",
                "name": "Mountain Asphalted Hills",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/11.jpg',
        },
        {
            "id": 12,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "4-5 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/12.jpg',
        },
        {
            "id": 13,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 2,
                "code": "LA",
                "name": "Light Adventure Driving",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "6-7 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 3,
                "code": "MH",
                "name": "Mountain Asphalted Hills",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/13.jpg',
        },
        {
            "id": 14,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 2,
                "code": "LA",
                "name": "Light Adventure Driving",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "8-9 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 3,
                "code": "MH",
                "name": "Mountain Asphalted Hills",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 5,
                "code": "W",
                "name": "Wadies",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/14.jpg',
        },
        {
            "id": 15,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 2,
                "code": "LA",
                "name": "Light Adventure Driving",
    
            },
            {
                "id": 3,
                "code": "MA",
                "name": "Medium Adventure Driving",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "9-10 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 3,
                "code": "MH",
                "name": "Mountain Asphalted Hills",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/15.jpg',
        },
        {
            "id": 16,
            "drivingOption": [{
                "id": 1,
                "code": "S",
                "name": "Smooth",
    
            },
            {
                "id": 4,
                "code": "AA",
                "name": "Advanced Adventure Driving",
    
            }],
            "noOFdays": "9-12 days",
            "vehicleOption": 1,
            "habitatOption": [{
                "id": 1,
                "code": "B",
                "name": "Beaches",
    
            },
            {
                "id": 4,
                "code": "MO",
                "name": "Mountain Off-roading",
    
            },
            {
                "id": 6,
                "code": "SD",
                "name": "Sandy Dunes",
    
            }],
            "image": '../../../../assets/img/self-drive/hekayat-route/19.jpg',
        }
    ]


    constructor(private _router: Router
        , private alertService: AlertService) { }

    ngOnInit(): void {
        this.recallJsFuntions();

        this.staticResponse();




        (function ($) {
            var CheckboxDropdown = function (el) {
                var _this = this;
                this.isOpen = false;
                this.areAllChecked = false;
                this.$el = $(el);
                this.$label = this.$el.find('.dropdown-label');
                this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
                this.$inputs = this.$el.find('[type="checkbox"]');

                this.onCheckBox();

                this.$label.on('click', function (e) {
                    e.preventDefault();
                    _this.toggleOpen();
                });

                this.$checkAll.on('click', function (e) {
                    e.preventDefault();
                    _this.onCheckAll();
                });

                this.$inputs.on('change', function (e) {
                    _this.onCheckBox();
                });
            };

            CheckboxDropdown.prototype.onCheckBox = function () {
                this.updateStatus();
            };

            CheckboxDropdown.prototype.updateStatus = function () {
                var checked = this.$el.find(':checked');

                this.areAllChecked = false;
                this.$checkAll.html('Check All');


            };

            CheckboxDropdown.prototype.onCheckAll = function (checkAll) {
                if (!this.areAllChecked || checkAll) {
                    this.areAllChecked = true;
                    this.$checkAll.html('Uncheck All');
                    this.$inputs.prop('checked', true);
                }
                else {
                    this.areAllChecked = false;
                    this.$checkAll.html('Check All');
                    this.$inputs.prop('checked', false);
                }

                this.updateStatus();
            };

            CheckboxDropdown.prototype.toggleOpen = function (forceOpen) {
                var _this = this;

                if (!this.isOpen || forceOpen) {
                    this.isOpen = true;
                    this.$el.addClass('on');
                    $(document).on('click', function (e) {
                        if (!$(e.target).closest('[data-control]').length) {
                            _this.toggleOpen();
                        }
                    });
                }
                else {
                    this.isOpen = false;
                    this.$el.removeClass('on');
                    $(document).off('click');
                }
            };

            var checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
            for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
                new CheckboxDropdown(checkboxesDropdowns[i]);
            }
        })(jQuery);

    }


    private staticResponse() {
        this.response = this.mapDetails;

        this.filteredMap = new Array<any>();

        this.fillmap(this.response);
        this.getDriveTypeName();
        this.getHabitatTypeName();
        this.manageEquipments();
        this.manageDriveType();
        this.manageHabitatType();
    }

    public onFilter(): void {
        const equipmentType = this.vehicleTypes.filter(x => x.isSelected).map(x => x.code);
        const driveType = this.drivingType.filter(x => x.isSelected).map(x => x.id);
        const habitatType = this.habitatType.filter(x => x.isSelected).map(x => x.id)



        let type = this.mapDetails;

        if (equipmentType.length > 0) {
            type = type.filter(x => equipmentType.includes(x.vehicleOption));
        }

        if(driveType.length > 0) {
            const res = type.filter(x =>{
                return x.drivingOption.filter(y => {
                    return driveType.find(z => y.id === z)
                }).length > 0
            });

            type = res;
        }

        if(habitatType.length > 0) {
            const res = type.filter(x =>{
                return x.habitatOption.filter(y => {
                    return habitatType.find(z => y.id === z)
                }).length > 0
            });

            type = res;
        }


        this.fillmap(type);
    }

    private manageEquipments() {
        let equipments = this.response.map(x => x.vehicleOption);
        equipments = equipments.filter((item, pos) => equipments.indexOf(item) === pos);

        equipments.forEach(type => {
            this.vehicleTypes.push({
                code: type,
                name: this.vehicleOption.find(x => x.id === type).name,
                isSelected: false,

            });
        });

    }

    getDriveTypeName() {

        this.response.forEach(account => {
            account.drivingOption.forEach(accountTypeItem => {
                this.driveTypeName.push(accountTypeItem.id);
            });
        });
    }

    getHabitatTypeName() {

        this.response.forEach(account => {
            account.habitatOption.forEach(accountTypeItem => {
                this.HabitatTypeName.push(accountTypeItem.id);
            });
        });
    }

    private manageDriveType() {
        let type = this.driveTypeName.filter((item, pos) => this.driveTypeName.indexOf(item) === pos);

        type.forEach(item => {
            this.drivingType.push({
                id: item,
                name: this.drivingOption.find(x => x.id === parseInt(item)).name,
                isSelected: false
            });
        });
    }

    private manageHabitatType() {
        let type = this.HabitatTypeName.filter((item, pos) => this.HabitatTypeName.indexOf(item) === pos);

        type.forEach(item => {
            this.habitatType.push({
                id: item,
                name: this.habitatOption.find(x => x.id === parseInt(item)).name,
                isSelected: false
            });
        });
    }






    private fillmap(map: Array<any>) {

        this.filteredMap = new Array<any>();

        map.forEach(map => {
            this.filteredMap.push({
                id: map.id,
                drivingOption: map.drivingOption,
                noOfDays: map.noOFdays,
                vehicleOption: map.vehicleOption,
                habitatOption: map.habitatOption,
                image: map.image

            });
        });
    }

    recallJsFuntions() {
        this.routerSubscription = this._router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.location = this._router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

}
