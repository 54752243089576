<div class="search_box">
    <div class="tab-content">
        <div role="tabpanel" aria-labelledby="pills-home-tab" class="tab-pane fade show active">
            <form (submit)="onSubmitSearch()">
                <div class="row align-items-center pb-3">
                    <div class="col-md-6 text-left " >
                        <div class="form-check d-none pb-3 pb-md-0">
                            <input type="checkbox" class="form-check-input returncar" name="search-Location" [(ngModel)]="pickUpFromHubValidForm.isEnableSearchPickUpLocation">
                            <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Pick Up Locations</label>
                        </div>
                    </div>
                    <div class="col-md-6 text-left " >
                        <label class="form-check text-brown  pb-3 pb-md-0">
                            Search For Drop Off Locations
                           <input type="checkbox" checked="checked" class="returncar"  name="search-Location" [(ngModel)]="pickUpFromHubValidForm.isEnableSearchDropOffLocation">
                           <span class="checkmark"></span>
                      </label>
                        <!-- <input type="checkbox" class="form-check-input " name="search-Location" [(ngModel)]="pickUpFromHubValidForm.isEnableSearchDropOffLocation">
                        <label class="form-check-label text-brown" name="returncar" for="rentACar_ReturnLocation">Search For Drop Off Locations</label> -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <!-- <div class="col-md-6" [hidden]="pickUpFromHubValidForm.isEnableSearchPickUpLocation">
                        <div class="form-group select">
                            <select class="form-control" name="pick-up-from-hub" [(ngModel)]="pickUpFromHubValidForm.pickUpFromHub"  placeholder="Select pick location from our main hub" >
                                <option value="">Select Pickup Hub Location</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div> -->

                    <div class="col-md-6" [hidden]="!pickUpFromHubValidForm.isEnableSearchPickUpLocation">
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Pick Up City'" [entries]="pickUpCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,1)"
                            [(ngModel)]="pickUpFromHubValidForm.pickUpAddress" name="pick-up-Address" (selectedValue)="onSelectItem($event,1)">
                            </ngx-autocomplete>
                        </div>
                    </div>

                    <div class="col-md-6" [hidden]="pickUpFromHubValidForm.isEnableSearchDropOffLocation">
                        <div class="form-group select" >
                            <select class="form-control" name="drop-off-to-hub" [(ngModel)]="pickUpFromHubValidForm.dropOffToHub" placeholder="Select drop off location to our main hub" >
                                <option value="">Select Drop Off Hub Location</option>
                                <option *ngFor="let location of parkOnlylocation" value="{{location.id}}">
                                    {{location.locationName}} ({{location.locationCode}})
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6" [hidden]="!pickUpFromHubValidForm.isEnableSearchDropOffLocation">
                        <div class="form-group transparent-field autotxt">
                            <ngx-autocomplete [searchKeyword]="'name'" [inputId]="'searchEmployee'" [placeholderValue]="'Search Drop Off City'" [entries]="dropOffCities" autocomplete="off" class="form-control m-0 pt-0 bg-transparent border-0 transparent-field" (keyup)="onEnterLocation($event,2)"
                            [(ngModel)]="pickUpFromHubValidForm.dropOffAddress" name="drop-off-address" (selectedValue)="onSelectItem($event,2)">
                            </ngx-autocomplete>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group select">
                            <select name="Vehicle Type" [(ngModel)]="pickUpFromHubValidForm.vehicleType" placeholder="Select Vehicle Type"  class="form-control">
                                <option value="">Vehicle Type</option>
                                <option *ngFor="let vehicle of vehiclesTypes" value="{{vehicle.id}}">
                                    {{vehicle.typeName}} 
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" readonly="true" class="form-control" name="pick-up-date"[(ngModel)]="pickUpFromHubValidForm.pickUpDate" autocomplete="off"
                            [minDate]="minDatePickUp" [maxDate]="maxDatePickUp" placeholder="Pickup date" #dp="bsDatepicker" bsDatepicker (ngModelChange)=dateSelect($event)>
                        </div>
                    </div>
                    <div class="col-md-4 text-left text-md-right">
                        <div class="form-group select">
                            <select class="form-control" name="pick-up-time" [(ngModel)]="pickUpFromHubValidForm.pickUpTime" (change)="getReturnTime()">
                                <option value="">Pick Up Time</option>
                                <option *ngFor="let time of pickUpTime" value="{{time}}">
                                    {{time}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>

                <div class="row align-items-center">
                    <div class="col-md-8">
                        <select class="form-control"  placeholder="Select Transfer Purpose" name="Transfer-purpose" [(ngModel)]="pickUpFromHubValidForm.transferPurpose">
                            <option value="">Select Transfer Purpose</option>
                            <option *ngFor="let purpose of transferPurpose" value="{{purpose}}">
                                {{purpose}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="text-right pr-0 mt-4">
                    <div class="form-check">
                        <button type="submit" class="btn btn-warning">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
