<app-header *ngIf="!(location == '/error' || location == '/coming-soon' 
    || location == '/sign-in' 
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-header>



<section class="about-area pb-0 pt-4">
    <div class="container new_container">
        <div class="about-inner-area">



           
            <div class="row ">
                <div class="container border-row"></div>
            </div>
         

        <div class="row">
            <div class="col-lg-4 pr-lg-5">
                

                <div class="about-text text-justify">
                <h3 class="mt-5">Pages</h3>

                <ul>
                    <li><a href="https://car-rental.hekaya.co" target="_Blank">Home</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/about" target="_Blank">About Us</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/contact" target="_Blank">Contact Us</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/services" target="_Blank">Services</a></li>
                </ul>
              
                <ul>
                    <li><a href="https://car-rental.hekaya.co/sign-in" target="_Blank">Sign-In</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/profile" target="_Blank">Profile</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/sign-up" target="_Blank">Sign-Up</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/Web-Use-Terms" target="_Blank">Web-Use-Terms</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/Privacy-Policy" target="_Blank">Privacy-Policy</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/Terms-and-Condition" target="_Blank">Terms&Conditions</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/forgot-password" target="_Blank">Forgot-Password</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/my-account/reset-password" target="_Blank">Reset-Password</a></li>
                </ul>

                <ul>
                    <li><a href="https://car-rental.hekaya.co/salalah" target="_Blank">Salalah</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/nizwa" target="_Blank">Nizwa</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/sohar" target="_Blank">Sohar</a></li>
                </ul>
                <ul>
                    <li><a href="https://car-rental.hekaya.co/jebel-akhdar" target="_Blank">Jebel akhdar</a></li>
                </ul>
            </div>

               

        </div>
        <div class="col-lg-8 ">
            <div class="about-text text-justify">
            <h3 class="mt-5">Blog Posts</h3>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog">Blog</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/12/02/10-ways-to-save-money-on-rental-cars/">10 ways to save money on rental cars</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/12/01/how-to-prepare-a-car-for-summer-travel/">How to prepare a car for summer travel?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/12/01/how-to-drive-defensively-in-oman/">How to drive defensively in Oman using a rental car?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/12/01/is-driving-in-muscat-easy/">Navigating the Streets of Muscat: Is driving in Muscat easy?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/11/03/women-travellers-with-monthly-car-rental-muscat/">Empowering women travellers with Monthly car rental Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/11/03/cheap-and-easy-rent-a-car-in-sohar-oman/">Cheap and Easy Rent a car in Sohar Oman</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/11/03/discovering-hidden-wonders-with-car-rental-salalah/">Off-the-Beaten-Path in Salalah: Discovering Hidden Wonders with Car rental Salalah</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/11/03/border-crossing-tips-for-rent-a-car-from-dubai-to-oman/">Navigating Border Crossings: Tips for rent a car from Dubai to Oman Drive</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/10/13/what-do-i-need-to-rent-a-car-in-oman/">What do I need to rent a car in Oman? A Comprehensive Guide</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/10/13/choosing-the-right-rental-car-in-sohar-oman/">Choosing the Right Rental Car in Sohar Oman: Finding the Perfect Vehicle for Your Needs</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/10/12/budget-rent-a-car-at-muscat-international-airport/">Airport Convenience: Budget Rent a Car at Muscat International Airport</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/10/09/is-it-safe-to-rent-a-car-in-oman/">Is it Safe to Rent a Car in Oman?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/10/09/corporate-travel-made-easy-monthly-car-rentals-for-business-professionals-in-muscat/">Corporate Travel Made Easy: Monthly Car Rentals for Business Professionals in Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/09/07/solo-travel-freedom-embracing-muscat-with-expert-car-rentals/">Solo Travel Freedom: Embracing Muscat with Expert Car Rentals</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/09/07/hire-cheap-car-rental-in-muscat-for-your-trip/">Hire Cheap car Rental in Muscat for your Trip</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/09/07/budget-friendly-car-rentals-for-muscat-adventurers/">Freedom of Flexibility_ Budget-Friendly Car Rentals for Muscat Adventurers</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/09/06/rent-a-car-from-dubai-to-oman-what-you-need-to-know/">Rent a Car from Dubai to Oman: What You Need to Know</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/09/06/why-renting-a-car-at-muscat-airport-is-a-smart-choice/">Why Renting a Car at Muscat Airport is a Smart Choice?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/09/06/renting-a-car-in-salalah-tips-for-a-stress-free-car-rental/">Renting a car in Salalah: Tips for a Stress-Free Car Rental Experience</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/08/07/why-rental-car-in-jebel-akhdar-offers-unrivaled-freedom-than-guided-tours/">Rental Car vs. Guided Tours: Why rental car in Jebel Akhdar Offers Unrivaled Freedom</a></li>
            </ul>

            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/08/07/what-makes-renting-a-car-monthly-in-muscat-ideal-for-expats/">What Makes Renting a Car Monthly in Muscat Ideal for Expats?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/08/07/why-rental-car-in-muscat-is-the-perfect-choice-while-travelling-with-family/">Why Rental Car in Muscat is the Perfect Choice while Travelling with Family?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/08/07/benefits-of-renting-a-car-in-oman-for-tourists/">What Are the Benefits of Renting a Car in Oman for Tourists?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/07/10/benefits-of-monthly-car-rental-in-muscat-for-long-term-travelers/">Benefits of Monthly Car Rental in Muscat for Long-Term Travelers</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/07/10/is-it-cheaper-to-rent-a-car-in-jebel-akhdar-by-weekly-or-monthly/">Is It Cheaper to Rent a Car in Jebel Akhdar by Weekly or Monthly</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/07/10/how-to-choose-the-right-rental-company-for-renting-a-car-in-oman-muscat/">How to Choose the Right Rental Company for Renting a Car in Oman</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/07/07/why-choose-monthly-car-rental-in-muscat-for-extended-stays-or-business-trips/">Why Choose Monthly Car Rental in Muscat for Extended Stays or Business Trips</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/06/14/how-to-choose-the-right-car-for-monthly-car-rental-in-muscat/">How to Choose the Right Car for Monthly Rental in Muscat: Factors to Consider</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/06/14/how-to-return-your-rental-car-at-muscat-airport-a-guide/">How to Return Your Rental Car at Muscat Airport: A Step-by-Step Guide</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/06/08/renting-a-car-in-oman-budget-friendly-options-for-travelers/">Renting a Car in Oman: Budget-Friendly Options for Every Traveler</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/06/07/renting-a-car-in-muscat-for-business-tips-for-corporate/">Renting a Car in Muscat for Business: Tips for Corporate</a></li>
            </ul>
            <ul>
              
                <li><a href="https://car-rental.hekaya.co/blog/2023/06/07/save-money-with-long-term-car-rental-in-muscat/">Why Long Term Car Rental in Muscat is More Cost-Effective than Short-Term Rentals</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/05/09/renting-a-car-in-jebel-akhdar-for-adventure-travel-tips-and-precautions/">Renting a Car in jebel akhdar for Adventure Travel: Tips and Precautions</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/05/09/how-to-plan-budget-friendly-road-trip-in-nizwa-by-rental-car/">How to plan a budget-friendly road trip in Nizwa by rental car</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/05/09/bene%ef%ac%81ts-of-monthly-car-rental-in-muscat-for-long-term-travelers/">Beneﬁts of Monthly Car Rental in Muscat for Long-Term Travelers</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/05/08/what-to-expect-while-first-time-renting-a-car-in-muscat/">A Guide for First-Time Renters on What to Expect When Renting a Car in Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/05/08/bene%ef%ac%81ts-of-cheap-car-rental-in-muscat-for-budget-travelers/">The Beneﬁts of Cheap Car Rental in Muscat for Budget-Conscious Travelers</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/04/11/explore-muscat-by-hiring-budget-friendly-rental-car-in-muscat/">Explore Muscat by hiring Budget-friendly rental car in Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/04/08/why-is-renting-a-car-better-than-buying-a-car/">Why is renting a car better than buying a car?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/04/08/tips-for-safe-driving-during-a-monthly-car-rental-in-muscat/">Tips for safe driving during a monthly car rental in Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/04/08/advantages-of-renting-a-car-for-business-trips-in-muscat/">Advantages of renting a car for business trips in Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/03/27/best-road-trips-to-take-from-muscat-with-a-rent-a-car-in-oman/">The Best Road Trips to Take from Muscat with a Rental Car</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/03/27/top-5-car-rental-companies-in-muscat/">Top 5 Car Rental Companies in Muscat</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2023/03/27/the-bene%ef%ac%81ts-of-renting-a-car-for-your-muscat-vacation/">The Beneﬁts of Renting a Car for your Muscat Vacation</a></li>
            </ul>

            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2024/01/03/7-major-benefits-of-choosing-an-electric-car-in-oman/">7 Major Benefits of choosing an electric car in Oman</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2024/01/03/how-to-keep-a-rental-car-clean/">How to keep a rental car clean</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2024/01/03/what-is-car-rental-insurance/">What is Car Rental Insurance?</a></li>
            </ul>
            <ul>
                <li><a href="https://car-rental.hekaya.co/blog/2024/01/02/what-to-do-if-you-have-a-rental-car-accident/">What to do if you have a rental car Accident</a></li>
            </ul>
    
    </div>
    </div> 
</div>
</div>
    </div>
</section>

<app-footer *ngIf="!(location == '/error' || location == '/coming-soon' || location == '/sign-in'
    || location == '/sign-up'
    ||location == '/forgot-password')">
</app-footer>