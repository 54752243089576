import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-home',
  templateUrl: './default-home.component.html',
  styleUrls: ['./default-home.component.scss']
})
export class DefaultHomeComponent implements OnInit {

  carRental= environment.carRental;
  pickDrop= environment.pickAndPark;
  selfDrive= environment.selfDrive;
  url: any;
  // windowUrl: string;

  constructor() { }

  ngOnInit(): void {

    this.url = window.location.href;

  }

  // ngOnInit(): void {

  //   // this.url = window.location.href;
  //   // console.log("url", this.url);
  //   this.windowUrl = window.location.href;
  //   console.log("url", this.windowUrl);
  //   if (this.windowUrl.indexOf(this.carRental) >= 0){
  //     this.url = this.carRental+'home';
      
  //     console.log("newurl", this.url);
  //   } else if (this.windowUrl.indexOf(this.pickDrop) >= 0){
  //     this.url = this.pickDrop+'pick-and-park';
  //     console.log("newurl2", this.url);
  //   }
  //   else if (this.windowUrl.indexOf(this.selfDrive) >= 0){
  //     this.url = this.selfDrive+'selfdrive';
  //     console.log("newurl3", this.url);
  //   } else {
  //     this.url = this.carRental+'home';
  //     console.log("newurl4", this.url);
  //   }
  //   window.location.href = this.url;

  // }

}
