import { BookingRequest } from './../../../../models/pick-drop/booking/pickdropbooking-request.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConstants } from 'src/app/app.routing.contents';
import { GenericService } from 'src/app/services/generic-service/generic.service';
import { SearchRequest } from '../models/search/search-request.model';
import { SearchResponse } from '../models/search/search-response.model';
import { BookingResponse } from 'src/app/models/pick-drop/booking/pickdropbooking-response.model';

@Injectable({
  providedIn: 'root'
})
export class PickAndDropService {

  constructor(private genericService: GenericService) { }

  selectedOption: any;
  searchData: any;

  search(request: SearchRequest): Observable<SearchResponse> {
    const serviceUrl = RoutingConstants.pickAndDrop.search;
    return this.genericService.postService(request, serviceUrl, true);
  }

  // pick and drop booking service
  bookingService(request: BookingRequest): Observable<BookingResponse> {
    const serviceUrl = RoutingConstants.pickAndDrop.bookings;
    return this.genericService.postService(request, serviceUrl, true);
  }

  getBookingsSummary(request: any): Observable<any> {
    let serviceUrl = RoutingConstants.pickAndDrop.bookingsByRef;
    return this.genericService.postService(request, serviceUrl, true);

  }

  getMyBookingsPickAndDrop(): Observable<any> {
    return this.genericService.postService({
      type: "Request",
      service: "MyBookings",
      content: {
        serviceType: "PickAndDrop"
    }
    }, RoutingConstants.myAccount.bookingRefByServiceType, true);
  }

  getMyBookingsPickAndPark(): Observable<any> {
    return this.genericService.postService({
      type: "Request",
      service: "MyBookings",
      content: {
        serviceType: "PickAndPark"
    }
    }, RoutingConstants.myAccount.bookingRefByServiceType, true);
  }

}
