<section class="register-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-image">
                <img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/register-bg.jpg" alt="image" loading="lazy">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                                <a routerLink="/"><img src="https://hekaya.s3.ap-south-1.amazonaws.com/carRental/black-logo.png" alt="image" loading="lazy"></a>
                            </div>

                            <h3>Create Your Account Now</h3>
                            <p>Already register? <a routerLink="/login">Login</a></p>

                            <form>
                                <div class="form-group">
                                    <input type="email" placeholder="Enter Your Name" class="form-control">
                                </div>

                                <div class="form-group">
                                    <input type="email" placeholder="Enter Your Email" class="form-control">
                                </div>

                                <div class="form-group">
                                    <input type="password" placeholder="Enter Your Password" class="form-control">
                                </div>

                                <div class="form-group">
                                    <input type="password" placeholder="Confirm Your Password" class="form-control">
                                </div>

                                <button type="submit">Signup</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>