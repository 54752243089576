// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  emailUrl: 'https://portal.helplinegroups.com/api/send_mail',
  apiUrl: 'https://api.client.hekaya.blueleaftech.in',
  agencyCode: 'MCTHEK001',
  emailAddress: 'test@gmail.com',
  password: '123456',
  domainRedirection: {
    enabled: false,
    carRental: 'rentcar.hekaya.blueleaftech.in/',
    pickAndPark: 'pickpark.hekaya.blueleaftech.in/',
    selfDrive: 'selfdrive.hekaya.blueleaftech.in/',
  },

  /* TEST URLS */
  carRental: 'https://rentcar.hekaya.blueleaftech.in/',
  pickAndPark: 'https://pickpark.hekaya.blueleaftech.in/',
  selfDrive: 'https://selfdrive.hekaya.blueleaftech.in/',

  /* LIVE URLS */
  // carRental: 'https://car-rental.hekaya.co/',
  // pickAndPark: 'https://car-transfer.hekaya.co/',
  // selfDrive: 'https://self-drive.hekaya.co/',

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
